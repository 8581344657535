export class LookUpUser {
  public userId: Number;
  public userName: string;
  public displayName: string;
  public email: string;
  public userType: string;
  public lastloginTime: string;
  public ldapDomain: string;
  public bu: string;
  public remark: string;
  public company: string;
  public phone: string;
  public whatsapp:string;
  public wechat:string;
  public line: string;
  public telegram:string;
  public multilingualLabels: MultilingualLabels[];
}

export class MultilingualLabels {
  id: string;
  value: string;
}
