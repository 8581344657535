import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourceService } from 'sce-core';
import { ViewOrderMapService } from '../components/services/view-order-map.service';
import { HttpClient } from '@angular/common/http';
import { tmsConfig } from 'src/app/core/tms-config';
import { catchError, map } from 'rxjs/operators';
import { transToLocationTime } from '../../../../assets/utils/transToLocationTime'

@Component({
  selector: 'app-view-order',
  templateUrl: './view-order.component.html',
  styleUrls: ['./view-order.component.css'],
  providers: [
    ViewOrderMapService
  ]
})
export class ViewOrderComponent implements OnInit {

  vertexId;
  orderDetail;
  isShow: boolean = true;
  pageInfo;
  controlTowerDetail: any;
  country: string = 'en_US';
  ePodView: any = '';
  @ViewChild('op') op: any;
  @ViewChild('opEdit') opEdit: any;
  @ViewChild('opView') opView: any; 
  public languagelist: any = [
    {
      langCode: 'en_US',
      langeName: 'English',
    },
    {
      langCode: 'zh_CN',
      langeName: '简体中文',
    },
    {
      langCode: 'en_CT',
      langeName: '繁體中文',
    },
    {
      langCode: 'vi_vn',
      langeName: 'Tiếng Việt',
    },
  ]
  getChangeList: any;
  countryCode: any;
  DeliveredState: any;
  constructor(
    private route: ActivatedRoute,
    public resourceService: ResourceService,
    public mapservice: ViewOrderMapService,
    public http$: HttpClient,
  ) {
  }

  ngOnInit() {
    setTimeout(() => {
      this.mapservice.initMap('map')
    }, 200);   
    // this.getLanguage().subscribe(res => {
    //   console.log(res);
    //   if(res){
    //     res.forEach(language=>{
    //       this.languagelist.push({
    //         langCode:language.name,
    //         langeName:language.xid
    //       })
    //     })
    //   }
    // })
    this.changeLanguge('en_US').subscribe(res => {
      console.log(res);
      if(res){
      this.getChangeList = res
      this.mapservice.getChangeList = res
      }
    })
    if (window.location.search) {
      let urls = window.location.search
      let keys = decodeURIComponent(urls.split('key=')[1]).replace(/\s/g, "+")
      let v = urls.match(/v=([^&]*)/)[1];
      setTimeout(() => {
        this.getcontent(keys, v).subscribe(res => {
          this.orderDetail = this.epodStatus([res])[0] || {}
          console.log(this.orderDetail)
          this.changeLanguge(res?.lang).subscribe(res => {
            this.getChangeList = res
            this.mapservice.getChangeList = res
          })
          this.country = res?.lang
          this.controlTowerDetail = res?.historyList
          let timeOfArrival =[],
            pickupDateTime =[],
            refuseTime =[];
          this.controlTowerDetail.forEach(ele => {
            if (ele.statusCode == "D1") {
              timeOfArrival.push(ele.eventDate)
            }
            if (ele.statusCode == 'AF') {
              pickupDateTime.push(ele.eventDate)
            }
            if (ele.statusCode == 'A7') {
              refuseTime.push(ele.eventDate)
            }
          });
          //  DeliveredState;
         this.DeliveredState = {
            Page: 'OrderTrack',
            Status: this.orderDetail?.orderStatus,
            earlyPickupDate: this.orderDetail?.earlyPickupDate,
            requestedDeliveryDate: this.orderDetail?.requestedDeliveryDate,
            shipmentPlannedEndDate: this.orderDetail?.shipmentPlannedEndDate,
            shipmentPlannedStartDate: this.orderDetail?.shipmentPlannedStartDate,
            sourceCity: this.orderDetail?.sourceCity,
            destCity: this.orderDetail?.destCity,
            diffDistance: this.orderDetail?.diffDistance,
            time: this.orderDetail?.time,
            orderStatus: this.orderDetail?.orderStatus,
            eventStatusCode: this.orderDetail?.eventStatusCode,
            shipmentXid: this.orderDetail?.shipmentXid,
            pickupDateTime,
            timeOfArrival,
            refuseTime,
            gpsLat: this.orderDetail?.gpsLat,
            gpsLon: this.orderDetail?.gpsLon,
            correctFlag: 'T',
            sourceLon: this.orderDetail?.sourceLon,
            sourceLat: this.orderDetail?.sourceLat,
            destLon: this.orderDetail?.destLon,
            destLat: this.orderDetail?.destLat,
            url: this.orderDetail?.url
          }
          this.mapservice.refreshMap([[this.orderDetail?.sourceLon, this.orderDetail?.sourceLat], [this.orderDetail?.destLon, this.orderDetail?.destLat]], this.DeliveredState);
        })
        // this.vertexId = queryParams.id;
      }, 500);
    }

  }

  epodStatus(data){
    if (data.length>0) {
      data.forEach((res,index)=>{
        res.exceeded = false
        if (data[index].historyList.length>0) {
          let historyList =[] 
           data[index].historyList.map(res=>{
            if (res.statusCode=="O3"){
              historyList.push(res);
            } 
          })
          let requestTimes = historyList.slice(-1)
          let requestTime = requestTimes[0]?.requestTime
           if (requestTime && requestTime!='-') {
            res.exceeded = this.isTimeExceeded(requestTime);
           }
        }
        if (res?.multimedias?.length>1) {
          res?.multimedias.forEach(ele=>{
          if (ele.type == "POD") {
            res.PODMediaUrl =ele.mediaUrl
          }
          if (ele.type == "POP") {
            res.POPMediaUrl =ele.mediaUrl
          }
          })
        }else if (res?.multimedias?.length>0 && res?.multimedias?.length <= 1) {
          if (res?.multimedias[0].type == "POD") {
            res.PODMediaUrl =res?.multimedias[0].mediaUrl
          }
          if (res?.multimedias[0].type == "POP") {
            res.POPMediaUrl =res?.multimedias[0].mediaUrl
          }
          res.mediaUrl = res?.multimedias[0].mediaUrl
        }
      })
      return data
    }
    console.log(data);
    return data
  }

  isTimeExceeded(dateStr: string): boolean {
    const MILLIS_IN_14_DAYS = 30 * 24 * 60 * 60 * 1000; // 14天的毫秒数
  
    const currentDate = new Date(); // get 当前时间
    const targetDate = new Date(dateStr); // 将目标时间字符串转换成Date对象
  
    if (
      currentDate.getTime() > targetDate.getTime() + MILLIS_IN_14_DAYS // 当前时间晚于目标时间14天以上
    ) {
      return true;
    } else {
      return false;
    }
  }

  getPODImage(url: string): string {
    if (!url) {
        return ''; 
    }
    let extension = JSON.parse(JSON.stringify(url)).split('.').pop().toLowerCase(); 
    if (extension === 'pdf') {
        return 'assets/images/file-pdf.svg';
    } else {
        return 'assets/images/file-pdf-1.svg';
    }
}

toggleOverlay(event: any,status,cpntent?) {
    this.ePodView = status
    if (status == 'view') {
      if (cpntent?.mediaUrl) {
        window.open(cpntent.mediaUrl, '_blank')
        return
      }
      if (this.opView) {
        this.opView.toggle(event);
      }
    }
    if (status == 'add') {
      if (this.op) {
        this.op.toggle(event);
      }
    }
    if (status == 'edit') {
      if (this.opEdit) {
        if (cpntent?.mediaUrl) {
          window.open(cpntent.mediaUrl, '_blank')
          return
        }
        this.opEdit.toggle(event);
      }
    }
}

public viewEPOD(url){
    window.open(url, '_blank')
  }

  public transLocationTime(val){
    if (val) {
      // var utcTime = val.value
      var utcTime = val
      var newutcTime = utcTime.slice(0,4)+'-'+utcTime.slice(4,6)+'-'+utcTime.slice(6,8)+' '+utcTime.slice(8,10)+':'+utcTime.slice(10,12)+':'+utcTime.slice(12,14)
      return transToLocationTime(newutcTime)
    }
  }

  getChangelabel(value){ 
    if (this.getChangeList) {
      const data = this.getChangeList.find(item => item.promptCode === value);
      return data ? data.description : value;  
    }else{
    return value
    }
  }

  getcontent(key, v) {
    let parmes = {
      signKey: '',
      key,
      v,
      // orderReleaseXid: value?.orderReleaseXid,
    }
    var url = tmsConfig.tms?.selectOrdersByXidNew
    const response$: any = this.http$.post(url, JSON.stringify(parmes)).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => {
        return this.resourceService.get(error.message);
      }),);
    return response$
  }

  changeLanguge(lang){
    var url = tmsConfig.tms.selectPrompt
    if (!lang) {
      lang = 'en_US'
    }
    var parmes ={
      lang
    }
    const response$: any = this.http$.post(url, JSON.stringify(parmes)).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => {
        return this.resourceService.get(error.message);
      }),);
    return response$

  }

  getLanguage(){
    var url = tmsConfig.tms.selectBusinessUnit
    const response$: any = this.http$.get(url).pipe(
      map((response: any) => {
        return response
      }),
      catchError((error: any) => {
        return this.resourceService.get(error.message);
      }),);
    return response$
  }

  changeCountry(event:any){
    console.log('123', event.target.value);
    this.changeLanguge(event.target.value).subscribe(res => {
      console.log(res);
      this.getChangeList = res
      this.mapservice.getChangeList = res
      this.mapservice.refreshMap([[this.orderDetail?.sourceLon, this.orderDetail?.sourceLat], [this.orderDetail?.destLon, this.orderDetail?.destLat]], this.DeliveredState);
    })
  }
  goEvent(value) {
    console.log(value);

  }
  public getLabel(key) {
    return this.resourceService.get(key);
  }
}
