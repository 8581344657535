import { Component, Input, OnInit, OnChanges} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ResourceService } from '../../resource.service';
import { AlertService } from '../alerts/alert.service';


@Component({
  selector: 'lfwms-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class SCEIframeComponent implements OnInit, OnChanges {

  @Input()  iframeUrl: string;
  @Input() public width: number;
  @Input() public height: number;
  public safeSrc: SafeResourceUrl;
  public showIframe:boolean = false;
  private reportFormat = false;
  private printPleaseCheck = "";
  public thumbnail;

  private showCompleted = false;
  // public safeSrc: String;
  constructor(public sanitizer: DomSanitizer, private resourceService: ResourceService, private alertService: AlertService) {

  }

  public downloadCompleted(){
    if(this.reportFormat){
      this.alertService.clearAll();
      this.alertService.success(this.resourceService.get('msg_prnt_rprt_check_browser'));
    }
    else{
      this.alertService.clearAll();
    }

  }


  public ngOnInit() {
    if(this.iframeUrl){
    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
    this.reportFormat = this.iframeUrl.includes('type=4') || this.iframeUrl.includes('type=3');
  }
  if(this.reportFormat){
    this.alertService.success(this.resourceService.get('msg_prnt_rprt_file_downloading'));
  }
  else{
  this.alertService.success(this.resourceService.get('msg_prnt_rprt_file_loading'));
  }
    setTimeout(() => {
      this.showIframe = true;
    }, 2000);
  }
  public ngOnChanges() {
    this.safeSrc =  this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl);
    setTimeout(() => {
      this.showIframe = true;
    }, 2000);
  }
}
