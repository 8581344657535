import { Component, TemplateRef, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { RoleManagementService } from './role-management.service';
import { AddRoleRequestModel } from './models/add-role-request.model';

import { UserManagementService } from '../user-management/user-management.service';

import { GroupManagementService } from '../group-management/group-management.service';
import { ModalService } from '../components/modal/modal.service';
import { AlertService } from '../components/alerts/alert.service';
import { NavigationService } from '../navigation.service';
import { ConfirmDialogService } from '../components/confirm-dialog/confirm-dialog.service';
import { ResourceService } from '../resource.service';
import { UserService } from '../security/user.service';


@Component({
  selector: 'lfwms-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements OnInit, OnDestroy {
  public selectedRole: string;
  public selectedRoleDetails: any;
  public selectedGroups: any;
  public selectedApp: number;
  public apps: any;
  public roles: any;
  public groups: any;
  public allRoleGroups: any;
  public allRoleGroupsMetaData: any = [];
  public modalRef: any;
  public accessResponse: any;
  public accessPrivilegeRole: any;
  public updatedAccessPrivilegeReq: any;
  public selectedPrivileges: Array<any> = [];
  public dropdownSettings = {};
  public displayMode: any;
  public texts = {};
  public response = {};
  public enableClick: Boolean = false;
  public config = { animated: false };
  public groupDetails = {};
  public previousAction: string;
  public isTemplateLoaded: Boolean;
  public showPrivilegePreview: Boolean;
  public rolesForLookup: any;
  public subscription: Subscription = new Subscription();
  @Input() disableDropdown: boolean = false;
  public groupDisableDropdown: Boolean = true;
  public formattedPrivilegeData: any;
  public formattedPrivilegeMetaData: any;
  public deafultPrivileges: any = [];
  public showOverlay: boolean = false;

 // Jira 1927 Role Management - Disable delete for Administrator Role
  public isAdminRoleSelected: boolean = false;

  // Jira 720 starts
  public roleGroup: any = [];
  public roleGroupId: any = [];
  public roleMngBtnAdd: boolean;
  public roleMngBtnMngGrp: boolean;
  public roleMngBtnCopy: boolean;
  public roleMngBtnEdit: boolean;
  public roleMngBtnDelete: boolean;
  // jira 720 ends

// LFWM-1994 To check the selected app id in dropdown 
public appDropdown:number;
// LFWM-1994 AppId for wms and gvt
public wmsAppId: number=1;
public gvtAppId: number=2;
/*** LFWM-2242 LF SCE Dashboard - User Management */
public dashboardAppId: number = 4;
 /*** LB-2 LF SCE Billing | Role Management */
 public billingAppId: number = 5;
 // for data loader
public dlAppId:number = 3;
  /*** POMS-2 LF SCE POMS | Role Management */
  public pomsAppId: number = 6;

   // for notification
public ntAppId:number = 7;
public dispatcherAppId:number = 8;

public privilegeTypeRoleManagement:string='B';//setting Button type as privilege for rolemanagement

  constructor(public route: ActivatedRoute,
    public userManagementService: UserManagementService,
    public modalService: ModalService,
    public roleManagementService: RoleManagementService,
    public alertService: AlertService,
    public navService: NavigationService,
    public confirmDialogService: ConfirmDialogService,
    public resourceService: ResourceService,
    public userService: UserService,
    public groupManagementService: GroupManagementService,
    public activatedRoute: ActivatedRoute) {
  }

  public ngOnInit() {
    this.initializeApps();
    /* --- this variable will set default mode as edit ---*/
    this.displayMode = 'E';
    this.previousAction = null;
    /* code changes to show non navigable bread crumb - This will be removed in future -- Start */
    this.navService.removeLastBreadCrumbItem();
    this.navService.addNonNavigableBreadCrumb('Role Management');
    /* code changes to show non navigable bread crumb - This will be removed in future -- Stop */
    this.initializeRoles(this.wmsAppId);// LFWM-1994 passing the appid 1 for initial loading
    // this.initializeDefaultAccessPrivilege();
    this.initializeAllRoleGroups(this.wmsAppId);// LFWM-1994 passing the appid 1 for initial loading
    this.activatedRoute.params.subscribe(params => {
      this.navService.setPageTitle(this.resourceService.get('mn_manage-role'));
    });
    this.roleManagementService.initializePrivilegeTemplate(this.wmsAppId,this.privilegeTypeRoleManagement);// LFWM-1994 passing the appid 1 for initial loading
    this.roleManagementService.onTemplateLoaded
      .subscribe((data: boolean) => {
        this.isTemplateLoaded = data;
        this.formattedPrivilegeData = this.roleManagementService.formattedPrivilegeData;
        this.formattedPrivilegeMetaData = JSON.parse(JSON.stringify(this.formattedPrivilegeData));
      });



    /* Default settings for Group multi select dropdown -- start */
    this.dropdownSettings = {
      'dynamicTitleMaxItems': 1,
      'displayAllSelectedText': true,
      'maxHeight': '200px',
      'overflow-x': 'hidden'
    };
    this.texts = {
      'checkAll': 'Select all',
      'uncheckAll': 'Unselect all',
      'checked': 'item selected',
      'checkedPlural': 'items selected',
      'searchPlaceholder': 'Find',
      'searchEmptyResult': 'Nothing found...',
      'searchNoRenderText': 'Type in search box to see results...',
      'defaultTitle': this.resourceService.get('lbl_slct_grp')
    };
    /* Default settings for Group multi select dropdown -- end */
    this.roleMngBtnAdd = this.checkButtonPrivilege('role_add');
    this.roleMngBtnMngGrp = this.checkButtonPrivilege('mng_grp');
    this.roleMngBtnCopy = this.checkButtonPrivilege('role_copy');
    this.roleMngBtnEdit = this.checkButtonPrivilege('role_edit');
    this.roleMngBtnDelete = this.checkButtonPrivilege('role_del');

  }

  public checkButtonPrivilege(privilegeCode: String) {
    return this.userService.checkButtonPrivilege(privilegeCode);
  }
  /* This method will fetch all the groups corresponds to app id in the application and bind it to UI */
  public initializeAllRoleGroups(appId) {// LFWM-1994 passing the appid as a parameter 
    this.subscription = this.userManagementService.getAllGroups(appId)
      .subscribe((resp: any) => {      
        this.groups = [];
        this.allRoleGroups = resp;
        resp.forEach((group, indexGroup) => {
          this.allRoleGroupsMetaData.push(group);
          this.groupDetails = { 'id': group.groupId, 'name': group.groupName };
          this.groups.push(this.groupDetails);
        });
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
    // return this.groups;
  }
  // // LFWM-1994 method on changing appid dropdown
  public changeSelectedApp(app){  
    this.rolesForLookup=[] 
    /*** dashboard ***/
    this.enableClick = false;
    // LFWM-1994 when changing the dropdown resetting displaymode,roleMngBtnAdd
    this.roleMngBtnAdd=true; 
    this.displayMode = 'E';
    if(app==this.gvtAppId){
      this.appDropdown=this.gvtAppId;
    }else if(app==this.wmsAppId){
      this.appDropdown=this.wmsAppId;
    } else if (parseInt(app, 10) === this.dashboardAppId) {
      /*** LFWM-2242 LF SCE Dashboard - User Management */
      this.appDropdown = this.dashboardAppId;
    } else if (parseInt(app, 10) === this.billingAppId) {
      /*** LB-2 LF SCE Billing | Role Management */
      this.appDropdown = this.billingAppId;
    } else if (parseInt(app, 10) === this.pomsAppId) {
      /*** POMS-2 LF SCE POMS | Role Management */
      this.appDropdown = this.pomsAppId;
    }else if(app==this.dlAppId){ //For data loader
      this.appDropdown=this.dlAppId;
    }else if(app==this.ntAppId){ //For notification
      this.appDropdown=this.ntAppId;
    }else if(parseInt(app, 10)==this.dispatcherAppId){ //For dispatcher
      this.appDropdown=this.dispatcherAppId;
    }

    this.initializeAllRoleGroups(app);// LFWM-1994 passing selected appid as a parameter for fetching template,roles and groups
    this.roleManagementService.initializePrivilegeTemplate(this.selectedApp,this.privilegeTypeRoleManagement);
    this.userManagementService.getAllRoles(this.selectedApp)
      .subscribe((resp: any) => {
        this.roles = resp;
        this.rolesForLookup = this.roles;
        this.selectedRole = null;
        this.groupDisableDropdown=true;
        this.roleGroup = [];
        this.showPrivilegePreview=false;
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });      
  }
  /* This method will fetch all the apps in the application and bind it to UI */
  public initializeApps() {
    const subscription = this.userManagementService.getAllUserApps()
      .subscribe((resp: any) => {
        // LFWM-1994 to check whether the logged in use has gvt access,if not,filter out gvt from the response and show details in dropdown
        // if(!this.userService.userInfo.gvtAccess){
        //   const appsList = [];
        //   resp.forEach(element => {
        //     if (element.appId === this.gvtAppId) {
        //       if (this.userService.userInfo.gvtAccess) {
        //         appsList.push(element);
        //       }
        //     } else if (element.appId === this.dashboardAppId) {
        //       /*** LFWM-2242 LF SCE Dashboard - User Management */
        //       if (this.userService.userInfo.dashboardAccess) {
        //         appsList.push(element);
        //       }
        //     } else if (element.appId === this.billingAppId) {
        //       /*** LFWM-2242 LF SCE Billing - User Management */
        //       if (this.userService.userInfo.billAccess) {
        //         appsList.push(element);
        //       }
        //     } else if (element.appId === this.pomsAppId) {
        //       /*** POMS-2 LF SCE POMS - User Management */
        //       if (this.userService.userInfo.pomsAccess) {
        //         appsList.push(element);
        //       }
        //     } else {
        //       appsList.push(element);
        //     }
        //   });
        //   resp = appsList;
        // // }
        this.apps = resp;
        this.selectedApp = this.apps[0].appId;
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
    this.subscription.add(subscription);
  }

  /* This method will fetch all the roles in the application and bind it to UI */
  public initializeRoles(appId) {// LFWM-1994 passing selected appid as parameter
    const subscription = this.userManagementService.getAllRoles(appId)// LFWM-1994passing selected appid as parameter
      .subscribe((resp: any) => {
        this.roles = resp;
        this.rolesForLookup = this.roles;
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
    this.subscription.add(subscription);
  }

  /* method used to translate the labels */
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }



  public filterLookup(event: any, template: any) {
    if (this.selectedRole) {
      const val = this.selectedRole.toLowerCase();
      this.rolesForLookup = this.roles.filter(function (d) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });
    } else {
      this.rolesForLookup = this.roles;
    }
    this.openLookup(template);
  }  /*This method helps to open lookup screen for roles*/
  public openLookup(template: TemplateRef<any>) {
    if (this.rolesForLookup && this.rolesForLookup.length !== 0) {
      const modalRef: any = this.modalService
        .showModal(template,
          {
            overlayHeader: '',
            showHeader: true,
            overlayHeight: 520,
            overlayWidth: 1024
          });
    } else {
      this.alertService.clearAll().error(this.resourceService.get('msg_role_val'));
    }
  }

  /*Helps to assign selected role value to text field from loop up screen*/
  public notifyHyperlinkClick(roleDetail: any) {
      if (this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.populateRoleRequestVO()['privileges'])) {

      this.initializelookup(roleDetail);
    } else {
      if (!this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.populateRoleRequestVO()['privileges'])
      ) {
        const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
        const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
        const dialogMsg = [];
        dialogMsg.push(dialogMsg1);
        dialogMsg.push(dialogMsg2);
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
          .subscribe((isConfirmed: any) => {
            if (isConfirmed) {
              this.initializelookup(roleDetail);

            } else {
              return;
            }
          });
      } else {
        this.initializelookup(roleDetail);
      }
    }
  }

  /* method used to filter the role list based on the manually entered value */
  public updateFilter(event: any, template: any) {
    this.showOverlay = true;
    const val = event.target.value.toLowerCase();
    if (event.keyCode === 13) {
      this.rolesForLookup = this.roles.filter(function (d) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });
      // this.openLookup(template);
    }
  }


  public initializelookup(roleDetail: any) {   
    this.cancelRole();
    this.deafultPrivileges = [];
    // this.disableToggleButtonOnLoad = false;
    this.enableClick = true;
    this.selectedRole = roleDetail.name;
    this.selectedRoleDetails = roleDetail;

    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.selectedGroups = [];
    // this.roleManagementService.setFormatedData(this.formattedPrivilegeMetaData);
    this.allRoleGroups.forEach((group, indexGroup) => {
      group.roles.forEach((role, indexRole) => {
        this.selectedGroups.push(group.groupId);
        if (roleDetail.roleId === role.id) {
          this.selectedGroups = Array.from(new Set(this.selectedGroups));

        }
      });
    });


    // Jira 720 starts
    this.roleGroup = [];
    this.roleGroupId = [];
    for (let i = 0; i < this.allRoleGroups.length; i++) {

      for (let j = 0; j < this.allRoleGroups[i].roles.length; j++) {

        if (this.allRoleGroups[i].roles[j].role === this.selectedRoleDetails.name) {
          this.roleGroup.push(this.allRoleGroups[i].groupName);
          this.roleGroupId.push(this.allRoleGroups[i].groupId);
        }
      }
    }

    if (this.roleGroup.length === 0) {
      this.groupDisableDropdown = true;

    } else {
      this.groupDisableDropdown = false;
    }






    // Jira 720 ends
    this.roleManagementService.setDefaultGroups(JSON.parse(JSON.stringify(this.selectedGroups)));
   // LFWM-1994 to reset selected app id based on  value in appdropdown
    if(this.appDropdown==this.gvtAppId){
      this.selectedApp=this.appDropdown;
    } else if (this.appDropdown === this.dashboardAppId) {
      /*** LFWM-2242 LF SCE Dashboard - User Management */
      this.selectedApp = this.appDropdown;
    } else if (this.appDropdown === this.billingAppId) {
      /*** LB-2 LF SCE Billing | Role Management */
      this.selectedApp = this.appDropdown;
    } else if (this.appDropdown === this.pomsAppId) {
      /*** POMS-2 LF SCE POMS | Role Management */
      this.selectedApp = this.appDropdown;
    } //for data loader
    else if(this.appDropdown==this.dlAppId){
      this.selectedApp=this.appDropdown;
    } // notification
    else if(this.appDropdown==this.ntAppId){
      this.selectedApp=this.appDropdown;
    } // Dispatcher
    else if(this.appDropdown==this.dispatcherAppId){
      this.selectedApp=this.appDropdown;
    }
    this.roleManagementService.getAccessPrivilegeRole(this.selectedApp, this.selectedRoleDetails.roleId,this.privilegeTypeRoleManagement)
      .subscribe((resp: any) => {
        if (resp['statusCode'] && resp['statusMessage']) {
          if (resp['statusCode'] === 200) {
            this.accessPrivilegeRole = [];
            this.accessPrivilegeRole = JSON.parse(JSON.stringify(resp.privileges));
            this.roleManagementService.refreshPrivilegeData(resp.privileges);
            this.showPrivilegePreview = true;
          }
        } else {
          if (resp.error) {
            this.alertService.clearAll();
            this.alertService.error(this.resourceService.translateServerResponse(resp.error));
          }
        }
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }




  /* method is used to populate create role screen,while clicking on Add New Role button*/
  public showCreateRoleScreen(isCreateRole?: boolean) {
    if (this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.populateRoleRequestVO()['privileges'])
    ) {
      this.initializeCreateRoleScreen(isCreateRole);
    } else {
      const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
      const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
      const dialogMsg = [];
      dialogMsg.push(dialogMsg1);
      dialogMsg.push(dialogMsg2);
      this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            this.initializeCreateRoleScreen(isCreateRole);
          } else {
            return;
          }
        });
    }
  }

  public initializeCreateRoleScreen(isCreateRole: Boolean) {
    if (isCreateRole) {
      // this.cancelRole();
      //  this.disableToggleButtonOnLoad = false;
      this.navService.preventNavigation = true;
      this.showPrivilegePreview = true;
      this.displayMode = 'C';
      this.selectedRole = null;
      this.enableClick = false;
      this.selectedRoleDetails = null;
      this.disableDropdown = false;
      this.groupDisableDropdown = true;
      // this.response['modules'] = this.accessPrivilege;
      // this.accessPrivilege = this.roleManagementService.getModifiedAccessPrivilege(this.response, 'create').modules;
      this.roleManagementService.resetPrivilegeData(true);
      this.roleManagementService.removeDefaultPrivileges();
      this.roleManagementService.removeDefaultGroups();
      this.selectedGroups = [];
      this.roleGroupId = [];
      // Jira- 891 change add
      this.roleGroup = [];
      // Jira- 891 change end
    } else {
      this.displayMode = 'E';
    }
  }
  /* Method is used to create request vo for save role functionality */
  public populateRoleRequestVO(): AddRoleRequestModel {
    this.updatedAccessPrivilegeReq = {};
    this.selectedPrivileges = [];
    this.formattedPrivilegeData.forEach((subModule, indexSubModule) => {
      /* Code changes to push privilege id of the module --Start */
      if (subModule.isChecked && subModule.id) {
        this.selectedPrivileges.push(subModule.id);
      }
      /* Code changes to push privilege id of the module -- End */
      subModule.privileges.forEach((privileges, indexPrivileges) => {
        privileges.forEach((privilege, indexPrivilege) => {
          if (privilege.id && privilege.isChecked === true) {
            this.selectedPrivileges.push(privilege.id);
          }
        });
      });
    });
    this.updatedAccessPrivilegeReq = new AddRoleRequestModel();
    this.updatedAccessPrivilegeReq.privileges = Array.from(new Set(this.selectedPrivileges));
    this.updatedAccessPrivilegeReq.roleName = this.selectedRole;
    this.updatedAccessPrivilegeReq.privType = this.privilegeTypeRoleManagement;//LFWM-1994 added privilege type 'B' for role management
    if (this.selectedRoleDetails && this.displayMode === 'E') {
      this.updatedAccessPrivilegeReq.roleId = this.selectedRoleDetails.roleId;
    } else {
      this.updatedAccessPrivilegeReq.roleId = null;
    }
    this.updatedAccessPrivilegeReq.appId = this.selectedApp;
    this.updatedAccessPrivilegeReq.groups = this.roleGroupId;

    // this.updatedAccessPrivilegeReq.groups = this.selectedGroups;
    return this.updatedAccessPrivilegeReq;
  }

  public setDefaultPrivileges() {
    this.formattedPrivilegeData.forEach((subModule, indexSubModule) => {
      subModule.privileges.forEach((privileges, indexPrivileges) => {
        privileges.forEach((privilege, indexPrivilege) => {
          if (privilege.id && privilege.isChecked === true) {
            this.selectedPrivileges.push(privilege.id);
          }
        });
      });
    });
  }


  /*Used for save role information*/
  public saveRole() {
    this.alertService.clearAll();
    this.updatedAccessPrivilegeReq = this.populateRoleRequestVO();
    // prompting the warning message when no modifications are done to the  role and trying to save.
    if (this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.selectedPrivileges) && this.displayMode === 'E'
      && this.validateGroup(this.roleManagementService.getDefaultGroups(), this.selectedGroups)) {
      const errorMsg = this.resourceService.get('mn_mdfy_role_msg');
      this.alertService.clearAll().warn(this.resourceService.get(errorMsg));
      return;
    } else {
      if (this.displayMode === 'E') {
        this.previousAction = 'edit';
      } else {
        this.previousAction = 'create';
      }
      if (this.updatedAccessPrivilegeReq.roleName && this.updatedAccessPrivilegeReq.roleName.trim()) {
        this.roleManagementService.saveRole(this.updatedAccessPrivilegeReq)
          .subscribe((resp: any) => {
            if (resp['statusCode'] && resp['statusMessage']) {
              if (resp['statusCode'] === 200) {
                this.alertService.clearAll().success(resp['statusMessage']);
                this.displayMode = 'E';
                this.roleManagementService.setDefaultPrivileges(this.selectedPrivileges);
                this.roleManagementService.setDefaultGroups(JSON.parse(JSON.stringify(this.selectedGroups)));
                this.roleManagementService.refreshPrivilegeData(this.selectedPrivileges);
                if (this.updatedAccessPrivilegeReq.roleId === null) {
                  const role = { 'roleId': resp['userRole'].id, 'name': resp['userRole'].role, 'code': resp['userRole'].role };
                  this.roles.push(role);
                  this.selectedRole = resp['userRole'].role;
                  this.selectedRoleDetails = role;
                  this.enableClick = true;
                }
                this.updateGroup(this.updatedAccessPrivilegeReq.groups, resp['userRole']);
                this.navService.preventNavigation = false;
              }
            } else {
              if (resp.errors) {
                this.alertService.clearAll();
                this.alertService.error(this.resourceService.translateServerResponse(resp.errors[0].validationMessage));
              }
            }
          }, (error) => {
            this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
          });
      } else {
        this.alertService.clearAll().error(this.resourceService.get('msg_role_man'));
        this.selectedRole = '';
      }
    }
  }
  /* Method used to update group list */
  public updateGroup(groups, roles) {
    this.allRoleGroups = JSON.parse(JSON.stringify(this.allRoleGroupsMetaData));
    groups.forEach((groupId, indexGroup) => {
      this.allRoleGroups.forEach((group, indexGrup) => {
        if (groupId === group.groupId && group.roles.indexOf(roles) === -1) {
          group.roles.push(roles);
        }
      });
    });

  }

  public validatePrivileges(defaultPrivileges: any, selectedPrivileges: any) {
    //  If we have no default privilages then also it may have some modifications i fthere are selectedrpivilages
    if (defaultPrivileges.length > 0 && selectedPrivileges.length > 0) {
      const modifiedPrivileges = defaultPrivileges.filter(item => selectedPrivileges.indexOf(item) < 0);

      const modifiedPrivilegesRev = selectedPrivileges.filter(item => defaultPrivileges.indexOf(item) < 0);

      if (modifiedPrivileges.length > 0 || modifiedPrivilegesRev.length > 0) {
        return false;
      } else {
        return true;
      }
    } else if (selectedPrivileges.length > 0 || defaultPrivileges.length > 0) {
      /*  it comes here when  defaultprivileges is zero but we have modified privileges and
        at times when there are dfault privileges but the selected privilegs happened to be zero */
      return false;
    } else {
      return true;
    }
  }
  public validateGroup(getDefaultGroups: any, selectedGroups: any) {
    if (getDefaultGroups.length > 0 && selectedGroups.length > 0) {
      const modifiedGroups = getDefaultGroups.filter(item => selectedGroups.indexOf(item) < 0);

      const modifiedGroupsRev = selectedGroups.filter(item => getDefaultGroups.indexOf(item) < 0);

      if (modifiedGroups.length > 0 || modifiedGroupsRev.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  /* Used for role cancellation */
  public cancelRole() {
    // this.disableToggleButtonOnLoad = true;
    this.accessResponse = [];
    //  this.disableToggleButtonOnLoad = true;
    this.disableDropdown = false;
    this.groupDisableDropdown = true;
    //  this.accessPrivilege = [];
    this.selectedPrivileges = [];
    this.updatedAccessPrivilegeReq = {};
    this.selectedRoleDetails = {};
    this.enableClick = false;
    this.selectedRole = null;
    this.selectedGroups = null;
    this.selectedGroups = [];
    // this.selectedApp = this.apps[0].appId;
    // LFWM-1994 to reset selected app id based on appdropdown
    if(this.appDropdown==this.wmsAppId){
      this.selectedApp = this.apps[0].appId;;
    }
    // this.roleManagementService.removeDefaultGroups();
    // this.roleManagementService.removeDefaultPrivileges();
    /*this.userManagementService.setDefaultPrivileges(JSON.parse(JSON.stringify(this.selectedPrivileges)));
    this.userManagementService.setDefaultGroups(JSON.parse(JSON.stringify(this.selectedGroups)));*/
    // this.accessPrivilege = JSON.parse(JSON.stringify(this.accessPrivilegeMetadata));
    // this.formattedPrivilegeData = this.formattedPrivilegeMetaData;
    this.displayMode = 'E';
    this.showPrivilegePreview = false;
  }

  public cancelRoleInfo() {
    this.groupDisableDropdown=true; // LFWM-1994 to reset the group dropdown 
    this.roleGroup = [];
    if (!this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.populateRoleRequestVO()['privileges'])
      || !this.validateGroup(this.roleManagementService.getDefaultGroups(), this.populateRoleRequestVO()['groups'])) {
      const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
      const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
      const dialogMsg = [];
      dialogMsg.push(dialogMsg1);
      dialogMsg.push(dialogMsg2);
      const subscription = this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {

            this.roleManagementService.removeDefaultGroups();
            this.roleManagementService.removeDefaultPrivileges();
            this.roleManagementService.resetPrivilegeData(false);
            this.cancelRole();

          } else {
            return;
          }
        });
    } else {

      this.roleManagementService.removeDefaultGroups();
      this.roleManagementService.removeDefaultPrivileges();
      this.roleManagementService.resetPrivilegeData(false);
      this.cancelRole();

    }
  }
  /* Helps to delete a role*/
  public deleteRole() {
    this.alertService.clearAll();
    this.roleManagementService.checkActiveUser(this.selectedApp,this.selectedRoleDetails.roleId)
      .subscribe((res: any) => {
        const dialogMsg = this.resourceService.get(res.statusMessage);
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
          .subscribe((isConfirmed: any) => {
            if (isConfirmed) {
              this.enableClick = false;
              const subscriptionChild = this.roleManagementService.deleteRole(this.selectedApp, this.selectedRoleDetails.roleId)
                .subscribe((resp: any) => {
                  if (resp['statusCode'] && resp['statusMessage']) {
                    if (resp['statusCode'] === 200) {
                      this.alertService.clearAll();
                      this.alertService.success(this.resourceService.translateServerResponse(resp['statusMessage']));
                      this.removeDeletedRole(this.selectedRoleDetails.roleId);
                      this.roleManagementService.removeDefaultGroups();
                      this.cancelRole();
                      this.roleGroup = [];//Jira 1994 to reset the group dropdown after delete operation
                    }
                  } else {
                    if (resp.error) {
                      this.alertService.clearAll();
                      this.alertService.error(this.resourceService.translateServerResponse(resp.error));
                    }
                  }
                }, (error) => {
                  this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
                });
              //  this.subscription.add(subscriptionChild);
            }
          });
      }, (error: any) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }
  /*This method is used to remove role from roles array */
  public removeDeletedRole(roleId: number) {
    this.roles.forEach((role, index) => {
      if (Number(role.roleId) === Number(roleId)) {
        this.roles.splice(index, 1);
      }
    });
  }
  /* Method used to navigate to group management screen -- In Progress */
  public getManageGroupUrl() {
     // LFWM-1994 to reset selected app id 
    this.selectedApp = this.apps[0].appId;
    if (!this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.populateRoleRequestVO()['privileges'])
    ) {
      const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
      const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
      const dialogMsg = [];
      dialogMsg.push(dialogMsg1);
      dialogMsg.push(dialogMsg2);
      const subscription = this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            this.navService.resetBreadcrumb('manage-role').addBreadCrumbItem(this.resourceService.get('mn_manage-group'));
            this.navService.navigateByUrl('/home/admin/groupmanagement');

          } else {
            return;
          }
        });
    } else {
      this.navService.resetBreadcrumb('manage-role').addBreadCrumbItem(this.resourceService.get('mn_manage-group'));
      this.navService.navigateByUrl('/home/admin/groupmanagement');
    }
  }

  /*Method used to copy an existing role and create new one */
  public copyRole(enableCopy) {
    if (enableCopy) {
      this.alertService.clearAll();
      if (this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.populateRoleRequestVO()['privileges'])
      ) {
        this.selectedRole = null;
        this.displayMode = 'C';
        this.navService.preventNavigation = true;
        if (this.previousAction === 'create' || this.previousAction === null) {
          this.accessPrivilegeRole = this.roleManagementService.getDefaultPrivileges();
        } else {
          this.accessPrivilegeRole = JSON.parse(JSON.stringify(this.selectedPrivileges));
        }
        this.roleManagementService.refreshPrivilegeData(this.selectedPrivileges);
        if (this.previousAction === 'edit') {
          this.roleManagementService.removeDefaultPrivileges();
        }
        this.alertService.success(this.resourceService.get('msg_copyrole_success'));
        return;
      } else {
        const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
        const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
        const dialogMsg = [];
        dialogMsg.push(dialogMsg1);
        dialogMsg.push(dialogMsg2);
        const subscription = this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
          .subscribe((isConfirmed: any) => {
            if (isConfirmed) {
              this.selectedRole = null;
              this.displayMode = 'C';
              this.selectedGroups = this.roleManagementService.getDefaultGroups();
              // this.accessPrivilege = JSON.parse(JSON.stringify(this.accessPrivilegeMetadata));
              //  this.accessPrivilege = this.roleManagementService.updateAccessPrivilegePerRole
              //   (this.accessPrivilege, this.accessPrivilegeRole);
              this.roleManagementService.refreshPrivilegeData(this.roleManagementService.getDefaultPrivileges());
              this.showPrivilegePreview = true;
              this.roleManagementService.removeDefaultPrivileges();
            } else {
              return;
            }
          });
      }
    }
  }

  // Jira 720 starts

  public viewPrivilege(roles: any) {
   
  
    // Jira 1927 Role Management - To Disable delete button for Administrator Role
    if (roles === 'Administrator'||roles === 'SUPER_ADMINISTRATOR') {
      this.isAdminRoleSelected = true;
    } else {
      this.isAdminRoleSelected = false;
    }
    //////////////////////////////////////////////////////

    this.groupDisableDropdown = false;
    this.rolesForLookup.forEach(element => {

      if (element.name === roles) {
        this.notifyHyperlinkClick(element);
      }

    });

  }

  // Jira 720 ends

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  public copyRoleConfirmation(enableCopy) {
    this.confirmDialogService.confirmDialog(this.resourceService.get('mn_copyrole_confirm_msg'))
      .subscribe((isConfirmed: any) => {
        if (isConfirmed) {
          this.copyRole(enableCopy);
        }
      });
  }
}
