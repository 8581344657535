export const sceconfig = {
  baseUrlUI: '/',
  logger: 'console',
  wms: {
    baseUrlUI: '/',
    wmsJreportBaseUrl: 'https://report-dev.lflogistics.net',
    wmsJreportBaseUat: 'https://report-uat.lflogistics.net',
    wmsJreportBaseProd: 'https://report.lflogistics.net',
    wmsJreportBasePreProd:'https://report.lflogistics.net',
    wmsJreportAliCloudUat: 'https://report-uat.lflogistics.net.cn',
    wmsJreportAliCloudProd: 'https://report.lflogistics.net.cn',
    wmsUat: 'ofsce-uat.lflogistics.net',
    wmsProd: 'sce.lflogistics.net',
    wmsPreProd:'ofsce-ppd.lflogistics.net',
    wmsAliCloudSit: 'sce-sit.lflogistics.net.cn',
    wmsAliCloudUat: 'sce-uat.lflogistics.net.cn',
    wmsAliCloudProd: 'sce.lflogistics.net.cn',
    commonApiBaseUrl: '../scecommon/'
  },
  dl: {
    dlBaseProdUrl: 'https://utlapp.lflogistics.net',
    dlBasePreProdUrl: 'https://utlapp-uat.lflogistics.net',
    dlBaseUrl: 'https://utlapp-uat.lflogistics.net',
    dlAliCloudBaseProdUrl: 'https://utlapp.lflogistics.net.cn',
    dlAliCloudBaseUrl: 'https://utlapp-uat.lflogistics.net.cn'
  },
  gvt: {
    baseUrlUI: '/gvt',
    gvtApiBaseUrl: '../gvtcommon/'
  },
  dashboard: {
    baseUrlUI: '/dashboard',
    dashboardApiBaseUrl: '../dashboardservice/'
  },
  billing: {
    baseUrlUI: '/billing',
    billingApiBaseUrl: '../billingservice/'

  },
  dataloader: {
    baseUrlUI: '/dataloader',
    dashboardApiBaseUrl: '../dataloaderservice/'
  },
  poms: {
    baseUrlUI: '/poms',
    pomsApiBaseUrl: '../pomsservice/'
  },
  ltpoms: {
    baseUrlUI: '/ltpoms',
    ltpomsApiBaseUrl: ''
  },
  notification: {
    baseUrlUI: '/notification',
    notificationApiBaseUrl: '../notificationservice/'
  }
};