import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Alert } from './alert.model';

@Injectable()
export class AlertService {

  public alertSubject: BehaviorSubject<Alert>;
  public alertIdCtr: number = 0;
  public  targetValueArray = [];
  constructor() {
    const alert: Alert = new Alert();
    alert.id = -1;
    // Alert with negative alertId will be ignored
    this.alertSubject = new BehaviorSubject<Alert>(alert);
  }

  // Returns the observable for subscribing to any
  public getAlertBroadcast(): Observable<Alert> {
    return this.alertSubject.asObservable();
  }

  public createAlert(type: string, messages: string[]): number {
    const alert: Alert = new Alert();
    alert.createdAt = Date.now();
    alert.id = this.alertIdCtr;
    alert.messages = messages;
    alert.severity = type;
    this.alertSubject.next(alert);
    if (this.alertIdCtr === 100) {
      this.alertIdCtr = 0;
    }
    return this.alertIdCtr++;
  }

  public clearAll() {
    const alert: Alert = new Alert();
    alert.id = -1;
    this.alertSubject.next(alert);
    return this;
  }

  public error(message: string | string[]): number {
    const msgs: string[] = (typeof message === 'string') ? [message] : message;
    return this.createAlert('error', msgs);
  }

  public success(message: string | string[]): number {
    const msgs: string[] = (typeof message === 'string') ? [message] : message;
    return this.createAlert('success', msgs);
  }

  public warn(message: string | string[]): number {
    const msgs: string[] = (typeof message === 'string') ? [message] : message;
    return this.createAlert('warning', msgs);
  }

}
