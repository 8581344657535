import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { trigger, state, transition, animate, style} from '@angular/animations';
@Component({
  selector: 'sce-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.css'],
  animations: [
    trigger('tabContent', [
      state('hidden', style({
        height: '0'
      })),
      state('visible', style({
        height: '*'
      })),
      transition('visible <=> hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class SCEAccordionComponent implements OnInit, OnChanges {

  @Input() expanded: boolean;
  @Input() header: string;
  @Output() open:  EventEmitter<boolean> = new EventEmitter();


  public headers: Array<string> = ['', ''];
  public isLoading: boolean = true;

  public ngOnInit() {
    this.isLoading = false;
  }
  // Data-bound input properties needs to be handled in ng Onchanges.
  // Called before ngOnInit() and whenever one or more data-bound input properties change.
  public ngOnChanges() {
    if (this.header) {
      this.headers = this.header.split(',');
    }
  }

  public toggleTab() {
    if (this.expanded) {
      this.open.emit(false);
      this.expanded = false;
    } else {
      this.open.emit(true);
      this.expanded = true;
    }
  }

}
