import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppStorageService, KEYS_APPSTORAGE } from './security/app-storage.service';
import { JreportSession } from './models/jreport-session.model';
import { UserInfo } from './models/user-info.model';
import { UserService } from './security/user.service';
import { sceconfig } from './sce-config';
import { LoaderEnabled } from './components/loader/loader.service';
import {forkJoin , of} from 'rxjs';
import {catchError, delay , map} from 'rxjs/operators';


export const jreportUrls = {
  // 'JREPORT_SERVER_URL':'https://lflreportdev.lfuat.net/jrserver/api/v1.2/',
  'JREPORT_SERVER_URL': sceconfig.wms.wmsJreportBaseUrl,
  'JREPORT_LOGIN': '/jrserver/api/v1.2/session',
  'JREPORT_GETNODE': '/jrserver/api/v1.2/nodes?path=/',
  'JREPORT_REPORT': '/jinfonet/tryView.jsp?jrs.report=',
  'JREPORT_CATALOG': '&jrs.catalog=',
  'JREPORT_RESULT': '&jrs.result_type=8',
  'JREPORT_SETTIMEOUT': '/jrserver/api/v1.2/session/timeout',
  'JREPORT_TIMEOUT': 3600,
  'JREPORT_DASHBOARD':'Dashboard',
  'JREPORT_DASHBOARD_URL_START':'/dashboard/app/entry/run.jsp?jrd_resext={"active":0,"reslst":[{"name":"',
  'JREPORT_DASHBOARD_URL_END':'","ver":-1}]}'
};
@Injectable()
export class JReportService {

  public iframeUrl: any;
  public storer: string;
  public regional: boolean;
  public jreportServerUrl: any;
  public global: boolean;
  public application: string;

  constructor(public http: HttpClient, public appStorageService: AppStorageService,
    public userService: UserService) {
    //checking the jreport ur based onthe environment
    //the url for each environment is mentioned in configfile
    switch (window.location.hostname) {
      case sceconfig.wms.wmsProd:
        this.jreportServerUrl = sceconfig.wms.wmsJreportBaseProd;
        break;
      case sceconfig.wms.wmsPreProd:
          this.jreportServerUrl = sceconfig.wms.wmsJreportBasePreProd;
          break;  
      case sceconfig.wms.wmsUat:
        this.jreportServerUrl = sceconfig.wms.wmsJreportBaseUat;
        break;
      case sceconfig.wms.wmsAliCloudProd:
        this.jreportServerUrl = sceconfig.wms.wmsJreportAliCloudProd;
        break;
      case sceconfig.wms.wmsAliCloudUat:
        this.jreportServerUrl = sceconfig.wms.wmsJreportAliCloudUat;
        break;
      case sceconfig.wms.wmsAliCloudSit:
          this.jreportServerUrl = sceconfig.wms.wmsJreportAliCloudUat;
        break;  
      default:
        this.jreportServerUrl = sceconfig.wms.wmsJreportBaseUrl;
        break;

    }

  }
  /* Authenticates the the user with username and password.
  Also updates userinfo object. */
  public loginJReport(password) {
    const jreportUsername = this.userService.userInfo.userName;
    const mergedUserInfo: UserInfo = this.appStorageService.getItem(KEYS_APPSTORAGE.USER_INFO);
    mergedUserInfo.jReportSession = new JreportSession;
    return this.http.post(this.jreportServerUrl + jreportUrls.JREPORT_LOGIN, 'username=' + encodeURIComponent(jreportUsername) + '&password=' + encodeURIComponent(password)).pipe(
      map((response: any) => {
        // login successful
        if (response) {
          mergedUserInfo.jReportSession.jReportStatus = 0;
          mergedUserInfo.jReportSession.jReportStatusMessage = '';
          mergedUserInfo.jReportSession.jReportSessionId = response.sessionId;
          this.appStorageService.setItem(KEYS_APPSTORAGE.USER_INFO, mergedUserInfo);
          this.userService.userInfo = mergedUserInfo;
        }
      }),catchError((error) => {
        if (error.status) {
          mergedUserInfo.jReportSession.jReportStatus = error.status;
          mergedUserInfo.jReportSession.jReportStatusMessage = error.statusText;
          mergedUserInfo.jReportSession.jReportSessionId = '';
          this.appStorageService.setItem(KEYS_APPSTORAGE.USER_INFO, mergedUserInfo);
          this.userService.userInfo = mergedUserInfo;
          return Observable.throw(error);
        }
      }));
  }

  @LoaderEnabled()
  public getReportDetails(url: any) {
    const response$ = this.http.get(url).pipe(
       map((response: any) => {
        return response;
      }),catchError((error) => {
        return Observable.throw(error);
      }));
    return response$;
  }

  public jreportIsSessionAlive(): Observable<any> {
    const response$ = this.http.get(this.jreportServerUrl + jreportUrls.JREPORT_LOGIN).pipe(
      map((response: any) => {
        return response;
      }),catchError((error) => {
        return Observable.throw(error);
      }));
    return response$;

  }
  public getIFrameUrl() {
    return this.iframeUrl;
  }

  public setIFrameUrl(path: any, linkedCatalog: string,type :string) {
    if(type == jreportUrls.JREPORT_DASHBOARD){
      this.iframeUrl= this.jreportServerUrl +jreportUrls.JREPORT_DASHBOARD_URL_START +path+jreportUrls.JREPORT_DASHBOARD_URL_END;
    } else {
      this.iframeUrl = this.jreportServerUrl + jreportUrls.JREPORT_REPORT + path +
      jreportUrls.JREPORT_CATALOG + linkedCatalog + jreportUrls.JREPORT_RESULT;
     }
   }
   //LFWM-2093- setting timeout for jreport
  public setJreportTimeOut() {
    let request = 'timeout=' + jreportUrls.JREPORT_TIMEOUT;
    const response$ = this.http.put(this.jreportServerUrl + jreportUrls.JREPORT_SETTIMEOUT, request, { responseType: 'text' }).pipe(
       map((response: any) => {
        return response;
      }),catchError((error: HttpErrorResponse) => {
        return Observable.throw(error);
      })).subscribe(logInState2 => {
        console.log('set timeout success', logInState2);
      }, (error1) => {
        console.log('set timeout error', error1);
      });
    return response$;
  }
  // Get GetNode API url
  public fetchGetNodeUrl(regional: boolean, countryCode: any, storer: string, global?:boolean): Observable<any> {
    if (regional) {
      countryCode = 'Regional';
    }  
    if(global){
      countryCode = 'Global';
    }
    if(this.application === '' || this.application === null){
      this.application = 'WMS'
    }
    if(storer === ''|| storer === null){
      storer ='*';
    }
    const url = 'api/setup/jreport/' + countryCode + '/' + storer +'/' + this.application + '/url/';
    const response$ = this.http.get(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return Observable.throw(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }
  @LoaderEnabled()
  public getJreports(urls): Observable<any> {
    let reportList = [];
    urls.forEach(url => reportList.push(this.http.get(url).pipe(
      delay(500),
      map(response => response),
      catchError(error => of(error))
    )));
    return forkJoin(reportList,
      (...results) => ({
        failed: results.map(r => r instanceof HttpErrorResponse ? r : null),
        succeeded: results.map(r => r instanceof HttpErrorResponse ? null : r)
      })
    );
  }

  // Get GetNode API url
  public fetchApplications(): Observable<any> {
    const url = 'api/data/codelkup/apps';
    const response$ = this.http.get(url).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        if (error.error.length !== 0) {
          if (error.error.statusCode !== 200) {
            return Observable.throw(error.error.statusMessage);
          }
        }
      }));
    return response$;
  }
}
