import { Injectable, EventEmitter } from '@angular/core';

const LEFT_PANEL_WIDTH: number = 350;   /* width for open state..change in css also (.leftpanel-open) */

@Injectable()
export class LeftPanelService {

  public isOpenState;
  public leftPanelOpened = new EventEmitter<boolean>();

  public getLeftPanelOpenEvent() {
      return this.leftPanelOpened.asObservable();
  }
  public isOpened() {
    return this.isOpenState;
  }
  public getLeftPanelWidthInPixels(): number {
    return LEFT_PANEL_WIDTH;
  }
  public openLeftPanel() {
    this.leftPanelOpened.emit(true);
    this.isOpenState = true;
  }

  public closeLeftPanel() {
    this.leftPanelOpened.emit(false);
    this.isOpenState = false;
  }

  public toggleLeftPanel() {
    this.isOpenState = ! this.isOpenState;
    this.leftPanelOpened.emit( this.isOpenState);
  }

}
