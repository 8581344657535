import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserManagementService } from '../../user-management/user-management.service';
import { DowntimeConfigurationService } from '../downtime-configuration.service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import moment from 'moment';
import { ModalService } from '../../components/modal/modal.service';
import { DatePickerConfigService } from '../../components/datepicker/datepicker-config.service';
import { ResourceService } from '../../resource.service';
import { AlertService } from '../../components/alerts/alert.service';

@Component({
  selector: 'lfwms-schedule-downtime-popup',
  templateUrl: './schedule-downtime-popup.component.html',
  styleUrls: ['./schedule-downtime-popup.component.css']
})
export class ScheduleDowntimePopupComponent implements OnInit {

  public applicationList: Array<any> = [];
  public downStartTime: Date;
  public downEndTime: Date;
  public displayMode: any;
  public downtime: any;
  public eventSubject = new Subject<any>();
  public enableTextArea: boolean;
  public downTimeMessage: any;
  public editableDownTimeMessage: any;

  constructor(public modalService: ModalService,
    public resourceService: ResourceService,
    public downtimeConfigurationService: DowntimeConfigurationService,
    public alertService: AlertService,
    public datePickerConfigService: DatePickerConfigService,
    public userManagementService: UserManagementService
  ) {
    this.eventSubject = new Subject<any>();
  }

  ngOnInit() { }

  public initializeComponents(applicationList: any, item?: any) {
    this.applicationList = applicationList;
    // item is only passed in edit condition.
    // In edit mode the dates are populated, and the checkboxes are checked based on the selected apps.
    if (item) {
      this.downtime = item;
      this.displayMode = "E";
      this.downStartTime = item['downTimeStart'];
      this.downEndTime = item['downTimeEnd'];
      this.downTimeMessage = item['downTimeMessage'];
      this.editableDownTimeMessage = item['downTimeMessage'].substr(item['downTimeMessage'].indexOf('During'));
      this.applicationList.forEach(app => {
        // code to enable the checkbox if the appcode in item is present in the appList of item.
        // if the appList of item has all apps, then check all the checkboxes.
        if (item.appList.length === this.applicationList.length - 1) {
          app.isEnabled = true;
        } else {
          let index = item.appList.indexOf(app.code);
          if (index === -1) {
            app.isEnabled = false;
          } else {
            app.isEnabled = true;
          }
        }

      });
      this.enableTextArea = true;
      // if display mode is create, checkboxes are not checked.
    } else {
      this.displayMode = "C";
      this.applicationList.forEach(app => {
        app.isEnabled = false;
      });
    }
  }
  // to get the display value of corresponding key from the resource bundle
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  // to add downtime and to update downtime.
  public addDowntime() {
    this.alertService.clearAll();
    let request: any = {};
    let apps: Array<any> = [];
    let checkedList: Array<any> = [];
    const successMsg = 'msg_save_succ';
    // list of elements  are filtered based on whether checkboxes are enabled
    //  and the appcode of that filtered data is stored inside checkedList 
    apps = this.applicationList.filter(app => app.isEnabled === true);
    apps.forEach((app: any) => {
      if (app.code !== 'All') {
        checkedList.push(app.code);
      }
    });
    // request are different for create mode and edit mode.
    // only if the validations of date are true, then only API call is made.

    if (this.validateDateEntered(this.downStartTime) && this.validateDateEntered(this.downEndTime)
      && this.validEndDate(this.downStartTime, this.downEndTime)) {
      // LFWM-2916- converting the downStartTime and downEndTime to utc and saving the utc times in DB.
      let downStartTimeInUtc = this.downtimeConfigurationService.convertTimeToUtc(this.downStartTime);
      let downEndTimeInUtc = this.downtimeConfigurationService.convertTimeToUtc(this.downEndTime);
      // request for create mode.
      if (this.displayMode === 'C') {
        request = {
          "appCode": checkedList,
          "downTimeStart": downStartTimeInUtc,
          "downTimeEnd": downEndTimeInUtc,
          "downTimeMessage": this.downTimeMessage
        }
        // for update
      } else {
        request = {
          "downTimeId": this.downtime['downTimeId'],
          "appCode": checkedList,
          "active": this.downtime['active'],
          "downTimeStart": downStartTimeInUtc,
          "downTimeEnd": downEndTimeInUtc,
          "downTimeMessage": this.downTimeMessage
        }
      }

      this.downtimeConfigurationService.addDowntime(request)
        .subscribe((resp: any) => {
          if (resp['statusCode'] && resp['statusMessage']) {
            if (resp['statusCode'] === 200) {
              this.alertService.clearAll().success(this.resourceService.get(successMsg));
              const populateEvent = {
                'type': 'addedSuccessfully'
              };
              this.notifyEvent(populateEvent);
              this.modalService.modalReference.hide();
            }
          } else {
            if (resp.error) {
              this.alertService.clearAll();
              this.alertService.error(this.resourceService.translateServerResponse(resp.error));
            }
          }
        }, (error) => {
          this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
        });
    }
  }

  public notifyEvent(value: any) {
    this.eventSubject.next(value);
  }

  public receiveEvent(): Observable<any> {
    return this.eventSubject.asObservable();
  }

  // to store date entered
  public capturingDate(startDate: any, endDate: any) {
    let flag = true;
    if (endDate) {
      if ((this.validateDateEntered(startDate)) && (this.validateDateEntered(endDate))) {
        this.downStartTime = startDate;
        if (this.validEndDate(startDate, endDate)) {
          this.downEndTime = endDate;
          this.enableTextArea = true;
          // when changes are made in dates, message is changed dynamically.
          this.showMessage(this.downStartTime, this.downEndTime, flag);
        }
        else {
          this.alertService.clearAll().error(this.resourceService.get('lbl_enter_valid_end_date'));
        }
      } else {
        this.alertService.clearAll().error(this.resourceService.get('lbl_enter_valid_date'));
      }
    } else {
      if (this.validateDateEntered(startDate)) {
        this.downStartTime = startDate;
        this.enableTextArea = true;
        // when changes are made in dates, message is changed dynamically.
        this.showMessage(this.downStartTime, endDate, flag);
      } else {
        this.alertService.clearAll().error(this.resourceService.get('lbl_enter_valid_date'));
      }
    }
  }

  // code for checking the checkboxes
  public selectCheckboxes(app: any) {
    let flag = true;
    app.isEnabled = !app.isEnabled;
    // if All is enabled and one or more codes are unchecked then All should also be unchecked.
    let all = this.applicationList.filter(app => app.code === 'All');
    if (app.isEnabled === false && all[0].isEnabled === true) {
      all[0].isEnabled = false;
    }
    if (app.code === 'All') {
      this.applicationList.forEach((element: any) => {
        element.isEnabled = app.isEnabled;
      });
    }
    // when changes are made in checkboxes message is changed dynamically.
    this.showMessage(this.downStartTime, this.downEndTime, flag);
  }
  //  the entered date should be greater or equal to today's date.
  public validateDateEntered(date: any) {
    let today = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
    if (date >= today) {
      return true;
    } else {
      return false;
    }
  }

  // the entered downEndTime should be greater than downStartTime.
  public validEndDate(startDate: any, date: any) {
    if (startDate < date) {
      return true;
    } else {
      return false;
    }
  }

  // [LFWM-2861]- Code to show dynamic message.
  public showMessage(startDate: any, endDate: any, flag?: any) {
    // list of elements  are filtered based on whether checkboxes are enabled
    //  and the appcode of that filtered data is stored inside checkedList.
    let apps: Array<any> = [];
    let checkedList: Array<any> = [];
    let appName: any;
    //LFWM-3612- Replacing some of the app codes with the application names.
    apps = this.applicationList.filter(app => app.isEnabled === true);
    apps.forEach((app: any) => {
        switch (app.code) {
          case 'All': {
            break;
          }
          case 'DL': {
            appName = 'Data Loader';
            checkedList.push(appName);
            break;
          }
          case 'DSHB': {
            appName = 'Dashboard';
            checkedList.push(appName);
            break;
          }
          case 'POMS': {
            appName = 'Global Control';
            checkedList.push(appName);
            break;
          }
          default: {
            checkedList.push(app.code);
          }  
      }
    });
    let sizeOfList = checkedList.length;
    if (sizeOfList > 1) { 
      checkedList[sizeOfList - 1] = "and " + checkedList[sizeOfList - 1]; 
    }
    // downtimeMessage is formed only if dates have value and checkedList is not empty.
    if (startDate !== undefined && endDate !== undefined && checkedList.length !== 0) {
      let finalDate = moment(new Date(endDate), 'DD/MM/YYYY HH:mm:ss');
      let initialDate = moment(new Date(startDate), 'DD/MM/YYYY HH:mm:ss');
      let duration = moment.duration(finalDate.diff(initialDate));
      let hours = Math.floor(duration.asHours()) + moment.utc(finalDate.diff(initialDate)).format(':mm:ss');
      //LFWM-2916- only the first textarea will be editable by user.
      if (this.editableDownTimeMessage === undefined || flag === true) {
        this.editableDownTimeMessage = this.resourceService.get('lbl_during_maintenance') + " " + checkedList.toString().split(',').join(', ') + " " + this.resourceService.get('lbl_not_accessible') + " " + this.resourceService.get('lbl_downtime_last_line');
        let message1 = (this.editableDownTimeMessage.substr(0, this.editableDownTimeMessage.indexOf(", and")));
        let message2 = (this.editableDownTimeMessage.substr(this.editableDownTimeMessage.indexOf(", and") + 1));
        this.editableDownTimeMessage = message1 + message2;
      } else {
        this.editableDownTimeMessage;
      }
      //LFWM-3612-reconstructing the message as per the user suggestion.
      this.downTimeMessage = this.resourceService.get('lbl_downtime_first_line') +
        '\n' + this.resourceService.get('lbl_from') + " " + moment(startDate).format("MMM DD Y, h:mm:ss a") +
        '\n' + this.resourceService.get('lbl_to') + " " + moment(endDate).format("MMM DD Y, h:mm:ss a") +
        '\n' + this.resourceService.get('lbl_duration') + ':' + hours + " " + 'hours' +
        '\n' + this.editableDownTimeMessage;

    } else {
      this.downTimeMessage = "";
      this.editableDownTimeMessage = "";
    }
  }
}

