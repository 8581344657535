import { Component, OnInit, Input } from '@angular/core';
import { AlertService } from './alert.service';
import { Alert } from './alert.model';
import { IntervalTimer } from './interval-timer';

@Component({
  selector: 'sce-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {

  @Input() public privateAlertService: AlertService;

  public alerts: Alert[] = [];
  public intervalTimer: IntervalTimer;

  constructor(public alertservice: AlertService) {
    this.intervalTimer = new IntervalTimer(() => {
      let isContinue: boolean = false;
      this.alerts
        .forEach((filterAlert: Alert) => {
          // [LFWM-2042] - Increasing the duarion of disaplaying the alert message to 2 minutes [1 min - 60000 ms]
          if ((Date.now() - filterAlert.createdAt) >= 120000) {
            filterAlert.isObsolete = true;
          } else {
            isContinue = true;
          }
        });
      if (!isContinue) {
        this.intervalTimer.stop();
        this.alerts = [];
      }
    }, 1000);
  }

  public ngOnInit() {
    if (this.privateAlertService) {
      this.alertservice = this.privateAlertService;
      /* use the privateAlertService if available and
       ignore constructor injected one */
    }
    this.alertservice.getAlertBroadcast()
      .subscribe((alert: Alert) => {
        if (alert.id === -1) {
          if (this.intervalTimer.timerID) {
            this.intervalTimer.stop();
          }
          this.alerts = [];
        } else {
          this.alerts.push(alert);
          if (!this.intervalTimer.timerID) {
            this.intervalTimer.start();
          }
        }
      });
  }

}

