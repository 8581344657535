import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginRedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const currentUrl = state.url;
    if (currentUrl === '/login') {
      const origin = window.location.origin
      if (origin.includes('cdt')) {
        window.location.href = 'https://cdt-wms.fulfillment.maersk.com/';
        return false;
      } else if (origin.includes('stage')) {
        window.location.href = 'https://stage-wms.fulfillment.maersk.com/';
        return false;
      } else if (origin.includes('americas')) {
        window.location.href = 'https://americas-wms.fulfillment.maersk.com/';
        return false;
      }
    }
    return true;
  }
}
