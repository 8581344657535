import { Component, TemplateRef, OnInit, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/Subscription';
import { RoleManagementService } from '../role-management/role-management.service';
import { AddRoleRequestModel } from './models/add-role-request.model';

import { UserManagementService } from '../user-management/user-management.service';

import { GroupManagementService } from '../group-management/group-management.service';
import { ModalService } from '../components/modal/modal.service';
import { AlertService } from '../components/alerts/alert.service';
import { NavigationService } from '../navigation.service';
import { ConfirmDialogService } from '../components/confirm-dialog/confirm-dialog.service';
import { ResourceService } from '../resource.service';
import { UserService } from '../security/user.service';


@Component({
  selector: 'lfwms-field-management',
  templateUrl: './field-management.component.html',
  styleUrls: ['./field-management.component.css']
})
export class FieldManagementComponent implements OnInit, OnDestroy {
  private groupsList: any = [];
  private rolesList: any = [];
  public selectedAppId: number;
  public selectedGroupId: number;
  public selectedRoleId: number;
  public privilegeRoleId: number;

  private currentGroup: any;
  public currentGroupRoles: any = [];
  public selectedRole: string;
  public selectedRoleDetails: any;
  public selectedGroups: any;
  public selectedApp: number;
  public apps: any;
  public roles: any;
  public groups: any;
  public allRoleGroups: any;
  public allRoleGroupsMetaData: any = [];
  public modalRef: any;
  public accessResponse: any;
  public accessPrivilegeRole: any;
  public updatedAccessPrivilegeReq: any;
  public selectedPrivileges: Array<any> = [];
  public dropdownSettings = {};
  public displayMode: any;
  public texts = {};
  public response = {};
  public enableClick: Boolean = false;
  public config = { animated: false };
  public groupDetails = {};
  public previousAction: string;
  public isTemplateLoaded: Boolean;
  public showPrivilegePreview: Boolean;
  public rolesForLookup: any;
  public subscription: Subscription = new Subscription();
  @Input() disableDropdown: boolean = false;
  public groupDisableDropdown: Boolean = true;
  public formattedPrivilegeData: any;
  public formattedPrivilegeMetaData: any;
  public deafultPrivileges: any = [];
  public showOverlay: boolean = false;
  public privilegeTypeGroupManagement: string = 'B'

 // Jira 1927 Role Management - Disable delete for Administrator Role
  public isAdminRoleSelected: boolean = false;

  // Jira 720 starts
  public roleGroup: any = [];
  public roleGroupId: any = [];
  public roleMngBtnEdit: boolean;
  

// LFWM-1994 To check the selected app id in dropdown 
public appDropdown:number;
// LFWM-1994 AppId for wms and gvt
public gvtAppId: number=2;
public billingAppId: number=5;
public pomsAppId: number=6;
public privilegeTypeFieldManagement:string='F';//setting field type as privilege for field management

  constructor(public route: ActivatedRoute,
    public userManagementService: UserManagementService,
    public modalService: ModalService,
    public roleManagementService: RoleManagementService,
    public alertService: AlertService,
    public navService: NavigationService,
    public confirmDialogService: ConfirmDialogService,
    public resourceService: ResourceService,
    public userService: UserService,
    public groupManagementService: GroupManagementService,
    public activatedRoute: ActivatedRoute) {
  }

  public ngOnInit() {
    this.initializeApps();
    /* --- this variable will set default mode as edit ---*/
    this.displayMode = 'E';
    this.previousAction = null;
    /* code changes to show non navigable bread crumb - This will be removed in future -- Start */
    this.navService.removeLastBreadCrumbItem();
    this.navService.addNonNavigableBreadCrumb('Field Management');
    /* code changes to show non navigable bread crumb - This will be removed in future -- Stop */
    this.initializeRoles(this.gvtAppId);// LFWM-1994 passing the appid 1 for initial loading
    // this.initializeDefaultAccessPrivilege();
    this.initializeAllRoleGroups(this.gvtAppId);// LFWM-1994 passing the appid 1 for initial loading
    this.activatedRoute.params.subscribe(params => {
      this.navService.setPageTitle(this.resourceService.get('mn_fieldmgt'));
    });
    this.roleManagementService.initializePrivilegeTemplate(this.gvtAppId,this.privilegeTypeFieldManagement);// LFWM-1994 passing the appid 1 for initial loading
    this.roleManagementService.onTemplateLoaded
      .subscribe((data: boolean) => {
        this.isTemplateLoaded = data;
        this.formattedPrivilegeData = this.roleManagementService.formattedPrivilegeData;
        this.formattedPrivilegeMetaData = JSON.parse(JSON.stringify(this.formattedPrivilegeData));
      });

    this.roleMngBtnEdit = this.checkButtonPrivilege('role_edit');
  }

  public checkButtonPrivilege(privilegeCode: String) {
    return this.userService.checkButtonPrivilege(privilegeCode);
  }
  /* This method will fetch all the groups corresponds to app id in the application and bind it to UI */
  public initializeAllRoleGroups(appId) {// LFWM-1994 passing the appid as a parameter 
    this.subscription = this.userManagementService.getAllGroups(appId)
      .subscribe((resp: any) => {      
        this.groups = [];
        this.allRoleGroups = resp;
        resp.forEach((group, indexGroup) => {
          this.allRoleGroupsMetaData.push(group);
          this.groupDetails = { 'id': group.groupId, 'name': group.groupName };
          this.groups.push(this.groupDetails);
        });
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
    // return this.groups;
  }
  /* This method will fetch all the apps in the application and bind it to UI */
  public initializeApps() {
    const subscription = this.userManagementService.getAllApps()
      .subscribe((resp: any) => {
        // LFWM-1994 to check whether the logged in use has gvt access,if not,filter out gvt from the response and show details in dropdown
        
          let appsList=[];
          resp.forEach(element => {
            if(element.appId== this.gvtAppId ){
              appsList.push(element)
            }
            if(element.appId== this.billingAppId ){
              appsList.push(element)
            }
            if(element.appId == this.pomsAppId){
              appsList.push(element)
            }
          });
        this.apps = appsList;
        this.selectedApp = this.apps[0].appId;
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
    this.subscription.add(subscription);
  }

  //Added Billing App in field Management
  public changeSelectedAppId(selectedApp) {
    // LFWM-1994 when the dropdown is changed resettin the display mode to 'E'
    this.displayMode = 'E'
    this.rolesForLookup = []
    /*** dashboard ***/
    this.enableClick = false;
    if (selectedApp == this.gvtAppId) {
      this.appDropdown = this.gvtAppId;
    } else if (parseInt(selectedApp, 10) === this.billingAppId) {
      /*** LB-11 LF SCE Billing | Group Management */
      this.appDropdown = this.billingAppId;
    } else if(selectedApp == this.pomsAppId){
      this.appDropdown = this.pomsAppId;
    }
    this.initializeAllRoleGroups(selectedApp);// LFWM-1994 passing selected appid as a parameter for fetching template,roles and groups
    this.roleManagementService.initializePrivilegeTemplate(selectedApp, this.privilegeTypeFieldManagement);
    this.userManagementService.getAllRoles(selectedApp)
      .subscribe((resp: any) => {
        this.roles = resp;
        this.rolesForLookup = this.roles;
        this.selectedRole = null;
        this.groupDisableDropdown = true;
        this.roleGroup = [];
        this.showPrivilegePreview = false;
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }

  /* This method will fetch all the roles in the application and bind it to UI */
  public initializeRoles(appId) {// LFWM-1994 passing selected appid as parameter
    const subscription = this.userManagementService.getAllRoles(appId)// LFWM-1994passing selected appid as parameter
      .subscribe((resp: any) => {
        this.roles = resp;
        this.rolesForLookup = this.roles;
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
    this.subscription.add(subscription);
  }

  /* method used to translate the labels */
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }



  public filterLookup(event: any, template: any) {
    if (this.selectedRole) {
      const val = this.selectedRole.toLowerCase();
      this.rolesForLookup = this.roles.filter(function (d) {
        return d.name.toLowerCase().indexOf(val) !== -1 || !val;
      });
    } else {
      this.rolesForLookup = this.roles;
    }
    this.openLookup(template);
  }  /*This method helps to open lookup screen for roles*/
  public openLookup(template: TemplateRef<any>) {
    if (this.rolesForLookup && this.rolesForLookup.length !== 0) {
      const modalRef: any = this.modalService
        .showModal(template,
          {
            overlayHeader: '',
            showHeader: true,
            overlayHeight: 520,
            overlayWidth: 1024
          });
    } else {
      this.alertService.clearAll().error(this.resourceService.get('msg_role_val'));
    }
  }

  /*Helps to assign selected role value to text field from loop up screen*/
  public notifyHyperlinkClick(roleDetail: any) {
      if (this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.populateRoleRequestVO()['privileges'])) {

      this.initializelookup(roleDetail);
    } else {
      if (!this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.populateRoleRequestVO()['privileges'])
      ) {
        const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
        const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
        const dialogMsg = [];
        dialogMsg.push(dialogMsg1);
        dialogMsg.push(dialogMsg2);
        if (this.modalRef) {
          this.modalRef.hide();
        }
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
          .subscribe((isConfirmed: any) => {
            if (isConfirmed) {
              this.initializelookup(roleDetail);

            } else {
              return;
            }
          });
      } else {
        this.initializelookup(roleDetail);
      }
    }
  }

  public initializelookup(roleDetail: any) {   
    this.cancelRole();
    this.deafultPrivileges = [];
    // this.disableToggleButtonOnLoad = false;
    this.enableClick = true;
    this.selectedRole = roleDetail.name;
    this.selectedRoleDetails = roleDetail;

    if (this.modalRef) {
      this.modalRef.hide();
    }
    this.selectedGroups = [];
    // this.roleManagementService.setFormatedData(this.formattedPrivilegeMetaData);
    this.allRoleGroups.forEach((group, indexGroup) => {
      group.roles.forEach((role, indexRole) => {
        this.selectedGroups.push(group.groupId);
        if (roleDetail.roleId === role.id) {
          this.selectedGroups = Array.from(new Set(this.selectedGroups));

        }
      });
    });


    // Jira 720 starts
    this.roleGroup = [];
    this.roleGroupId = [];
    for (let i = 0; i < this.allRoleGroups.length; i++) {

      for (let j = 0; j < this.allRoleGroups[i].roles.length; j++) {

        if (this.allRoleGroups[i].roles[j].role === this.selectedRoleDetails.name) {
          this.roleGroup.push(this.allRoleGroups[i].groupName);
          this.roleGroupId.push(this.allRoleGroups[i].groupId);
        }
      }
    }

    if (this.roleGroup.length === 0) {
      this.groupDisableDropdown = true;

    } else {
      this.groupDisableDropdown = false;
    }

    // Jira 720 ends
    this.roleManagementService.setDefaultGroups(JSON.parse(JSON.stringify(this.selectedGroups)));

    /*** LB-2 LF SCE Billing | Role Management */
    if (this.appDropdown === this.gvtAppId){
      this.selectedApp=this.appDropdown;
    } else if(this.appDropdown==this.billingAppId){
      this.selectedApp=this.appDropdown;
    } else if(this.appDropdown==this.pomsAppId){
      this.selectedApp = this.appDropdown;
    }
  
    this.roleManagementService.getAccessPrivilegeRole(this.selectedApp, this.selectedRoleDetails.roleId,this.privilegeTypeFieldManagement)
      .subscribe((resp: any) => {
        if (resp['statusCode'] && resp['statusMessage']) {
          if (resp['statusCode'] === 200) {
            this.accessPrivilegeRole = [];
            this.accessPrivilegeRole = JSON.parse(JSON.stringify(resp.privileges));
            this.roleManagementService.refreshPrivilegeData(resp.privileges);
            this.showPrivilegePreview = true;
          }
        } else {
          if (resp.error) {
            this.alertService.clearAll();
            this.alertService.error(this.resourceService.translateServerResponse(resp.error));
          }
        }
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }



  /* Method is used to create request vo for save role functionality */
  public populateRoleRequestVO(): AddRoleRequestModel {
    this.updatedAccessPrivilegeReq = {};
    this.selectedPrivileges = [];
    this.formattedPrivilegeData.forEach((subModule, indexSubModule) => {
      /* Code changes to push privilege id of the module --Start */
      if (subModule.isChecked && subModule.id) {
        this.selectedPrivileges.push(subModule.id);
      }
      /* Code changes to push privilege id of the module -- End */
      subModule.privileges.forEach((privileges, indexPrivileges) => {
        privileges.forEach((privilege, indexPrivilege) => {
          if (privilege.id && privilege.isChecked === true) {
            this.selectedPrivileges.push(privilege.id);
          }
        });
      });
    });
    this.updatedAccessPrivilegeReq = new AddRoleRequestModel();
    this.updatedAccessPrivilegeReq.privileges = Array.from(new Set(this.selectedPrivileges));
    this.updatedAccessPrivilegeReq.roleName = this.selectedRole;
    this.updatedAccessPrivilegeReq.privType = this.privilegeTypeFieldManagement;//LFWM-1994 added privilege type 'F' for Field management
    if (this.selectedRoleDetails && this.displayMode === 'E') {
      this.updatedAccessPrivilegeReq.roleId = this.selectedRoleDetails.roleId;
    } else {
      this.updatedAccessPrivilegeReq.roleId = null;
    }
    this.updatedAccessPrivilegeReq.appId = this.selectedApp;
    this.updatedAccessPrivilegeReq.groups = this.roleGroupId;

    // this.updatedAccessPrivilegeReq.groups = this.selectedGroups;
    return this.updatedAccessPrivilegeReq;
  }

  public setDefaultPrivileges() {
    this.formattedPrivilegeData.forEach((subModule, indexSubModule) => {
      subModule.privileges.forEach((privileges, indexPrivileges) => {
        privileges.forEach((privilege, indexPrivilege) => {
          if (privilege.id && privilege.isChecked === true) {
            this.selectedPrivileges.push(privilege.id);
          }
        });
      });
    });
  }


  /*Used for save role information*/
  public saveRole() {
    this.alertService.clearAll();
    if (!this.validateDateTime()) {
      const errorMsg = 'Select Date or Time combination in Global Tracking Detail Event ';
      this.alertService.clearAll().warn(this.resourceService.get(errorMsg));
      return;
    }
    this.updatedAccessPrivilegeReq = this.populateRoleRequestVO();
    // prompting the warning message when no modifications are done to the  role and trying to save.
    if (this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.selectedPrivileges) && this.displayMode === 'E'
      && this.validateGroup(this.roleManagementService.getDefaultGroups(), this.selectedGroups)) {
      const errorMsg = this.resourceService.get('mn_mdfy_role_msg');
      this.alertService.clearAll().warn(this.resourceService.get(errorMsg));
      return;
    } else {
      if (this.displayMode === 'E') {
        this.previousAction = 'edit';
      } else {
        this.previousAction = 'create';
      }
      if (this.updatedAccessPrivilegeReq.roleName && this.updatedAccessPrivilegeReq.roleName.trim()) {
        this.roleManagementService.saveRole(this.updatedAccessPrivilegeReq)
          .subscribe((resp: any) => {
            if (resp['statusCode'] && resp['statusMessage']) {
              if (resp['statusCode'] === 200) {
                this.alertService.clearAll().success(resp['statusMessage']);
                this.displayMode = 'E';
                this.roleManagementService.setDefaultPrivileges(this.selectedPrivileges);
                this.roleManagementService.setDefaultGroups(JSON.parse(JSON.stringify(this.selectedGroups)));
                this.roleManagementService.refreshPrivilegeData(this.selectedPrivileges);
                if (this.updatedAccessPrivilegeReq.roleId === null) {
                  const role = { 'roleId': resp['userRole'].id, 'name': resp['userRole'].role, 'code': resp['userRole'].role };
                  this.roles.push(role);
                  this.selectedRole = resp['userRole'].role;
                  this.selectedRoleDetails = role;
                  this.enableClick = true;
                }
                this.updateGroup(this.updatedAccessPrivilegeReq.groups, resp['userRole']);
                this.navService.preventNavigation = false;
              }
            } else {
              if (resp.errors) {
                this.alertService.clearAll();
                this.alertService.error(this.resourceService.translateServerResponse(resp.errors[0].validationMessage));
              }
            }
          }, (error) => {
            this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
          });
      } else {
        this.alertService.clearAll().error(this.resourceService.get('msg_role_man'));
        this.selectedRole = '';
      }
    }
  }
  /* Method used to update group list */
  public updateGroup(groups, roles) {
    this.allRoleGroups = JSON.parse(JSON.stringify(this.allRoleGroupsMetaData));
    groups.forEach((groupId, indexGroup) => {
      this.allRoleGroups.forEach((group, indexGrup) => {
        if (groupId === group.groupId && group.roles.indexOf(roles) === -1) {
          group.roles.push(roles);
        }
      });
    });

  }
  public validateDateTime(): boolean | undefined {
    let expectedDate = false;
    let expectedDateTime = false;
    let actualDate = false;
    let actualDateTime = false;
    this.formattedPrivilegeData.forEach(data => {
      if (data.displayId === 'Global Tracking Detail Event') {
        data.privileges.forEach(items => {
          items.forEach(item => {
            if (item.display === 'Expected Date' && item.isChecked) {
              expectedDate = true;
            }
            if ((item.display === 'Expected Date Time' || item.display === 'Expected Time') && item.isChecked) {
              expectedDateTime = true;
            }
            if (item.display === 'Actual Date' && item.isChecked) {
              actualDate = true;
            }
            if ((item.display === 'Actual Time' || item.display === 'Actual Date Time') && item.isChecked) {
              actualDateTime = true;
            }
          });
        });
      }
    });
    if ((actualDate && !expectedDate && expectedDateTime) ||
      (expectedDate && !actualDate && actualDateTime) ||
      (actualDate && actualDate && (!expectedDate && !expectedDateTime))) {
      return false;
    } else if ((actualDateTime && expectedDate && !expectedDateTime) ||
      (expectedDateTime && expectedDate && !actualDateTime) ||
      (expectedDate && expectedDateTime && (!actualDate && !actualDateTime))) {
      return false;
    } else {
      return true;
    }
  }
  public validatePrivileges(defaultPrivileges: any, selectedPrivileges: any) {
    //  If we have no default privilages then also it may have some modifications i fthere are selectedrpivilages
    if (defaultPrivileges.length > 0 && selectedPrivileges.length > 0) {
      const modifiedPrivileges = defaultPrivileges.filter(item => selectedPrivileges.indexOf(item) < 0);

      const modifiedPrivilegesRev = selectedPrivileges.filter(item => defaultPrivileges.indexOf(item) < 0);

      if (modifiedPrivileges.length > 0 || modifiedPrivilegesRev.length > 0) {
        return false;
      } else {
        return true;
      }
    } else if (selectedPrivileges.length > 0 || defaultPrivileges.length > 0) {
      /*  it comes here when  defaultprivileges is zero but we have modified privileges and
        at times when there are dfault privileges but the selected privilegs happened to be zero */
      return false;
    } else {
      return true;
    }
  }
  public validateGroup(getDefaultGroups: any, selectedGroups: any) {
    if (getDefaultGroups.length > 0 && selectedGroups.length > 0) {
      const modifiedGroups = getDefaultGroups.filter(item => selectedGroups.indexOf(item) < 0);

      const modifiedGroupsRev = selectedGroups.filter(item => getDefaultGroups.indexOf(item) < 0);

      if (modifiedGroups.length > 0 || modifiedGroupsRev.length > 0) {
        return false;
      } else {
        return true;
      }
    }
  }

  /* Used for role cancellation */
  public cancelRole() {
    // this.disableToggleButtonOnLoad = true;
    this.accessResponse = [];
    //  this.disableToggleButtonOnLoad = true;
    this.disableDropdown = false;
    this.groupDisableDropdown = true;
    //  this.accessPrivilege = [];
    this.selectedPrivileges = [];
    this.updatedAccessPrivilegeReq = {};
    this.selectedRoleDetails = {};
    this.enableClick = false;
    this.selectedRole = null;
    this.selectedGroups = null;
    this.selectedGroups = [];
    this.selectedApp = this.apps[0].appId;
    this.displayMode = 'E';
    this.showPrivilegePreview = false;
  }

  public cancelRoleInfo() {
    this.groupDisableDropdown=true; // LFWM-1994 to reset the group dropdown 
    this.roleGroup = [];
    if (!this.validatePrivileges(this.roleManagementService.getDefaultPrivileges(), this.populateRoleRequestVO()['privileges'])
      || !this.validateGroup(this.roleManagementService.getDefaultGroups(), this.populateRoleRequestVO()['groups'])) {
      const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
      const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
      const dialogMsg = [];
      dialogMsg.push(dialogMsg1);
      dialogMsg.push(dialogMsg2);
      const subscription = this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {

            this.roleManagementService.removeDefaultGroups();
            this.roleManagementService.removeDefaultPrivileges();
            this.roleManagementService.resetPrivilegeData(false);
            this.cancelRole();

          } else {
            return;
          }
        });
    } else {

      this.roleManagementService.removeDefaultGroups();
      this.roleManagementService.removeDefaultPrivileges();
      this.roleManagementService.resetPrivilegeData(false);
      this.cancelRole();

    }
  }

  // Jira 720 starts

  public viewPrivilege(roles: any) {
   
  
    // Jira 1927 Role Management - To Disable delete button for Administrator Role
    if (roles === 'Administrator'||roles === 'SUPER_ADMINISTRATOR') {
      this.isAdminRoleSelected = true;
    } else {
      this.isAdminRoleSelected = false;
    }
    //////////////////////////////////////////////////////

    this.groupDisableDropdown = false;
    this.rolesForLookup.forEach(element => {

      if (element.name === roles) {
        this.notifyHyperlinkClick(element);
      }

    });

  }

  // Jira 720 ends

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changeSelectedApp(selected){

  }
}
