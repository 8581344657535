import { Injectable, Optional, SkipSelf } from '@angular/core';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { OverlayConfig } from '../models/overlay-config';

@Injectable()
export class ModalService {

  public modalEventSubject = new Subject<any>();
  public modalReference: any;
  public currentComponent: any;

  constructor(
    @Optional() @SkipSelf() parent: ModalService) {
       if (parent) {
         // If parent already loaded
         throw new Error(`Tried to initialize singleton service ModalService again.`);
       }
      }

  public showModal(activeComponent: any, overlayConfig: OverlayConfig): any {
    this.currentComponent = activeComponent;
    const showEvent: any = {
      type: 'show',
      activeComponent: activeComponent,
      overlayConfig: overlayConfig
    };
    this.publishEvent(showEvent);
    return this.modalReference;
  }

  public publishEvent($event: any) {
    this.modalEventSubject.next($event);
  }

  public receiveEvent(): Observable<any> {
    return this.modalEventSubject.asObservable();
  }

}
