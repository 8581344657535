import { Injectable, Optional, SkipSelf } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppStorageService, KEYS_APPSTORAGE } from './app-storage.service';
import { SessionTimerService } from './session-timer.service';

@Injectable()
export class TokenService {

  public refreshTokenUrl = 'api/user/token/refresh';
  public headers = new HttpHeaders({ 'api' : 'admin'});
  constructor(public http: HttpClient, public appStorageService: AppStorageService,
    public sessionTimerService: SessionTimerService,
    @Optional() @SkipSelf() parent: TokenService) {
     if (parent) {
       // If parent already loaded
       throw new Error(`Tried to initialize singleton service TokenService again.`);
     }

  }

  public setToken(username: string, token: string, tokenExpiry: number) {
    this.appStorageService.setItem(KEYS_APPSTORAGE.TOKEN_NAME, {
      username: username,
      token: token,
      tokenExpiry: tokenExpiry
    }
    );
    // start the session timer service
    this.sessionTimerService.start();
  }

  public refreshToken() {
    this.http.get(this.refreshTokenUrl,{ headers: this.headers }).subscribe((response: any) => {
      // this._logger.log(response.token);
      const tokenInfo = this.appStorageService.getItem(KEYS_APPSTORAGE.TOKEN_NAME);
      // tokenInfo.token = response.token;
      // this.appStorageService.setItem(KEYS_APPSTORAGE.TOKEN_NAME, tokenInfo);
      this.setToken(tokenInfo.username, response.token, response.tokenExpiry);
    });
    /* ***needs review***
     First call to this.startTokenTimers(); will be made from HomeComponent after login call
      Subsequent calls from refreshToken methods after setToken call
    // this.startTokenTimers();
    */
    return;
  }

  public getToken(): string {
    const currentUser = this.appStorageService.getItem(KEYS_APPSTORAGE.TOKEN_NAME);
    const token = currentUser && currentUser.token;
    return token ? token : '';
  }

  public invalidateToken(): void {
    // clear token remove user from local storage to log user out
    this.appStorageService.removeItem(KEYS_APPSTORAGE.TOKEN_NAME);
    // this.userService.userInfo = null;
  }

  public isAuthenticatedUser(): boolean {
    return !this.isTokenExpired();
  }

  public isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!token) {
      return true;
    }
    const date = this.getTokenExpirationDate();
    if (date === undefined) {
      return false;
    }
    /* The valueOf() method returns the primitive value of a Date object
       as a number data type, the number of milliseconds since midnight 01 January, 1970 UTC. */
    return !(date > new Date().valueOf());
  }

  public getTokenExpirationDate(): number {
    const currentUser = this.appStorageService.getItem(KEYS_APPSTORAGE.TOKEN_NAME);
    const epochTime = currentUser && currentUser.tokenExpiry;
    // let date = new Date();
    return parseInt(epochTime, 10);
  }

  public isTokenExpiryBufferCrossed(): boolean {
    const token = this.getToken();
    if (!token) {
      return false;
    }
    const date = this.getTokenExpirationDate();
    if (date === undefined) {
      return false;
    }
    const millisecondsToExpiry = date.valueOf() - new Date().valueOf();
    return false; // (millisecondsToExpiry < this.TOKEN_EXPIRY_BUFFER);
  }

  public getMillisecondsToExpiry(): number {
    const date = this.getTokenExpirationDate();
    if (date === undefined) {
      return -1;
    }
    const millisecondsToExpiry = date.valueOf() - new Date().valueOf();
    // this._logger.log('millisecondsToExpiry', millisecondsToExpiry);
    return millisecondsToExpiry;
  }

}
