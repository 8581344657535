export const router = {
  'shpmnt_planning': '/home/shipment/shipment-planning',
  'finalized_shpmnt': '/home/shipment/shipment-finalized',
  'shpmnt_excution': '/home/shipment/shipment-excution',
  'all_ordrs': '/home/order/all-order',
  'ordr_prcssng': '/home/order/order-processing',
  'order_event': '/home/order/order-event',
  'inbd_itf_msg': '/home/report/inbound-interface-message',
  'outbd_itf_msg': '/home/report/outbound-interface-message',
  'loc_vrnc_rprt': '/home/report/location-variance-report',
  'trnsptr':'/home/basic-data/basic-carrier',
  'location':'/home/basic-data/basic-location',
  'eqpmnt_grp':'/home/basic-data/basic-equipment-group',
  'principal':'/home/basic-data/basic-customer',
  'driver':'/home/basic-data/basic-driver',
  'fleet':'/home/basic-data/basic-truck',
  'mass_pod_upload': '/home/order/mass-upload',
  "ord_visibility": "/home/control-tower/order-visibility",
  "kpi_dashbrd": "/home/control-tower/KPI-Dashboard/search",
  'comodty': '/home/settings/commodity',
  'sfty_chk': '/home/settings/safety-check',
  'sts_rsn_cd': '/home/settings/reason-code',
  'push_noti': '/home/settings/push-notification',
  'systm_stngs': '/home/settings/system-setting',
  'shp_unt_spc': '/home/settings/transport-handling',
  'forecastplan': '/home/reserve/shipment-dtl',
  'cr_rsrv_dshbrd': '/home/reserve/shipment-dsbd-crr-f',
  'cr_bkng_dshbrd': '/home/reserve/shipment-dsbd-crr-p',
  'ops_rsrv_dshbrd': '/home/reserve/shipment-dsbd-lf-f',
  'ops_bkng_dshbrd': '/home/reserve/shipment-dsbd-lf-p',
  'mtch_dshbrd': '/home/reserve/shipment-match',
  'frcst_ord_imprt': '/home/reserve/shipment-order-import',
  'srvr_confg': '/home/notification/server-configuration',
  'vw_msg': '/home/notification/view-messages',
  'dshbrd': '/home/notification/dashboards',
  'child_tmplt': '/home/notification/child-template',
  'master_tmplt': '/home/notification/master-template',
  'mng_evnt': '/home/notification/manage-event',
  'mng_wrkflw': '/home/notification/manage-workflow',
  'ct_message': '/home/control-tower/message',
  'thrd_prty_rprt': '/home/report/third-party-report',
  'inbd_evn_hstry': '/home/reserve/event-history1',
  'handovr_rprt': '/home/report/handover-report',
  'blck_crd_br': '/home/black-card/black-binding',
  'blck_crd_dshbrd': '/home/black-card/black-dashboard',   
  'blck_crd_dvc_chrt': '/home/black-card/black-echart',   
  'blck_crd_mgmt': '/home/black-card/black-management',   
  'paramtr_config': '/home/black-card/param-cfg',
  'accessorial_code': '/home/reserve/accessorial-code',
  'fleet_monitoring': '/home/control-tower/fleet-monitoring',
  'driver_hos_report': '/home/control-tower/service-report',
  'driver_safety_report': '/home/control-tower/safety-report',
  'stop_dwelling_report': '/home/report/stop-dwelling',
  'enroute_dispatch': '/home/shipment/dynamic-planning',
  'stn_pull_report': '/home/report/stn-pull-report'
};
export const domainName = {
  dev_ocf_tms: 'https://cdt-tms.ocf.fulfillment.maersk.com',
  dev: 'https://ofscetms-dev.lflogistics.net',
  sit_ocf_tms: 'https://sit-tms.ocf.fulfillment.maersk.com',
  sit: 'https://ofscetms-sit.lflogistics.net',
  uat_ocf_tms: 'https://stage-tms.ocf.fulfillment.maersk.com',
  uat: 'https://ofscetms-uat.lflogistics.net',
  prod_ocf_tms: 'https://tms.ocf.fulfillment.maersk.com',
  prod: 'https://ofscetms.lflogistics.net',
};
