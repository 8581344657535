import { Component, Input, OnInit, AfterViewInit, ViewChild, ChangeDetectorRef, Renderer2 } from '@angular/core';
import moment from 'moment';
import { Observable } from 'rxjs';
import { UrlHandlingStrategy } from '@angular/router';
import { DashboardEvent, REMOVE_FAVOURITE } from '../models/dashboard-event';
import { DashboardService } from '../../dashboard.service';


@Component({
  selector: 'sce-dynamic-carousel-view',
  templateUrl: './dynamic-carousel-view.component.html',
  styleUrls: ['./dynamic-carousel-view.component.css']
})
export class SCECarouselHomeComponent implements OnInit, AfterViewInit {

  public carouselImageUrls: string[];
  public carouselViewportHeight: number = 0;
  public carouselViewportWidth: number = 0;
  public favouritesList: any;
  public favouriteReportsList: any[];
  public apiReportList: any[];
  public apiErrorlist: any[];
  public iframeUrl: any;

  constructor(public dashboardService: DashboardService) {
    this.dashboardService.favouriteDashboardList = [];
    this.carouselImageUrls = ['assets/images/home_carousel_1.jpg',
    'assets/images/home_carousel_2.jpg',
    'assets/images/home_carousel_3.jpg',
    'assets/images/home_carousel_4.jpg',
    'assets/images/home_carousel_5.jpg',
    'assets/images/home_carousel_6.jpg',
    'assets/images/home_carousel_7.jpg',
    'assets/images/home_carousel_8.jpg',
    'assets/images/home_carousel_9.jpg'];

  }

  public ngOnInit() {
      this.fetchDshbModules();
      // Reload DOM after removing Fav from Homepage
      this.dashboardService.receiveEvent()
      .subscribe((event: DashboardEvent) => {
        if (event.type === REMOVE_FAVOURITE) {
          this.fetchDshbModules();
        }
      });
  }

  public ngAfterViewInit() {
    const intervalID: any = setInterval(() => {
      if (this.dashboardService.showCarousel) {
        const elmnt = document.getElementsByClassName("carousel-viewport");
        if (elmnt.length === 0) {
          const elmnt = document.getElementsByClassName("carousel-viewport-home");
        }
        this.carouselViewportHeight = elmnt[0] ? (elmnt[0].clientHeight ? elmnt[0].clientHeight: 0) : 0;
        this.carouselViewportWidth = elmnt[0] ? (elmnt[0].clientWidth ? elmnt[0].clientWidth: 0) : 0; 
      }
    }, 100);
  }

  public fetchDshbModules() {
    let dashboardModules = [];
    this.dashboardService.fetchDshbModules().subscribe((resp: any) => {
      Object.keys(resp).forEach((module) => {
        const moduleId = resp[module];
        dashboardModules.push(moduleId);
      });
      this.getFavouritesFromModuleId(dashboardModules);
    });
  }

  public getFavouritesFromModuleId(dashboardModules) {
    if (dashboardModules.length > 0) {
      dashboardModules.forEach((moduleId) => {
        this.dashboardService.getFavouriteDashboard(moduleId).subscribe((response: any) => {
          this.favouriteReportsList = [];
          if (Object.keys(response.favList).length > 0) {
                Object.keys(response.favList).forEach((dashboard) => {
                  this.favouritesList = response.favList[dashboard];
                      if (this.favouritesList.length > 0) {
                        this.favouritesList.forEach((fav) => {
                            fav['show'] = false;
                            fav['iframeUrl'] = this.fetchIframeUrl(fav);
                            if (this.favouriteReportsList.length < 6) {
                              this.favouriteReportsList.push(fav);
                            }
                        })
                      }
                    if (!this.dashboardService.favouriteDashboardList) {
                      this.dashboardService.favouriteDashboardList = [];
                    }
                    var obj = {};
                    obj['reportsList'] = this.favouriteReportsList;
                    obj['dashBoardName'] = dashboard.replace("Dashboard", "");
                    obj['dashBoardName'] = obj['dashBoardName'].toUpperCase();
                    this.dashboardService.favouriteDashboardList.forEach((data, index) => {
                      if (data.dashBoardName === obj['dashBoardName']) {
                        this.dashboardService.favouriteDashboardList.splice(index, 1);
                      }
                    });
                    if (this.favouriteReportsList.length % 2 === 1) {
                      obj['responsiveChart'] = this.favouriteReportsList[this.favouriteReportsList.length - 1];
                      this.favouriteReportsList.pop();
                    }
                    this.dashboardService.favouriteDashboardList.push(obj);
                    if (this.dashboardService.favouriteDashboardList.length > 0) {
                      document.body.className = document.body.className.replace('carousel-viewport','carousel-viewport-home');
                      this.dashboardService.showCarousel = false;
                    } else {
                      this.dashboardService.showCarousel = true;
                    }
                });
              }
        });
      });
    }
  }

  public fetchIframeUrl(fav) {
    let params;
    const fromDate = moment().subtract(28, 'd').format('MM/DD/YYYY');
    const toDate = moment().format('MM/DD/YYYY');
    let storer = fav.storer ? fav.storer.split(',') : '';
    //[GC-256] newReqData fetched in paramList to include additional search criteria for favorite dashboards
    if (fav.moduleName === 'Forward Dashboard' || fav.moduleName === 'Forwarder Dashboard') {
      let reqData = {
        param_storer: ((storer[0] === 'No Restriction') ? '*' : storer[0]),
        param_periodfrom: fromDate, param_periodto: toDate
      };
      let criteriaParsed = JSON.parse(fav.criteria);
      let newReqData = this.getParams(criteriaParsed, fav.dashboardCategory, fav.accessModuleId);
      params = {
        dashboardId: fav.dashboardId,
        accessModuleId: fav.accessModuleId,
        // paramList: reqData,
        paramList: newReqData.paramList,
        //storerKey: ((storer[0] === 'No Restriction') ? '*' : storer[0]),
        storerKey: newReqData.storerKey,
        countryName: fav.countryName === 'GBL' ? "GLOBAL" : fav.countryName,
        application: "Dashboard",
      }
    } else if (fav.moduleName === 'Operation Dashboardd' || fav.moduleName === 'Operation Dashboard') {
      let reqData = { param_storer: ((storer[0] === 'No Restriction') ? '*' : storer[0]), 
      param_datefrom: fromDate, param_dateto: toDate };
      if(!fav.criteria){
        params = {
          dashboardId: fav.dashboardId,
          accessModuleId: fav.accessModuleId,        
          //paramList: reqData,
          //storerKey: ((storer[0] === 'No Restriction') ? '*' : storer[0]),
          storerKey: '',
          countryName: fav.countryName === 'GBL' ? "GLOBAL" : fav.countryName,
          application: "Dashboard",
        }
      }else{
        let criteriaParsed = JSON.parse(fav.criteria);
        let newReqData = this.getParams(criteriaParsed, fav.dashboardCategory, fav.accessModuleId);
        params = {
          dashboardId: fav.dashboardId,
          accessModuleId: fav.accessModuleId,        
          //paramList: reqData,
          paramList: newReqData.paramList,
          //storerKey: ((storer[0] === 'No Restriction') ? '*' : storer[0]),
          storerKey: newReqData.storerKey,
          countryName: fav.countryName === 'GBL' ? "GLOBAL" : fav.countryName,
          application: "Dashboard",
        }
        if (fav && fav.dashboardCategory && fav.dashboardCategory === 6) {
          params['storerKey'] = '';
          delete params['paramList'];
        }
      }
    } else if (fav.moduleName === 'Booking Performance Dashboard' || fav.moduleName === 'Booking Performance Dashboard'
   || fav.moduleName==='Billing Dashboard'|| fav.moduleName==='TMS Dashboard' ) {
      let reqData = { param_storer: ((storer[0] === 'No Restriction') ? '*' : storer[0]), 
      param_periodfrom: fromDate, param_periodto: toDate };
      let criteriaParsed = JSON.parse(fav.criteria);
      let newReqData = this.getParams(criteriaParsed, fav.dashboardCategory, fav.accessModuleId);
      params = {
        dashboardId: fav.dashboardId,
        accessModuleId: fav.accessModuleId, //2569        
        //paramList: reqData, 
        paramList: newReqData.paramList,
        //storerKey: ((storer[0] === 'No Restriction') ? '*' : storer[0]),
        storerKey: newReqData.storerKey,
        countryName: fav.countryName === 'GBL' ? "GLOBAL" : fav.countryName,
        application: "Dashboard",
      }
    }

    this.dashboardService.fetchSelectedIframeUrl(params).subscribe(resp => {
      fav.iframeUrl = decodeURIComponent(resp.returnUrl);
    });
  }

  //[GC-256] New method added to fetch additional parameters
  //for displaying favorite dashboards based on last search criteria
  public getParams(criteria, dashboardCategory, accessModuleId) {    
      const paramObj = {};
      let Storer = '';   
      let Country = '';   
      criteria.conditions.forEach(clauses => {
        Object.keys(clauses).forEach((clause) => {
          if (clause === 'clauses' && clause.length > 0) {
            clauses[clause].forEach(data => {
              if ((data.column === 'STORER' || data.column === 'Storer') && data.dashboardParam !== 'PARAM_STORERKEY') {
                Storer = ((data.value === 'No Restriction') ? '*' : data.value);
                paramObj[('param_storer')] = ((data.value === 'No Restriction') ? '*' : data.value);
              } else if ((data.column === 'STORER' || data.column === 'Storer') && data.dashboardParam === 'PARAM_STORERKEY') {
                Storer = ((data.value === 'No Restriction') ? '*' : data.value);
                paramObj[(data.dashboardParam).toLowerCase()] = ((data.value === 'No Restriction') ? '*' : data.value);
              } else if (data.dashboardParam === 'PARAM_DATE' || data.dashboardParam === 'PARAM_CB_DATE') { // CB Dshb
                paramObj[('param_periodfrom')] = data.formattedDateValue;
                paramObj[('param_periodto')] = data.formattedDateValue1;
              } else if (data.dashboardParam === 'PARAM_OP_DATE') { // Operation Dshbd
                paramObj[('param_datefrom')] = data.formattedDateValue;
                paramObj[('param_dateto')] = data.formattedDateValue1;
              } else if (data.dashboardParam === 'PARAM_FISCAL_MONTH') { // Fwd Dshbd
                paramObj[('param_fiscal_month_from')] = data.formattedDateValue;
                paramObj[('param_fiscal_month_to')] = data.formattedDateValue1;
              } else if (data.dashboardParam === 'PARAM_POD_DATE') { // Fwd Dshbd
                paramObj[('param_pod_from')] = data.formattedDateValue;
                paramObj[('param_pod_to')] = data.formattedDateValue1;
              }  else if (data.dashboardParam === 'PARAM_POD') { // Fwd Dshbd
                paramObj[('param_pod_from')] = data.formattedDateValue;
                paramObj[('param_pod_to')] = data.formattedDateValue1; 
              }else if (data.dashboardParam === 'PARAM_PERIODFROM') { // BPD Dshb
                paramObj[('param_periodfrom')] = data.formattedDateValue;
                paramObj[('param_periodto')] = data.formattedDateValue1;
              } else if (data.column === 'Client ID' && data.dashboardParam === 'PARAM_CLIENT_ID') {
                Storer = ((data.value === 'No Restriction') ? '*' : data.value);
                if(typeof Storer === 'object'){
                  Storer = data.value.join(',')
                }
                paramObj[('param_client_id')] = ((data.value === 'No Restriction') ? '*' : data.value);
              }else if (data.column === 'Country') {
                Country = data.value;
                 if(typeof Country === 'object'){
                  Country = data.value.join(',')
                }
                paramObj[(data.dashboardParam).toLowerCase()] = data.value;
              }else if (data.dashboardParam === 'PARAM_STARTDATE_FROM') { 
                paramObj[('param_startdate_from')] = data.formattedDateValue;
                paramObj[('param_startdate_to')] = data.formattedDateValue1;
              } else if (data.dashboardParam === 'PARAM_ADD') { // TMS Dshb
                paramObj[('param_addfrom')] = data.formattedDateValue;
                paramObj[('param_addto')] = data.formattedDateValue1;
              }else if (data.dashboardParam === 'PARAM_ATD_PORTDATE_FROM') { // BPD Dshb
                paramObj[('param_atd_portdate_from')] = data.formattedDateValue;
                paramObj[('param_atd_portdate_to')] = data.formattedDateValue1;
              }else if (data.dashboardParam === 'PARAM_EVT') { // FWD Dshb
                paramObj[('param_evt_from')] = data.formattedDateValue;
                paramObj[('param_evt_to')] = data.formattedDateValue1;
              }  
              else if (data.dashboardParam === 'PARAM_EPD') { // TMS Dshb
                if(typeof(data.value) == "object")
                  paramObj[('param_epdfrom')] = data.value[0];
                else
                  paramObj[('param_epdfrom')] = data.value
                paramObj[('param_epdto')] = data.value1;
              }else {
                if (data && data.dashboardParam && data.dashboardParam !== 'PARAM_WEEKS'&& data.dashboardParam !== 'PARAM_EPD') {
                  paramObj[(data.dashboardParam).toLowerCase()] = data.value;
                }
              }
            });
          }
        });
      });      
      const params = {        
        paramList: paramObj,
        storerKey: Storer,        
      };
      return params;
  }
}
