import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { ResourceService } from '../resource.service';
import moment from 'moment';

@Injectable()
export class DowntimeConfigurationService {
    public headers = new HttpHeaders({ 'Content-Type': 'application/json', 'api': 'admin' });

    constructor(public _http: HttpClient, public resourceService: ResourceService) { }

    // method used to handle error
    public handleError(error: Response) {
        return Observable.throw(error || this.resourceService.get('msg_um_ServrErrDuringLogin'));
    }

    // API call for adding downtime
    public addDowntime(request: any) {
        return this._http.post('api/app/downtime', request, { headers: this.headers }).pipe(
            map((response: any) => {
                return response;

            }),catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors.length !== 0) {
                        if (error.status === 400) {
                            return Observable.throw(error.error.errors['0'].defaultMessage);
                        } else if (error.error.errors[0].validationCode !== 200) {
                            return Observable.throw(error.error.errors['0'].validationMessage);
                        }
                    }
                }
            }));
    }
    // API call for deleting the downtime
    public deleteDowntime(request: any) {
        const options = {
            headers: this.headers,
            body: request,
        };
        return this._http.delete('api/app/downtime', options).pipe(
            map((response: any) => {
                return response;
            }),catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors.length !== 0) {
                        if (error.status === 400) {
                            return Observable.throw(error.error.errors['0'].defaultMessage);
                        } else if (error.error.errors[0].validationCode !== 200) {
                            return Observable.throw(error.error.errors['0'].validationMessage);
                        }
                    }
                }
            }));
    }
    // API call for fetching the downtime
    public fetchDowntime() {
        return this._http.get('api/app/downtime', { headers: this.headers }).pipe(
             map((response: any) => {
                return response;

            }),catchError((error: any) => {
                if (error.error.errors.length !== 0) {
                    if (error.error.errors.length !== 0) {
                        if (error.status === 400) {
                            return Observable.throw(error.error.errors['0'].defaultMessage);
                        } else if (error.error.errors[0].validationCode !== 200) {
                            return Observable.throw(error.error.errors['0'].validationMessage);
                        }
                    }
                }
            }));
    }

    //[LFWM-2916]- To convert the downStartTime and downEndTime according to users timezone.
    //Function to convert downStartTime and downEndTime to UTC format.
    public convertTimeToUtc(date: any) {
        let timeInUtc = new Date(date).toUTCString();
        return timeInUtc;
    }

    // [LFWM-2916]-Function to convert downStartTime and downEndTime to localtime.
    // we will be getting UTC string from backend.
    public convertTimeToLocalTime(date: any) {
        let localTime = moment(date).format("YYYY-MM-DD HH:mm:ss");
        return localTime;
    }
}