import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { catchError, map } from 'rxjs/operators';
import { NavigationService } from '../../navigation.service';
import { ResourceService } from '../../resource.service';
import { UserService } from '../../security/user.service';


@Component({
  selector: 'lfwms-user-profile-common',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  public header = new HttpHeaders({ 'api': 'admin' });

  public userName: any;

  public displayName: any;

  public email: any;

  public phone: any;

  public whatsapp: any;

  public telegram: any;

  public wechat: any;

  public line: any;

  public company: any;
  public image_user = 'assets/images/icon-person.png';
  public displayPhotoBool: boolean = false;

  constructor(public elementRef: ElementRef, public http: HttpClient,
    public resourceService: ResourceService, public navService: NavigationService,
    public userService: UserService) {
    // Initialise the menu from backend service.ElementRef
  }

  public ngOnInit() {
    this.getUserDetails().subscribe((response: any) => {
      this.userName = response.userName;
      this.displayName = response.displayName;
      this.email = response.email;
      this.phone = response.phone;
      this.whatsapp = response.whatsapp;
      this.telegram = response.telegram;
      this.wechat = response.wechat;
      this.line = response.line;
      this.company = response.company;

      this.navService.currentMenuIdSubject.next('my-profile');
      if (this.userService.userInfo.photo) {
        this.image_user = this.userService.userInfo.photo;
        this.displayPhotoBool = true;
      }
         // this.navService.resetBreadcrumb('home');
      this.navService.addBreadCrumbItem('My-profile');
    });
  }

  // tslint:disable-next-line:typedef
  getUserDetails() {
    const url: string = 'api/users/details/profile/' + this.userService.userInfo.userId;
    const response$: any = this.http.get(url , { headers: this.header }).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => {
        return Observable.throw(this.resourceService.get('server_err_pop_list')
          + this.resourceService.get(error.message));
      }));
    return response$;
  }
}
