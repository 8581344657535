import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserManagementService } from '../user-management/user-management.service';
import { ModalService } from '../components/modal/modal.service';
import { CustomPaginationService } from '../custom-pagination/custom-pagination.service';
import { ResourceService } from '../resource.service';
import { NavigationService } from '../navigation.service';
import { ConfirmDialogService } from '../components/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'lfwms-user-lookup',
  templateUrl: './user-lookup.component.html',
  styleUrls: ['./user-lookup.component.css']
})
export class UserLookupComponent implements OnInit {

  public start_index: number;
  public end_index: number;
  public errorMessage: string;
  public title: string;
  public list: any[] = [];
  public adduserDisabled: boolean = true;
  public rows = [];
  public temp = [];
  public sortOrder: number = -1;
  public dataPerPage = 10;
  public searchkeyword: string;
  public columnDef = [{
    prop: 'userName',
    name: this.getLabel('lbl_um_userName'),
    sortOrder: -1,
    type: 'string'
  }, {
    prop: 'email',
    name: this.getLabel('lbl_um_E-MailID'),
    sortOrder: -1,
    type: 'string'
  }, {
    prop: 'ldapDomain',
    name: this.getLabel('lbl_um_LDAPDomain'),
    sortOrder: -1,
    type: 'string'
  }];
  public lookupTitle: string = '';
  // public currentPage: number = 1;
  // public recordCount: number = 0;
  public listPerPage: any[] = [];
  public defaultSearchOption: any;
  public  paginatorService: CustomPaginationService;
  public pageList = [10, 20, 50, 100];
  public recordCount: number = 0;
  public selectedRowsPerPage: number = 10;
  public  state = {
    page: 1,
    first: 1,
    rows: [],
    pageCount: this.recordCount
  };
  public currentPage: number = 1;
  public pageSize: number = 10;

  constructor(public modalService: ModalService, public userManagementService: UserManagementService,
    public resourceService: ResourceService,  public confirmDialogService: ConfirmDialogService,
    public activatedRoute: ActivatedRoute,
    public navService: NavigationService) {
    this.paginatorService = new CustomPaginationService(this.confirmDialogService, resourceService);
    this.paginatorService.rows = this.selectedRowsPerPage;
    this.paginatorService.tableMode = 'V';
    this.searchkeyword = this.userManagementService.search_value;
    this.getUserNames();

    this.paginatorService.getPaginatorBroadcast()
    .subscribe((event: any) => {
      this.state = event;
      this.start_index = (this.state.page * this.paginatorService.rows) - this.paginatorService.rows;
      this.end_index = ((this.state.page * this.paginatorService.rows) + this.paginatorService.rows) - this.paginatorService.rows;
      this.listPerPage = [];
      this.listPerPage = this.rows.slice(this.start_index, this.end_index);
    });
  }
  // method used to get user names
  public getUserNames() {
    this.userManagementService.getUserNames(this.searchkeyword).subscribe((user: any) => {
      this.rows = user;
      this.temp = user;
      this.start_index = 0;
      this.end_index = 10;
      this.recordCount = user.length;
      this.listPerPage = this.rows.slice(this.start_index, this.end_index);
      this.paginatorService.totalRecords = this.recordCount;
      this.paginatorService.choosenPage = this.currentPage;
      this.paginatorService.paginatorState = this.state;
      this.paginatorService.rowsPerPageOptions = this.pageList;
      this.paginatorService.pageLinkSize = 5;
    }, (error: any) => {
      this.errorMessage = <any>error;
    });
  }

  // method used to translate the labels
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  public ngOnInit() {

      this.navService.setPageTitle(this.resourceService.get('lbl_user_lkp'));

    this.lookupTitle = this.resourceService.get('lbl_um_UserList');
  }
  // method used to navigate to user management screen with selected user details
  public onUserRowSelect(event): void {
    this.userManagementService.disablefield = true;
    this.userManagementService.selectedUserDetails_LookUp = event;
    this.userManagementService.retrievevalue(event);
    this.modalService.modalReference.hide();
  }
  // method used to update the filter
  public updateFilter(event) {
    const val = event.target.value.toLowerCase();
    this.rows = this.temp.filter((d: any) => {
      return d.userName.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.start_index = 0;
    this.end_index = 10;
    this.recordCount = this.rows.length;
    this.listPerPage = this.rows.slice(this.start_index, this.end_index);
    this.paginatorService.totalRecords = this.recordCount;
    this.paginatorService.choosenPage = 1;
    this.paginatorService.paginatorState = this.state;
    this.paginatorService.rowsPerPageOptions = this.pageList;
    this.paginatorService.pageLinkSize = 5;
    this.paginatorService.updatePaginatorState();
  }
  // method used to sort the column
  public toggleColumnSort(column: any) {
    let currentSortOrder: number = column.sortOrder;
    if ((currentSortOrder === -1) || (currentSortOrder === 0)) {
      this.rows.sort((a: any, b: any) => {
        if (column.type !== 'DATE') {
          if (a[column.prop] < b[column.prop]) {
            return -1;
          } else if (a[column.prop] > b[column.prop]) {
            return 1;
          } else {
            return 0;
          }
        } else {
          if (a[column.prop] === null) {
            return 1;
          } else if (b[column.prop] === null) {
            return -1;
          } else if (a[column.prop] === b[column.prop]) {
            return 0;
          } else if (a[column.prop] !== null && b[column.prop] !== null) {
            let aDate: Date;
            let bDate: Date;
            aDate = new Date(a[column.prop]);
            bDate = new Date(b[column.prop]);
            return (aDate > bDate) ? -1 : ((bDate > aDate) ? 1 : 0);
          }
        }
      });
      currentSortOrder = 1;
    } else if (currentSortOrder === 1) {
      this.rows.sort((a: any, b: any) => {
        if (column.type !== 'DATE') {
          if (a[column.prop] < b[column.prop]) {
            return 1;
          } else if (a[column.prop] > b[column.prop]) {
            return -1;
          } else {
            return 0;
          }
        } else {
          if (a[column.prop] === null) {
            return 1;
          } else if (b[column.prop] === null) {
            return -1;
          } else if (a[column.prop] === b[column.prop]) {
            return 0;
          } else if (a[column.prop] !== null && b[column.prop] !== null) {
            let aDate: Date;
            let bDate: Date;
            aDate = new Date(a[column.prop]);
            bDate = new Date(b[column.prop]);
            return (aDate > bDate) ? 1 : ((bDate > aDate) ? -1 : 0);
          }
        }
      });
      currentSortOrder = 0;
    }
    this.columnDef.forEach((col: any) => {
      if (col.prop !== column.property) {
        col.sortOrder = -1;
      }
    });
    this.listPerPage = this.rows.slice(this.start_index, this.end_index);
    column.sortOrder = currentSortOrder;
  }
}
