export class IntervalTimer {

  public callback: any;
  public interval: number;
  public timerID: any;

  constructor(callback: any, interval: number) {
    this.callback = callback;
    this.interval = interval;
  }

  public start() {
    this.timerID = setInterval(this.callback, this.interval);
    return this;
  }

  public reset() {
    return this.stop().start();
  }

  public stop() {
    if (this.timerID) {
      clearInterval(this.timerID);
      this.timerID = null;
    }
    return this;
  }

}
