import { Component, Input } from '@angular/core';
import { OnInit, Output, EventEmitter } from '@angular/core';
import { CustomPaginationService } from './custom-pagination.service';



@Component({
  selector: 'lfwms-pagination',
  templateUrl: './custom-pagination.component.html',
  styleUrls: ['./custom-pagination.component.css']
})
export class CustomPaginationComponent implements OnInit {

  @Output() onPageChange: EventEmitter<any> = new EventEmitter();
  @Input() paginatorService: CustomPaginationService;

  ngOnInit() {
    this.paginatorService.updatePaginatorState();
  }


}
