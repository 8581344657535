import { PageDefinition } from './../../search/models/page-definition.model';
import { Component, Input, OnInit, OnDestroy, Output, EventEmitter} from '@angular/core';
import { SearchService } from '../../search/search.service';
import { Subscription } from 'rxjs';
import { SearchEvent } from '../../search/models/search-event';
import { NEW_SEARCH_EVENT, PAGINATE_EVENT } from '../../search/search-constants';
import { PaginationEvent } from '../../events/pagination-event';

@Component({
  selector: 'lfwms-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit, OnDestroy {

  public recordCount: number = 0;
  public pageDefinition: any={};
  public eventSubscription: Subscription;
  public disablePrev: boolean = false;
  public disableNext: boolean = false;
  public currentPage: number = 1;
  public pageSize: number = 10;
  public pageCount: number = 1;
  public pageSizeOptions: Array<number> = [10, 20, 30, 50, 100];
  public showLeftEllipsis: boolean = false;
  public showRightEllipsis: boolean = false;
  public displayedPages: Array<number>;
  public manualPageNumber: string = '';
  public isInvalidPageNumber: boolean = false;

  @Input() public searchService: SearchService;
  @Input() public newPageNum: any;
  @Input() public newPageSize: any;
  @Input() public newRecordCount: any=0;
  @Output() public pageChanged:
    EventEmitter<PaginationEvent> = new EventEmitter();

  public ngOnInit() {
    this.pageDefinition = this.searchService.getPagedData().pageDefinition;

    this.resetPaginator();
    this.eventSubscription = this.searchService.receiveEvent()
      .subscribe((event: SearchEvent) => {
        if (event.type === PAGINATE_EVENT) {
          this.pageSize = event.pageSize;
          this.currentPage = event.pageNumber;
          this.refreshPaginator();
        } else if (event.type === NEW_SEARCH_EVENT) {
          this.resetPaginator();
        }
      });
  }

  public ngOnChanges(){

    this.pageDefinition.recordCount = this.newRecordCount
    this.pageDefinition.pageSize = this.newPageSize
    this.pageDefinition.pageNumber = this.newPageNum
    this.resetPaginator();
  }

  public resetPaginator() {
    this.recordCount = this.pageDefinition.recordCount;
    this.pageSize = this.pageDefinition.pageSize;
    this.currentPage = this.pageDefinition.pageNumber;
    this.manualPageNumber = '';
    this.isInvalidPageNumber = false;
    this.refreshPaginator();
  }

  public refreshPaginator() {
    this.displayedPages = [];
    this.pageCount = Math.ceil(this.recordCount / this.pageSize);
    if (this.recordCount > 0) {
      this.setDisplayedPages(1);
    } else {
      this.displayedPages = [1];
      this.pageCount = 1;
      this.currentPage = 1;
    }
    this.disablePrev = (this.currentPage === 1);
    this.disableNext = (this.currentPage === this.pageCount);
  }

  public setDisplayedPages(pageNumber: number) {
    this.showLeftEllipsis = false;
    this.showRightEllipsis = false;
    this.displayedPages = [];
    if ((pageNumber < 1) || (pageNumber > this.pageCount)) {
      return;
    }
    if (this.pageCount <= 5) {
      for (let i: number = 1; i <= this.pageCount; i++) {
        this.displayedPages.push(i);
      }
    } else {
      this.showLeftEllipsis = (1 < (this.currentPage - 2));
      this.showRightEllipsis = ((this.currentPage + 2) < this.pageCount);
      let initialAddend: number = -2;
      if (this.currentPage === this.pageCount) {
        initialAddend = -4;
      } else if (this.currentPage === (this.pageCount - 1)) {
        initialAddend = -3;
      } else if (this.currentPage === 2) {
        initialAddend = -1;
      } else if (this.currentPage === 1) {
        initialAddend = 0;
      }else if(this.currentPage === 0){
        initialAddend = 1;
      }
      for (let i: number = initialAddend; i < (initialAddend + 5); i++) {
        this.displayedPages.push(this.currentPage + i);
      }
    }
  }

  public handlePageChange(pageSize: number, pageNumber: number) {
    const paginationEvent: PaginationEvent = new PaginationEvent(PaginationEvent.PAGE_CHANGE);
    if (pageNumber > 0 && pageNumber <= this.pageCount) {
      paginationEvent.pageNumber = pageNumber;
    } else {
      paginationEvent.pageNumber = this.currentPage;
    }
    paginationEvent.pageSize = pageSize;
    this.pageChanged.emit(paginationEvent);
    this.manualPageNumber = '';
    this.isInvalidPageNumber = false;
  }

  public ngOnDestroy() {
    this.eventSubscription.unsubscribe();
  }

  public handlePageChangeOnUserInput($event: any) {
    const pageNumber: number = +$event.target.value;
    if (pageNumber > 0 && pageNumber <= this.pageCount) {
      this.currentPage = +$event.target.value;
      this.handlePageChange(this.pageSize, this.currentPage);
    } else {
      this.isInvalidPageNumber = true;
    }
  }

  public validatePageNumber($event: any) {
    const isPageNumberValid: boolean = new RegExp('^[0-9]+$').test($event.key);
    if ((!isPageNumberValid && $event.key !== 'Enter'
        && $event.key !== 'Backspace') || this.manualPageNumber === '0') {
      this.manualPageNumber
        = this.manualPageNumber.replace(this.manualPageNumber
          .substring(this.manualPageNumber.length - 1), '');
    }
  }

  public onFocusOut() {
    this.isInvalidPageNumber = false;
    this.manualPageNumber = '';
  }

}
