import { Component, OnInit } from '@angular/core';
import { DowntimeConfigurationService } from './downtime-configuration.service';
import { ScheduleDowntimePopupComponent } from './popup/schedule-downtime-popup.component';
import { UserManagementService } from '../user-management/user-management.service';
import { DatePickerConfigService } from '../components/datepicker/datepicker-config.service';
import { ModalService } from '../components/modal/modal.service';
import { AlertService } from '../components/alerts/alert.service';
import { ResourceService } from '../resource.service';

@Component({
  selector: 'lfwms-downtime-configuration',
  templateUrl: './downtime-configuration.component.html',
  styleUrls: ['./downtime-configuration.component.css'],
  
})
export class DowntimeConfigurationComponent implements OnInit {
  public infoList: Array<any> = [];
  public applicationList: Array<any> = [];
  public isEnabled: boolean;
  public downtimeList: Array<any> = [];
  public hasInfoList: boolean = false;

  constructor(public resourceService: ResourceService,
    public modalService: ModalService,
    public alertService: AlertService,
    public userManagementService: UserManagementService,
    public datePickerConfigService: DatePickerConfigService,
    public downtimeConfigurationService: DowntimeConfigurationService) {
  }

  ngOnInit() {

    // to fetch the data for displaying.
    this.fetchDowntime();

    //  to get the list of all the apps.
    let data = {
      code: 'All',
      isEnabled: false
    }
    this.applicationList.push(data);
    const subscription = this.userManagementService.getAllApps()
      .subscribe((resp: any) => {
        resp.forEach((element: any) => {
          element['isEnabled'] = false;
          this.applicationList.push(element)
        });
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }

  // to get the display value of corresponding key from the resource bundle
  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  // schedule downtime popup 
  public scheduleDowntime() {
    const modalRef: any = this.modalService
      .showModal(ScheduleDowntimePopupComponent,
        {
          overlayHeader: null,
          showHeader: false,
          overlayHeight: 650,
          overlayWidth: 510
        });
    modalRef.content.initializeComponents(this.applicationList);
    // if the downtime is added succesfully, triggering the fetchDowntime to fetch the details.
    modalRef.content.receiveEvent()
      .subscribe((event: any) => {
        if (event.type === 'addedSuccessfully') {
          this.fetchDowntime();
        }
        modalRef.hide();
      });
    modalRef.content.showLookup = true;
  }

  // fetching the downtime details to display.
  public fetchDowntime() {
    this.downtimeConfigurationService.fetchDowntime()
      .subscribe((resp: any) => {
        if (resp['statusCode'] && resp['statusMessage']) {
          if (resp['statusCode'] === 200) {
            this.infoList = resp.columnList;
            this.hasInfoList = true;
          }
        } else {
          if (resp.error) {
            this.alertService.clearAll();
            this.alertService.error(this.resourceService.translateServerResponse(resp.error));
          }
        }
      }, (error) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }

  // event emitted from the downtime dashboard component during edit functionality  is captured and edited data is fetched.
  public infoListChanged(isEdited: boolean) {
    this.fetchDowntime();
  }
}
