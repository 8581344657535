import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';


import { PrinterSetupComponent } from '../printer-setup/printer-setup.component';
import { NavigationService } from '../../navigation.service';
import { ModalService } from '../../components/modal/modal.service';
import { LeftPanelService } from '../../components/left-panel/left-panel.service';
import { UserService } from '../../security/user.service';
import { AlertService } from '../../components/alerts/alert.service';
import { ResourceService } from '../../resource.service';
import { Router } from '@angular/router';
// -----------------------

@Component({
  selector: 'lfwms-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  animations:  [
    trigger('leftPanelOpenClose', [
      state('open', style({
        left: '350px',
      })),
      state('closed', style({
        left: '0px'
      })),
      transition('open => closed', [
        animate('15s')
      ]),
      transition('closed => open', [
        animate('15s')
      ]),
    ])
  ]
})
export class MenuComponent {

  // Dynamic menu config
  public config: any = {};
  public openedRootMenuId: any = '';
  public isPanelOpened: any = false;
  constructor(public navService: NavigationService,
      public modalService: ModalService,
      public leftPanelService: LeftPanelService,
      public userService: UserService,
      public alertService: AlertService,
      public router:Router ,
      public resourceService: ResourceService) {
    this.config.breadCrumbs = [];
    // Initialise the menu from backend service.
    this.navService.getUserMenu()
      .subscribe((data: any) => {
        this.config.menuItems = data;
      });
    this.navService.getNavigatedMenuIdBroadcast()
      .subscribe((currentMenuId: any) => {
        this.openedRootMenuId = this.navService.getCurrentRootMenuId();
      });

    if (this.userService.userInfo?.defaultSidepanelStatus === null) {
      this.isPanelOpened = false;
    }
    if (this.userService.userInfo?.defaultSidepanelStatus) {
      this.isPanelOpened = true;
      // DOM element is loaded but its not getting rendered properly and the side panel appears to blank .So given timeout.
      setTimeout(() => {
        this.leftPanelService.openLeftPanel();
      }, 100);
    } else {
      this.isPanelOpened = false;
      this.leftPanelService.closeLeftPanel();
    }
  }

  /* This method is onclick listner for controlling menu navigation.
  It routes to the menuid's url saved in route-mapping constants.
  This also updates the rootmenu of currently clicked submenu for keeping it highlighted
  and sets the breadcrumb with the menuitem's corresponding breadcrumb array. */
  public navigateTo(menuId: any, rootid: any, id: any) {
    console.log('this.navService', this.navService, 'menuId===>', menuId , 'rootid', rootid);
    if (menuId === 'set_printer') {
     // this.openMenu();
     // Special menu items that doesnt need navigation
        this.navService.transmitMenuIdWithoutNavigation(menuId);
        this.clearMenuStates();
    }else if (['dispatcher','TMS','tms'].includes(rootid)) {      
      this.navService.navigateByUrl('/home/admin/tms', false, menuId);
      localStorage.setItem('menuId', menuId)    
      this.clearMenuStates();
    } else {
      const isNavSuccess = this.navService.navigateByMenuId(menuId);
      if (isNavSuccess) {
        this.openedRootMenuId = rootid;
        this.clearMenuStates();
      }
    }
  }
  // method used to hide menu items while clicking outside & also to clear state when navigate success
  public clearMenuStates() {
    if (this.config.menuItems) {
      this.config.menuItems.forEach(menu => {
        menu.clicked = false;
        menu.hovered = false;
      });
    }
  }

  // method used to expand only the clicked menu and hide other menus while hovering using hovered flag
  public onClickLevel1MenuItem(clickedMenuItem: any) {
    this.config.menuItems.forEach(menu => {
       // reset clicked state for first level menu items..
          menu.clicked = false;
    });
       // set clicked true on curently clicked item
       setTimeout(() => {
         /*To handle the weird use-case --
          settimeout is required because the execution of this blocks onClickLevel1MenuItem()
           on clicking the tooltip is not saving this state after exiting this function.
           ie.  clickedMenuItem.clicked = false after exiting the function and
           the menu-overlay is not being opened on clicking tooltip.
         */
         clickedMenuItem.clicked = true;
        }, 0);
      if (clickedMenuItem.menuID === 'home') {
        /* when hover state button is removed click needs to be registered on actual button/image.
        limiting to only home since others no navigation needed */
        this.navigateTo(clickedMenuItem.menuID, clickedMenuItem.menuID, clickedMenuItem.id);
      }
  }

  public getLabel(key: string): string {
    return this.resourceService.get(key);
  }

  public openMenu() {
    this.modalService
      .showModal(PrinterSetupComponent,
        { overlayHeader: this.getLabel('lbl_default_rdt')
          + ' / ' + this.getLabel('lbl_Bartender_printer'),
          showHeader: true,
          overlayHeight: 520,
          overlayWidth: 1024
        });
    this.clearMenuStates();
  }

  public toggleNav() {
     this.leftPanelService.toggleLeftPanel();
     this.isPanelOpened = !this.isPanelOpened;
  }

}
