import {
  Component,
  ViewChild,
  Input,
  OnInit,
  Output,
  EventEmitter,
  forwardRef,
  AfterViewInit
} from '@angular/core';
import moment from 'moment';

import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatePickerConfigService } from './datepicker-config.service';

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => DatepickerComponent),
  multi: true
};

@Component({
  selector: 'lfwms-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR]
})
export class DatepickerComponent implements OnInit, ControlValueAccessor, AfterViewInit {
  public pCalBindingDateFormatMoment: string ; // for reverse binding conversion from pCalendar to ng-model
  public pCalPrimeNgDateFormat: string;  // for forward binding conversion from ng-model to pCalendar
  public pCalValue: any;
  public pCalPlaceholder: string = 'MM/DD/YYYY';
  public pCalDisabled: boolean = false;
  public pCalTimePickerEnabled: boolean = false;
  public pCalYearPickerRange: string = '2000:2021';
  public pCalShowOnFocus: boolean = true;
  public pCalPreviousValue: Date;
  public pCalHourFormat: string = '12'; // 12 or 24 as string! // 12 or 24
  public pCalShowSeconds: boolean = false;

  @Input()
  public inputFormat: string;
   /* db dateFormat
   /*Input	Example	Description
    YYYY	2014	4 or 2 digit year
    YY	14	2 digit year
    Y	-25	Year with any number of digits and sign
    Q	1..4	Quarter of year. Sets month to first month in quarter.
    M MM	1..12	Month number
    MMM MMMM	Jan..December	Month name in locale set by moment.locale()
    D DD	1..31	Day of month
    Do	1st..31st	Day of month with ordinal
    DDD DDDD	1..365	Day of year
    X	1410715640.579	Unix timestamp
    x	1410715640579	Unix ms timestamp

    H HH	0..23	Hours (24 hour time)
    h hh	1..12	Hours (12 hour time used with a A.)
    k kk	1..24	Hours (24 hour time from 1 to 24)
    a A	am pm	Post or ante meridiem (Note the one character a p are also considered valid)
    m mm	0..59	Minutes
    s ss	0..59	Seconds
    S SS SSS	0..999	Fractional seconds
    Z ZZ	+12:00	Offset from UTC as +-HH:mm, +-HHmm, or Z

      moment("2018-12-30 00:00:00.001","YYYY-MM-DD HH:mm:ss.SSSSS",true)
      SSSSS >5or more S all same.
      only 3digit milliseconds will be converted to value.
  */
  @Input()
  public displayPlaceholder: string;
  @Input()
  public dateDisplayFormatMoment: string;
  /*Input	Example	Description
  ****Should never include time even if timepicker true****/
  @Input()
  public dateDisplayFormatPrimeNg: string;
  /*  Following options can be a part of the displayFormat.
  ****Should not include time.Only date part always****
  d - day of month (no leading zero)
  dd - day of month (two digit)
  o - day of the year (no leading zeros)
  oo - day of the year (three digit)
  D - day name short
  DD - day name long
  m - month of year (no leading zero)
  mm - month of year (two digit)
  M - month name short
  MM - month name long
  y - year (two digit)
  yy - year (four digit)
  @ - Unix timestamp (ms since 01/01/1970)
  ! - Windows ticks (100ns since 01/01/0001)
  '...' - literal text
  '' - single quote
  anything else - literal text
  */
  @Input()
  public showTimePicker: boolean = false;
  @Input()
  public strictParserMode: boolean = false;
  @Input()
  public isDropup: boolean = false;
  @Input()
  public xOffset: number = 0;
  @Input()
  public yOffset: number = 0;
  @Input()
  public allowBlankDate: boolean = false;
  @Input()
  public minDate: Date;
  @Input()
  public maxDate: Date;
  @Input()
  public yearRange: string;
  @Input()
  public defaultDate: Date;
  @Output()
  public focus: EventEmitter<any> = new EventEmitter<any>();
  // @Output()
  // public tab: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('pCalendarRef' , { static: true }) public _pCalendarRef: any;

  public onTouchedCallback: () => void = () => {};
  public onChangeCallback: (_: any) => void = () => {};


  constructor(public datepickerConfig: DatePickerConfigService) {}

  ngOnInit(): void {

    // Set defaults from configService
    this.pCalBindingDateFormatMoment = this.datepickerConfig.defaultDateDisplayFormat;
    this.pCalPrimeNgDateFormat = this.datepickerConfig.
                                  getPrimeNgDateFormat(this.pCalBindingDateFormatMoment);

    if (!this.inputFormat) {
      // if unitilaized via @input use defaults
        const dateTimeFormat = this.datepickerConfig.defaultDateInputFormat
                              + ' ' + this.datepickerConfig.defaultTimeInputFormat;
       this.inputFormat = this.showTimePicker ? dateTimeFormat : this.datepickerConfig.defaultDateInputFormat;
    }

    // if display format is passed via input
    if (!this.dateDisplayFormatPrimeNg && this.dateDisplayFormatMoment) {
      // if no values passed as @input-dateDisplayFormatPrimeNg use from mapping based on dateDisplayFormatMoment
      this.pCalPrimeNgDateFormat = this.datepickerConfig.getPrimeNgDateFormat(this.dateDisplayFormatMoment);
      if (!this.pCalPrimeNgDateFormat) {
        throw new Error('Non mappable date format - dateDisplayFormatMoment.. Ensure supported date format is passed');
      }
      // set passed date format for reverse binding
      this.pCalBindingDateFormatMoment = this.dateDisplayFormatMoment;

    }else if (this.dateDisplayFormatPrimeNg && this.dateDisplayFormatMoment) {
      // if both values passed using @input variables
      this.pCalPrimeNgDateFormat = this.dateDisplayFormatPrimeNg;
      this.pCalBindingDateFormatMoment = this.dateDisplayFormatMoment;
    } else if (this.dateDisplayFormatPrimeNg && !this.dateDisplayFormatMoment) {
      // if only one value throw error
        throw new Error('Use both displayFormatpCal & displayFormatMoment or displayFormatMoment only.');
    }

    // set primengFlags for timePicker mode if @input-showTimePicker is passed true
    if (this.showTimePicker) {
      this.pCalTimePickerEnabled = true;
      // capital H represent 24hour mode in moment format
      this.pCalHourFormat = this.datepickerConfig.defaultTimeDisplayFormat.startsWith('H') ? '24' : '12';
      // small s/ss represent seconds in moment format
      this.pCalShowSeconds = this.datepickerConfig.defaultTimeDisplayFormat.includes('s');
      const secondsFormatPart =  this.pCalShowSeconds ? ':ss' : '';
      const timeFormat = this.datepickerConfig.defaultTimeDisplayFormat.startsWith('H') ? ' HH:mm' + secondsFormatPart : ' hh:mm' + secondsFormatPart + ' A';
      this.pCalBindingDateFormatMoment = this.pCalBindingDateFormatMoment + timeFormat;
      // this.pCalBindingDateFormatMoment should always have hh:mm in timepicker mode as pCal doesnt support modifying time format
    }

    // if placeholder passed as input set it else copy default format as placeholder
    if (this.displayPlaceholder) {
      this.pCalPlaceholder = this.displayPlaceholder;
    }else {
      this.pCalPlaceholder = this.pCalBindingDateFormatMoment;
    }
    //[LFWM-3465] - Set datepicker range based on the current date.
    this.setYearRange();
  }

  public ngAfterViewInit() {
  /*  Removing the tab blocking event ( was added as a apart of /LFWM-1337) as tab is not supposed to be blocked in all datepickers.
  Tab should only be blocked in ag-grid header filters. Date filters in header are actually text fields.
  So this fix is removed which is actually blocking the tabout (LFWM-1756 #1) from all the datepickers in accordion and so on.

   this._pCalendarRef.inputfieldViewChild.nativeElement
      .addEventListener('keydown', ($event: any) => {
        if ($event.key === 'Tab') {
          // this.tab.emit();
          $event.preventDefault();
          return false;
        }
    }); */
  }

  writeValue(inputDate: any): void {
    if (inputDate) {
      const d = moment(inputDate, this.inputFormat, this.strictParserMode);
      this.pCalValue = d.format(
        this.pCalBindingDateFormatMoment
      );
      // fix for LFWM-2581 LFWM-2191 LFWM-2502
      // The datepicker lib has an issue in displaying  time from 12:00:00 AM to 12:59:59 AM 
      // This is a temperory fix add to display the time 12:00:00 AM to 12:59:59 AM as 00:00:00 AM to  00:59:59 AM 
      // Once user select any date then the value will change to 12 pm and if an above
      // mention range should be manually enter (key in )as 00:00:00 AM instead of selecting from date picker
      const hour = moment(d).format("hh");
      const hourFormat =  moment(d).format("A");
      if(hour === '12' && hourFormat=== 'AM'){
        let dateDisplayList=  this.pCalValue.split(" ");
        if(dateDisplayList.length > 2){
          let date = dateDisplayList[0];
          let timeDisplayList = dateDisplayList[1].split(":");
          if(timeDisplayList.length > 1){
            let hour = timeDisplayList[0].replace('12', '00'); 
            this.pCalValue = date+" "+hour+":"+timeDisplayList[1]+":"+timeDisplayList[2]+" "+hourFormat; 
          }
        }
      } 
    }else {
      // LB-12 This is added for Billing Application Contract Management. To clear out date field
      this.pCalValue = null
    }
}
  registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.pCalDisabled = isDisabled;
  }
  // get accessor
  get value(): any {
    // console.log('get accessor');
    return this.pCalValue;
  }
  // set accessor including call the onchange callback
  set value(v: any) {
     if (!v) {
          if (this.allowBlankDate && !this._pCalendarRef.inputfieldViewChild.nativeElement.value) {
            // if allowblank true and if input field fully cleared, send valuechange with null
            console.warn('null date binding');
            this.onChangeCallback(v);
          }else if (this.showTimePicker && this._pCalendarRef.inputfieldViewChild.nativeElement.value) {
            // Added for LFWM-1850 - PO Order Date Edit.
            // Note: Appended time format may need to be updated if 24hour mode or seconds mode is used.
              const appendTimeValue = this.datepickerConfig.defaultTimeDisplayFormat.startsWith('H') ? '00:00:00' : '12:00:00 AM';
              const formattedOutputDate: any = moment(
              this._pCalendarRef.inputfieldViewChild.nativeElement.value + ' ' + appendTimeValue,
              this.pCalBindingDateFormatMoment,
              true
            ).format(this.inputFormat);
            if (formattedOutputDate !== 'Invalid date') {
              this._pCalendarRef.inputfieldViewChild.nativeElement.value =  this._pCalendarRef.inputfieldViewChild.nativeElement.value +  ' 12:00:00 AM';
              this.pCalValue = this._pCalendarRef.inputfieldViewChild.nativeElement.value;
              // formattedOutputDate = formattedOutputDate + ' 12:00:00 AM';
              this.onChangeCallback(formattedOutputDate);
            }
         //   console.log(formattedOutputDate);
          }else if (this.pCalValue) {
          console.warn('last selected date will be saved');
          // do not send change event in this case
          }
    }else if (v !== this.pCalPreviousValue) {
      this.pCalValue = v;
      //[LFWM-3465] - Always validate the year before callbacks.
      this.setValidYear();
      this.pCalPreviousValue = new Date(this.pCalValue);
      const formattedOutputDate: any = moment(
        this.pCalValue,
        this.pCalBindingDateFormatMoment,
        true
      ).format(this.inputFormat);
      this.onChangeCallback(formattedOutputDate);
    }
  }

  public setFocus() {
  //  console.log('setfocus')
    this._pCalendarRef.inputfieldViewChild.nativeElement.focus();
  }
  
  // method called when user selects the date from calendar
  public onSelect() {
    // console.log('onselect')
    this.setFocus();
  }

  public onFocus() {
    this.focus.emit();
  //  console.log(this.yOffset)
    if (!this.yOffset && !this.xOffset) {
      // if no realignment needed show and exit without running realignment logic
      this._pCalendarRef.inputfieldViewChild.nativeElement.style.opacity = 1;
      this._pCalendarRef.inputfieldViewChild.nativeElement.style.visibility =
        'visible';
        return;
    }

    const intervalID = setInterval(() => {
      if (this._pCalendarRef.inputfieldViewChild) {
          if (this.yOffset !== 0) {
            this._pCalendarRef.inputfieldViewChild.nativeElement.style.top = this.yOffset + 'px';
            this._pCalendarRef.inputfieldViewChild.nativeElement.style.bottom = 'unset';
          }
          if (this.xOffset !== 0) {
            this._pCalendarRef.inputfieldViewChild.nativeElement.style.right = this.xOffset + 'px';
            this._pCalendarRef.inputfieldViewChild.nativeElement.style.left = 'unset';
          }

        // if (!this.isDropup) {
        //   if (this.isDropup === false) {
        //     this._pCalendarRef.inputfieldViewChild.nativeElement.style.top =
        //       '19px';
        //   } else {
        //     this._pCalendarRef.inputfieldViewChild.nativeElement.style.left =
        //       'unset';
        //     this._pCalendarRef.inputfieldViewChild.nativeElement.style.right =
        //       '10%';
        //     // this.primeFocusInput.inputfieldViewChild.nativeElement.style.top = this.offset + 'px';
        //   }
        // } else {
        //   this._pCalendarRef.inputfieldViewChild.nativeElement.style.bottom =
        //     '19px';
        // }
        this._pCalendarRef.inputfieldViewChild.nativeElement.style.opacity = 1;
        this._pCalendarRef.inputfieldViewChild.nativeElement.style.visibility =
          'visible';
        clearInterval(intervalID);
      }
    }, 1);
  }

  /* [LFWM-2666] - method to set the valid year if user selects an year lesser than the years available 
   the date picker [Ex: if current year - 2021 and year rage is 2011:2031 then the minimum valid year is 2011 ,
   year range: + and - 10 years from current year ], but user can key in any year */
   public setValidYear() {
    //[LFWM-3465] - Use picker range to find the minimum valid year.
    const validYear = +this.pCalYearPickerRange.split(":")[0];
    if (this.pCalValue.getFullYear() < validYear) {
      this.pCalValue.setFullYear(validYear);
    }
  }

  private setYearRange(){
    const d = moment();
    if(this.yearRange) {
      this.pCalYearPickerRange = this.yearRange;
    } else {
    const yearPickerStart = d.year() - 10;
    const yearPickerEnd = d.year() + 78;
    // set yearpicker to -10years & +78years from current year
    this.pCalYearPickerRange = yearPickerStart + ':' + yearPickerEnd;
    }
  }
  onInput() {

    const inputValue = this._pCalendarRef.inputfieldViewChild.nativeElement.value;
    console.log(inputValue);
    // Split the input value by "/" to get individual date parts
    const dateParts = inputValue.split('/');

    // Check if the input value matches the format MM/DD/YYYY
    if (dateParts.length === 3 && dateParts[0].length === 2 && dateParts[1].length === 2 ) {
      // Check if the year part exceeds 4 characters
      if (dateParts[2].length > 4) {
        // Trim the year part to keep only the first four characters
        const trimmedYear = dateParts[2].substring(0, 4);
        // Reconstruct the input value with the trimmed year
        const trimmedValue = `${dateParts[0]}/${dateParts[1]}/${trimmedYear}`;
        // Update the input field value with the trimmed value
        this._pCalendarRef.inputfieldViewChild.nativeElement.value = trimmedValue;
         // Call onChangeCallback to update ngModel binding
        this.onChangeCallback(trimmedValue);
      }
    }
  }
}
