import { Component, OnInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { LeftPanelService } from './left-panel.service';


@Component({
  selector: 'sce-left-panel',
  templateUrl: './left-panel.component.html',
  styleUrls: ['./left-panel.component.css'],
  animations: [
    trigger('openClose', [
      state('open', style({
        width: '350px'
      })),
      state('closed', style({
        width: '0px'
      })),
      transition('open => closed', [
        animate('0.5s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ])
  ]
})
export class SCELeftPanelComponent implements OnInit {


  /* Below state variable should only be changed in one place.
    i.e in subscription of LeftPanelService to have consistent
    behaviour in other subscriptions;
    THIS _opened SHOULD NOT EVEN BE INITIALISED HERE.
    Left Panel SHOULD BE OPENED OR CLOSED ONLY USING LeftPanelService. */
  public isSidePanelOpened: boolean;

  constructor(public leftPanelService: LeftPanelService) { }

  public ngOnInit() {
    this.leftPanelService.getLeftPanelOpenEvent()
      .subscribe((isSidePanelOpened: boolean) => {
        this.isSidePanelOpened = isSidePanelOpened;
      });
  }

}
