
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { TokenService, sceconfig } from 'sce-core';
import { environment } from '../environments/environment';
import { LoggerService } from './core/logger.service';
import { catchError } from 'rxjs/operators';
import { UserService } from 'sce-core';
import { tmsConfig } from './core/tms-config';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  public injector: Injector;
  public jReportUrl: any;

  constructor(injector: Injector) {
    this.injector = injector; // Used to avoid cyclic error for AuthService<-->HttpClient
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // To avoid cyclic dependency error, do not Initiate AuthService in constructor.Instead inject it here
    const tokenService: TokenService = this.injector.get(TokenService);
    // if (tokenService.isTokenExpiryBufferCrossed()) {
    //   if (!req.url.endsWith('/api/user/login') && !req.url.endsWith('api/user/token/refresh')) {
    //     // Refresh the token.Checking url condition to avoid cyclic refreshToken calls
    //     tokenService.refreshToken();
    //     /*Above call is expected to refresh the saved token with the buffer time to expiry.
    //      If not, next intercepted call will again request another refresh which
    //      as per expectation. i.e to refresh the token some how within the buffer time. */
    //   }
    // }
   /* --yahoo api dependency removed. code can be deleted
    if (req.url.startsWith('https://query.yahooapis.com')) {
      // yahooapis do not work with below Authorization and Content-Type header set.
      // So bypass the authRequest logic
      return next.handle(req);
    }*/
    //checking the jreport ur based onthe environment
    //the url for each environment is mentioned in configfile
     switch(window.location.hostname){
      case  sceconfig.wms.wmsProd:
        this.jReportUrl = sceconfig.wms.wmsJreportBaseProd;
        break;
      case sceconfig.wms.wmsUat:  
        this.jReportUrl = sceconfig.wms.wmsJreportBaseUat;
        break;
      case sceconfig.wms.wmsAliCloudProd:
          this.jReportUrl = sceconfig.wms.wmsJreportAliCloudProd;
        break;
      case sceconfig.wms.wmsAliCloudUat:
        this.jReportUrl = sceconfig.wms.wmsJreportAliCloudUat;
        break;
      case sceconfig.wms.wmsAliCloudSit:
        this.jReportUrl = sceconfig.wms.wmsJreportAliCloudUat;
        break;
      case tmsConfig.wms.wmsProdAmericas:
        this.jReportUrl = tmsConfig.wms.wmsJreportBaseProdAmericas;
        break;
      case tmsConfig.wms.wmsProdAsia:
        this.jReportUrl = tmsConfig.wms.wmsJreportBaseProdAsia;
        break;
      case tmsConfig.wms.wmsProdEur:
        this.jReportUrl = tmsConfig.wms.wmsJreportBaseProdEur;
        break;
      case tmsConfig.wms.wmsCdt:
        this.jReportUrl = tmsConfig.wms.wmsJreportBaseCdt;
        break;
      default:
        this.jReportUrl = sceconfig.wms.wmsJreportBaseUrl;
        break;

    }
    // LFWM-2028-To handle the JReport api requests
    // append the header with content type- application/x-www-form-urlencoded
    // and withCredentials true
    //this.jReportUrl = 'https://lflreportdev.lfuat.net';
    if (req.url.startsWith(this.jReportUrl)) {
      let reqHeadersJReport = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'});
      const authReqJReport = req.clone({
        url: req.url,
        headers: reqHeadersJReport,
        withCredentials: true
      });
      return next.handle(authReqJReport);
    }



    // ******************** API based url prefix logic **********************
    let appFullUrl = req.url;
        // if the given URL is full, do not append the prefix again
    if (!req.url.startsWith('http') && !appFullUrl.startsWith('assets/')) {      
            switch (req.headers.get('api')) {
              
              case 'admin' :  // any calls from admin module will be routed through here.
                            // ** Currently only added in getAllUsers() in userService -- /api/users. refer for example usage.
                            // console.debug('admin api caught in interceptor - inject conditional prefix here' , req.url, req.headers);
              appFullUrl = environment.wmsApiBaseUrl + appFullUrl; 
                           // appFullUrl = 'https://scesit.lflogistics.net/scecommon/' + appFullUrl; 
                            break;
              case 'notification':  // any calls from dashboard module will be routed through here.
                appFullUrl = environment.notificationApiBaseUrl + appFullUrl;       
                // case 'notification':  // any calls from dashboard module will be routed through here.
                // appFullUrl = sceconfig.notification.notificationApiBaseUrl + appFullUrl;
               // appFullUrl = 'https://scesit.lflogistics.net/dashboardservice/' + appFullUrl;      
              break;
              default:  // consider all default as appUrl wms/gvt etc
              appFullUrl = environment.notificationApiBaseUrl + appFullUrl;
                         // appFullUrl = 'https://scesit.lflogistics.net/dashboardservice/' + appFullUrl;
                          
            }
    }
    // ******************** API based url prefix logic  ENDS *****************



    // Set the current token.
    let reqHeaders = new HttpHeaders({'Authorization': tokenService.getToken()});

    // Set the content type
    const contentType = req.headers.get('Content-Type') || 'application/json';
    if (contentType !== 'application/octet-stream') {
      reqHeaders = reqHeaders.set('Content-Type', contentType);
    }

    // Set Accept
    const acceptType = req.headers.get('Accept') || 'application/json';
    reqHeaders = reqHeaders.set('Accept', acceptType);

    // Clone the request to add the new header.
    const authReq = req.clone({
      url: appFullUrl,
      headers: reqHeaders
    });

    // Pass on the cloned request instead of the original request.

    return next.handle(authReq);
  }
}

@Injectable()
export class TimingLogInterceptor implements HttpInterceptor {

  constructor(public _logger: LoggerService) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const started = Date.now();

    return next
      .handle(req);
     // ._finally(() => {
        // const elapsed = Date.now() - started;
        // this._logger.debug(`Request for ${req.urlWithParams} took ${elapsed} ms.`);
     // });
  }
}

@Injectable()
export class ResponseErrorInterceptor implements HttpInterceptor {

  constructor(public _logger: LoggerService) { }
  public userService: UserService

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const started = Date.now();

    return next.handle(req).pipe(
      catchError((error: any) => {
        // const elapsed = Date.now() - started;
        if (error instanceof HttpErrorResponse) {
          this._logger.warn('Processing http error', error);
          if (error.status === 404) {
            this._logger.warn('PageNot FOUND 404');
          } else if (error.status === 401) {
            this._logger.warn('Unauthorised');
          } else if (error.status === 409){
            this.userService.logout();
          } else if(error.status === 403){
            this._logger.warn('error.permission.mismatchno permission');
          }
        }
        return observableThrowError(error);
      }));
  }

}
