/* The file contents for the current environment will overwrite these during build.
   The build system defaults to the dev environment which uses `environment.ts`, but if you do
   `ng build --env=prod` then `environment.prod.ts` will be used instead.
   The list of which env maps to which file can be found in `.angular-cli.json`. */

   import { sceconfig } from 'sce-core';

   export const environment = {
    production: false,
    logger: 'console',
    baseUrlUI: sceconfig.wms.baseUrlUI,

    // wmsApiBaseUrl: 'https://ofsce-uat.lflogistics.net/scecommon/', // uat envrionment
    // notificationApiBaseUrl: 'https://ofscetms-uat.lflogistics.net/api' // uat envrionment

    // wmsApiBaseUrl: 'https://ofsce-uat.lflogistics.net/scecommon/', // uat envrionment
    // notificationApiBaseUrl: 'https://ofscetms-uat.lflogistics.net/api' // uat envrionment
    
    // wmsApiBaseUrl: 'https://ofsce-dev.lflogistics.net/scecommon/',  // localhost envrionment
    // notificationApiBaseUrl: 'https://ofscetms-dev.lflogistics.net/api' // localhost envrionment

    // wmsApiBaseUrl: 'https://ofsce-sit.lflogistics.net/scecommon/', // sit envrionment
    // notificationApiBaseUrl: 'https://ofscetms-sit.lflogistics.net/api' // sit envrionment

  // wmsApiBaseUrl: 'https://ofsce.lflogistics.net/scecommon/', // prod envrionment
  // notificationApiBaseUrl: 'https://ofscetms.lflogistics.net/api' // prod envrionment

  wmsApiBaseUrl: 'https://wms.ocf.fulfillment.maersk.com/scecommon/', // new prod envrionment
  notificationApiBaseUrl: 'https://tms.ocf.fulfillment.maersk.com/api' // new prod envrionment
};
