import { Injectable, Input, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { ResourceService } from '../../resource.service';


class PaginatorState {
  page: any;
  first: any;
  rows: any;
  pageCount: any;
}

@Injectable()
export class CustomPaginationService {

  public paginatorStateSubject: Subject<PaginatorState>;

  constructor(public confirmDialogService: ConfirmDialogService, public resourceService: ResourceService) {
    const paginatorState = new PaginatorState();
    this.paginatorStateSubject = new Subject<PaginatorState>();
  }
  pageLinkSize: any;

  alwaysShow: boolean = true;

  dropdownAppendTo: any;

  choosenPage: any;

  public pageLinks: any[];
  public totalRecord: number = 0;
  public first: number = 1;
  public rows: number = 1;
  public rowPerPageOptions: number[];
  public rowsPerPageItems: any[] = [];
  public paginatorState: any;
  public ellipseSymbol: any;
  public addNewRow: boolean = false;

  public tableMode: string;
  get totalRecords(): number {
    return this.totalRecord;
  }
  set totalRecords(val: number) {
    this.totalRecord = val;
    this.updatePageLinks();
  }

  @Input() get rowsPerPageOptions(): number[] {
    return this.rowPerPageOptions;
  }

  set rowsPerPageOptions(val: number[]) {
    this.rowPerPageOptions = val;
    if (this.rowPerPageOptions) {
      this.rowsPerPageItems = [];
      for (const opt of this.rowPerPageOptions) {
        this.rowsPerPageItems.push({ label: String(opt), value: opt });
      }
    }
  }

  public isFirstPage() {
    if (this.getPage() <= 1) {
      return true;
    } else {
      return false;
    }
  }

  public isLastPage() {
    return this.getPage() === this.getPageCount();
  }

  public getPageCount() {
    return Math.ceil(this.totalRecords / this.rows) || 1;
  }

  public updatePageLinks() {
    this.pageLinks = [];
    const current = this.getPage(),
      last = this.getPageCount(),
      pagesToBedisplayInLink = 5,
      left = current,
      right = current + pagesToBedisplayInLink,
      range = [];
    let link: any = 0;

    for (let data = 1; data <= last; data++) {
      if (data === 1 || data === last || data >= left && data < right) {
        range.push(data);
      }
    }

    let plusSym = true;
    for (const value of range) {
      if (link) {
        if (value - link === 2) {
          this.pageLinks.push(link + 1);
        } else if (value - link !== 1) {
          if (plusSym === true) {
            if (this.getPage() < 4) {
              this.pageLinks.push('+');
              plusSym = false;
            } else {
              this.pageLinks.push('-');
              plusSym = false;
            }
          } else {
            this.pageLinks.push('+');
          }
        }
      }
      this.pageLinks.push(value);
      link = value;
    }
  }

  public navigateToNewPage(pageNum: any) {
    const totalPageCount = this.getPageCount();
    if (pageNum === '+' || pageNum === '-') {
      this.ellipseSymbol = pageNum;
      this.updatePageLinksForElipse();
    } else {
      if (pageNum === 0) {
        this.choosenPage = 1;
      } else {
        this.choosenPage = pageNum;
      }
     if (pageNum >= 0 && pageNum <= totalPageCount && pageNum !== this.paginatorState.page) {
        this.pageLinks = [];
        this.first = this.rows * pageNum;
        const state = {
          page: pageNum,
          first: this.first,
          rows: this.rows,
          pageCount: totalPageCount
        };
        this.updatePageLinks();
        this.updatePaginatorState();
        this.paginatorStateSubject.next(this.paginatorState);
      } else {
        this.choosenPage = this.paginatorState.page;
      }
    }
  }
  public navigateToLastPage(pageNum: any) {
    const totalPageCount = this.getPageCount();
    if (pageNum === '+' || pageNum === '-') {
      this.ellipseSymbol = pageNum;
      this.updatePageLinksForElipse();
    } else {
      if (pageNum === 0) {
        this.choosenPage = 1;
      } else {
        this.choosenPage = totalPageCount;
      }
      if (pageNum >= 0 && pageNum <= totalPageCount && pageNum !== this.paginatorState.page) {
        this.pageLinks = [];
        this.first = this.rows * pageNum;
        const state = {
          page: totalPageCount,
          first: this.first,
          rows: this.rows,
          pageCount: totalPageCount
        };
        this.updatePageLinks();
        this.updatePaginatorState();
        this.paginatorStateSubject.next(this.paginatorState);
      } else {
        this.choosenPage = this.paginatorState.page;
      }
    }
  }
  public changePage(pageNum: any) {
    if (this.tableMode !== 'V' && pageNum !== this.paginatorState.page && pageNum <= this.getPageCount()) {
      const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
      const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
      const dialogMsg = [];
      dialogMsg.push(dialogMsg1);
      dialogMsg.push(dialogMsg2);
      this.updatePaginatorState();
      this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirm: boolean) => {
          if (isConfirm) {
            this.navigateToLastPage(pageNum);
            return;
          }
        });
    } else {
      this.navigateToNewPage(pageNum);
    }
  }

  public getPage(): number {
    if (Math.floor(this.first / this.rows) < 1) {
      return 1;
    } else {
      return Math.floor(this.first / this.rows);
    }
  }

  public changePageToFirst(event: any) {
    if (!this.isFirstPage()) {
      this.changePage(1);
    }
    event.preventDefault();
  }

  public changePageToPrev(event: any) {
    this.changePage(this.getPage() - 1);
    event.preventDefault();
  }

  public changePageToNext(event: any) {
    this.changePage(this.getPage() + 1);
    event.preventDefault();
  }

  public changePageToLast(event: any) {
    if (!this.isLastPage()) {
      this.changePage(this.getPageCount() + 1);
    }
    event.preventDefault();
  }

  public onPageLinkClick(event: any, page: number) {
    this.changePage(page);
    event.preventDefault();
  }
  public onRowsPerPageChange(event: any) {
    if (this.tableMode !== 'V') {
      const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
      const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
      const dialogMsg = [];
      dialogMsg.push(dialogMsg1);
      dialogMsg.push(dialogMsg2);
      this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirm: boolean) => {
          if (isConfirm) {
            this.changePageOnDropdown(1, event);
            return;
          }
        });
    } else {
      this.changePageOnDropdown(1, event);
    }
  }

  public changePageOnDropdown(pageNum: any, event: any) {
    if (pageNum === 0) {
      this.choosenPage = 1;
    } else {
      this.choosenPage = pageNum;
    }
    this.rows = event.value;
    const totalPageCount = this.getPageCount();
    if (pageNum >= 0) {
      this.pageLinks = [];
      this.first = this.rows * pageNum;
      const state = {
        page: pageNum,
        first: this.first,
        rows: this.rows,
        pageCount: totalPageCount
      };
      this.updatePageLinks();
      this.updatePaginatorState();
      this.paginatorStateSubject.next(this.paginatorState);
    }
  }

  // Returns the observable for subscribing to any
  public getPaginatorBroadcast(): Observable<PaginatorState> {
    return this.paginatorStateSubject.asObservable();
  }
  public updatePaginatorState() {
    this.paginatorState = {
      rows: this.rows,
      first: this.first,
      page: this.getPage(),
      totalRecords: this.totalRecords
    };
  }
  public handlePageChangeUserInput($event: any) {
    /* Due to usage of same model object for both paginators, pageChanged event will
       be fired twice every time. To avoid this 'ignorePageChange' flag is used. */
    this.changePage($event.target.value);
  }

  updatePageLinksForElipse() {
    this.pageLinks = [];
    if (this.ellipseSymbol === '-') {
      if (this.getPage() - 5 < 1) {
        const current = 1,
          last = this.getPageCount(),
          pagesToBedisplayInLink = 5,
          left = current,
          right = current + pagesToBedisplayInLink,
          range = [];
        for (let i = 1; i <= last; i++) {
          if (i === 1 || i === last || i >= left && i < right) {
            range.push(i);
          }
        }
        this.changePage(current);
      } else {
        const current = this.getPage() - 5,
          last = this.getPageCount(),
          pagesToBedisplayInLink = 5,
          left = current,
          right = current + pagesToBedisplayInLink,
          range = [];
        for (let i = 1; i <= last; i++) {
          if (i === 1 || i === last || i >= left && i < right) {
            range.push(i);
          }
        }
        this.changePage(current);
      }
    } else {
      const current = this.getPage() + 5,
        last = this.getPageCount(),
        pagesToBedisplayInLink = 5,
        left = current,
        right = current + pagesToBedisplayInLink,
        range = [];
      for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || i >= left && i < right) {
          range.push(i);
        }
      }
      this.changePage(current);
    }
  }
  public changeToEllipsisSymbol(value: any) {
    if (value === '+' || value === '-') {
      return '...';
    } else {
      return value;
    }
  }

  public setTableMode(tableMode) {
    this.tableMode = tableMode;
  }


  public changePageHighLightOnAddDeleteRow(pageNum: any) {
    const totalPageCount = this.getPageCount();
    if (pageNum >= 0) {
      this.pageLinks = [];
      this.first = this.rows * pageNum;
      const state = {
        page: pageNum,
        first: this.first,
        rows: this.rows,
        pageCount: totalPageCount
      };
      this.updatePageLinks();
      this.updatePaginatorState();
      this.paginatorStateSubject.next(this.paginatorState);
    }
  }

}
