import {
  Component, ViewChild, ComponentFactoryResolver,
  ViewContainerRef, ComponentRef, ComponentFactory
} from '@angular/core';
import { DynamicWidgetDirective } from './widget/dynamic-widget-directive';
import { ModalService } from './modal.service';
import { OverlayConfig } from '../models/overlay-config';

@Component({
  selector: 'lfwms-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class SCEModalComponent {

  public showOverlay: boolean = false;
  public overlayConfig: OverlayConfig;
  public content: any;

  @ViewChild(DynamicWidgetDirective , { static: true }) dynamicWidget: DynamicWidgetDirective;


  constructor(
    public componentFactoryResolver: ComponentFactoryResolver,
    public modalService: ModalService) {

    this.modalService.receiveEvent()
      .subscribe(($event: any) => {
        if ($event.type === 'show') {
          this.show($event.activeComponent, $event.overlayConfig);
        } else if ($event.type === 'hide') {
          this.hide();
        }
      });
  }

  public show(activeComponent: any, overlayConfig: OverlayConfig) {
    this.overlayConfig = overlayConfig; const componentFactory: ComponentFactory<any>
      = this.componentFactoryResolver
        .resolveComponentFactory(activeComponent);
    const viewContainerRef: ViewContainerRef
      = this.dynamicWidget.viewContainerRef;
    viewContainerRef.clear();
    const componentRef: ComponentRef<any>
      = viewContainerRef.createComponent(componentFactory);
    this.modalService.modalReference = this;
    this.content = componentRef.instance;
    this.showOverlay = true;
  }

  public hide() {
    this.showOverlay = false;
    const viewContainerRef: ViewContainerRef
      = this.dynamicWidget.viewContainerRef;
    viewContainerRef.clear();
  }

  public cancel() {
    this.modalService.publishEvent({'type': 'hide'});
  }

}
