export const INITIALBREADEVENT = [
    { id: 'home', displayValue: 'Home', route: '/home' },
    { id: 'tms', displayValue: 'TMS', route: undefined },
    { id: 'manual_update', displayValue: 'CHS-Manual Update', route: undefined },
    { id: 'mass_pod_upload', displayValue: 'CHS-Mass Upload', route: '/home/order/mass-upload' }
]

export const FETCH_DASHBOARD_DETAILS = {
    'RG': {code: 'RG', id: 2223, notificationId: '8', moduleId: 'Recipient Group', pageTitle: 'Receipient Group', tableId: 'massuploadsearchtd'},
};

export const metaDataGrp = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
    {
        'id': '1001',
        'elementId': 'Principal',
        'propertyName': 'lbl_tms_principal_id',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 1,
        'priority': 'HIGH',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_principal',
    },
    {
        'id': '1001',
        'elementId': 'Shipment XID',
        'propertyName': 'Shipment ID',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 1,
        'priority': 'HIGH',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Shipment XID',
    },
    {
        'id': '1002',
        'elementId': 'Order ID',
        'propertyName': 'lbl_tms_forecast_order_code',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 2,
        'priority': 'HIGH',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_forecast_order_code',
    },
    {
        'id': '1003',
        'elementId': 'Shipment ID',
        'propertyName': 'lbl_tms_shipment_xid',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 3,
        'priority': 'HIGH',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_shipment_xid',
    },
    {
        'id': '1004',
        'elementId': 'Customer Ref No',
        'propertyName': 'lbl_tms_customer_ref_no',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 4,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_customer_ref_no',
    },
    {
        'id': '1005',
        'elementId': 'Invoice No',
        'propertyName': 'lbl_tms_invoice_no',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 5,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_invoice_no',
    },
    {
        'id': '1006',
        'elementId': 'Tracking No',
        'propertyName': 'lbl_tms_tracking_no',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 6,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_tracking_no',
    },
    {
        'id': '1007',
        'elementId': 'Customer Po No',
        'propertyName': 'lbl_tms_customer_po_no',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 7,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_customer_po_no',
    },
    {
        'id': '1008',
        'elementId': 'Destination State',
        'propertyName': 'lbl_tms_destination_state',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 8,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_destination_state',
    },
    {
        'id': '1009',
        'elementId': 'Destination City',
        'propertyName': 'lbl_tms_destination_city',
        'type': 'TEXTFIELD',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 9,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_destination_city',
    },
    {
        'id': '1010',
        'elementId': 'Planned Start Date',
        'propertyName': 'lbl_tms_planned_start_date',
        'type': 'DATE',
        'defaultVal': '',
        'defaultOp': 'between',
        'isMandatory': true,
        'maxLength': 30,
        'index': 10,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_planned_start_date',
    },
    {
        'id': '1011',
        'elementId': 'Planned Delivery Date',
        'propertyName': 'lbl_tms_planned_delivery_date',
        'type': 'DATE',
        'defaultVal': '',
        'defaultOp': 'between',
        'isMandatory': true,
        'maxLength': 30,
        'index': 11,
        'priority': 'LOW',
        'toolTip': null,
        'values': [],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'lbl_tms_planned_delivery_date',
    },
    {
        'id': '1012',
        'elementId': 'Status',
        'propertyName': 'status_Id',
        'type': 'DROPDOWN',
        'defaultVal': '',
        'defaultOp': '=',
        'isMandatory': true,
        'maxLength': 30,
        'index': 12,
        'priority': 'LOW',
        'toolTip': null,
        'values': [
            { 'key': '', 'value': '-Select-' }, 
            { 'key': 'Open', 'value': 'Open' }, 
            { 'key': 'Planned', 'value': 'Planned' },
            { 'key': 'In Transit', 'value': 'In Transit' },
            { 'key': 'Deliveried', 'value': 'Deliveried' },
            { 'key': 'Cancelled', 'value': 'Cancelled' },
            { 'key': 'Pickup', 'value': 'Planned' },
            { 'key': 'Failure', 'value': 'Failure' },
            { 'key': 'Delivery Failure', 'value': 'Delivery Failure' },
            { 'key': 'DC Handover', 'value': 'DC Handover' },
            { 'key': 'Suspended', 'value': 'Suspended' },
            { 'key': 'TRANSFERRED', 'value': 'Transferred' }
            
        ],
        'columnGroup': 'Primary',
        'displayType': 'V',
        'dataType': 'String',
        'cellDataName': 'Status',
    },
]
}

export const metaDataMassGrpTable = {
    'elementId': 'RECIPIENT_GROUP_TB',
    'columnList': [
        {
            'id': '1000',
            'elementId': 'lbl_tms_file_name',
            'propertyName': 'fileName',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': false,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'dataType': 'String',
            "displayType":'T',
            'cellDataName': 'fileName',
        },
        {
            'id': '1001',
            'elementId': 'lbl_tms_shipment_xid',
            'propertyName': 'shipmentXid',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 1,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'Shipment XID',
        },
        {
            'id': '1001',
            'elementId': 'lbl_tms_principal_id',
            'propertyName': 'principal',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'Principal',

        },
        {
            'id': '1002',
            'elementId': 'lbl_tms_customer_ref_no',
            'propertyName': 'customerRefNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'Customer Ref No',

        },
        {
            'id': '1003',
            'elementId': 'lbl_tms_source_order_id',
            'propertyName': 'orderSourceId',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',   
             'cellDataName': 'Order Source ID',

        },
        {
            'id': '1004',
            'elementId': 'lbl_tms_invoice_no',
            'propertyName': 'invoiceNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'Invoice No',
        },
        {
            'id': '1005',
            'elementId': 'lbl_tms_tracking_no',
            'propertyName': 'trackingNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'Tracking No',
        },
        {
            'id': '1006',
            'elementId': 'lbl_tms_customer_po_no',
            'propertyName': 'customerPONo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'Customer PO No',
        },
        // {
        //     'id': '1007',
        //     'elementId': 'lbl_tms_order_status',
        //     'propertyName': 'orderStatus',
        //     'type': 'TEXTFIELD',
        //     'defaultVal': '',
        //     'defaultOp': '=',
        //     'isMandatory': true,
        //     'maxLength': 30,
        //     'index': 58,
        //     'priority': 'LOW',
        //     'toolTip': null,
        //     'values': [],
        //     'columnGroup': 'Primary',
        //     'cellDataName': 'Order Status',
        // },
        {
            'id': '1008',
            'elementId': 'lbl_tms_planned_delivery_date',
            'propertyName': 'deliveryDate',
            'type': 'DATETIME',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'Delivery Date',
        },
        {
            'id': '1009',
            'elementId': 'lbl_tms_upload_result',
            'propertyName': 'status',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'status',
        },
        {
            'id': '1009',
            'elementId': 'lbl_tms_batch_id',
            'propertyName': 'batchID',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'batchID',
        },
        {
            'id': '1002',
            'elementId': 'origin',
            'propertyName': 'Origin',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'Origin',
        },
        {
            'id': '1012',
            'elementId': 'lbl_tms_order_status',
            'propertyName': 'orderStatus',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 12,
            'priority': 'LOW',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' },
                { 'key': 'Open', 'value': 'Open' },
                { 'key': 'FINALIZED', 'value': 'Finalized' },
                { 'key': 'PENDING', 'value': 'Pending Booking' },
                { 'key': 'DECLINED', 'value': 'Declined' },
                { 'key': 'BOOKED', 'value': 'Booked' },
                { 'key': 'IN-TRANSIT', 'value': 'In Transit' },
                { 'key': 'DELIVERED', 'value': 'Delivered' },
                { 'key': 'DELIVERY-FAILURE', 'value': 'Delivery Failure' },
                { 'key': 'CANCELLED', 'value': 'Cancelled' },
                // { 'key': 'TRANSFER-ON-HOLD', 'value': 'Transfer On Hold' },
                // { 'key': 'SUSPENDED', 'value': 'Suspended' },
                // { 'key': 'TRANSFERRED', 'value': 'Transferred' },
                
            ],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'orderStatus',
        },
        {
            'id': '1009',
            'elementId': 'Type',
            'propertyName': 'type',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 58,
            'priority': 'LOW',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'cellDataName': 'type',
        },
    ]
}

export const metaDataOrderEventGrpTable = {
    'elementId': 'TAG_TB',
    'columnList': [
        {
            'id': '1001',
            'elementId': 'Shipment ID',
            'propertyName': 'shipmentId',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 1,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'shipmentId',
            'moduleId': 'GroupNameTd'
        },
        {
            'id': '1002',
            'elementId': 'Order Movement ID',
            'propertyName': 'orderMovementId',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 2,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
            'cellDataName': 'orderMovementId',
        },
        {
            'id': '1003',
            'elementId': 'lbl_tms_source_order_id',
            'propertyName': 'orderSourceId',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 3,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1004',
            'elementId': 'Principal',
            'propertyName': 'principal',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 4,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1005',
            'elementId': 'Customer Ref No',
            'propertyName': 'customerRefNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 5,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        
        {
            'id': '1006',
            'elementId': 'Invoice No',
            'propertyName': 'invoiceNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 7,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1007',
            'elementId': 'Tracking No',
            'propertyName': 'trackingNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 8,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1008',
            'elementId': 'Customer PO No',
            'propertyName': 'customerPoNo',
            'type': 'TEXTFIELD',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 8,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'V',
            'dataType': 'String',
        },
        {
            'id': '1009',
            'elementId': 'Pickup Status',
            'propertyName': 'pickupStatus',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 9,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' }, 
                { 'key': 'CANC-Order Cancellation', 'value': 'CANC-Order Cancellation' }, 
                { 'key': 'AA-Attempt Pickup', 'value': 'AA-Attempt Pickup' },
                { 'key': 'AF-Picking up at LF DC', 'value': 'AF-Picking up at LF DC' },
                { 'key': 'XX-Failed Pickup', 'value': 'XX-Failed Pickup' },
                { 'key': 'TP-Transfer Pickup', 'value': 'TP-Transfer Pickup' },
                { 'key': 'RF-Transfer Out', 'value': 'RF-Transfer Out' },
                { 'key': 'TA-Tender Accepted', 'value': 'TA-Tender Accepted' },
            ],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
            'cellDataName': 'pickupStatus',
        },
        {
            'id': '1010',
            'elementId': 'Pickup Reason',
            'propertyName': 'pickupReason',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 10,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' }, 
                { 'key': 'Normal Status', 'value': 'Normal Status' }, 
                { 'key': 'Wrong Product', 'value': 'Wrong Product' },
                { 'key': 'Other', 'value': 'Other' },
                { 'key': 'Wrong Quantity', 'value': 'Wrong Quantity' },
            ],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
        {
            'id': '1011',
            'elementId': 'Pickup Time',
            'propertyName': 'pickupTime',
            'type': 'DATE',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 11,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
        {
            'id': '1012',
            'elementId': 'Delivery Status',
            'propertyName': 'deliveryStatus',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 12,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' }, 
                { 'key': 'D1-Delivered', 'value': 'D1-Delivered' }, 
                { 'key': 'A7-Rejected', 'value': 'A7-Rejected' },
                { 'key': 'CANC-Order Cancellation', 'value': 'CANC-Order Cancellation' },
                { 'key': 'TP-Transfer Pickup', 'value': 'TP-Transfer Pickup' },
                { 'key': 'TP-Transfer Out', 'value': 'TP-Transfer Out' },
                { 'key': 'TA-Tender Accepted', 'value': 'TA-Tender Accepted' },
            ],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
        {
            'id': '1013',
            'elementId': 'Delivery Reason',
            'propertyName': 'deliveryReason',
            'type': 'DROPDOWN',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 13,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [
                { 'key': '', 'value': '-Select-' }, 
                { 'key': 'Normal Status', 'value': 'Normal Status' }, 
                { 'key': 'Partial Rejection', 'value': 'Partial Rejection' },
            ],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
        {
            'id': '1014',
            'elementId': 'Delivery Time',
            'propertyName': 'deliveryTime',
            'type': 'DATE',
            'defaultVal': '',
            'defaultOp': '=',
            'isMandatory': true,
            'maxLength': 30,
            'index': 14,
            'priority': 'HIGH',
            'toolTip': null,
            'values': [],
            'columnGroup': 'Primary',
            'displayType': 'E',
            'dataType': 'String',
        },
    ]
    }
