import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupManagementService } from './group-management.service';
import { RoleManagementService } from '../role-management/role-management.service';
import { UserManagementService } from '../user-management/user-management.service';
import { AlertService } from '../components/alerts/alert.service';
import { NavigationService } from '../navigation.service';
import { ConfirmDialogService } from '../components/confirm-dialog/confirm-dialog.service';
import { ResourceService } from '../resource.service';
import { UserService } from '../security/user.service';

@Component({
  selector: 'lfwms-group-management',
  templateUrl: './group-management.component.html',
  styleUrls: ['./group-management.component.css']
})
export class GroupManagementComponent implements OnInit {

  public displayMode: string = 'E';

  // Model corresponding to new Group Name (Create Mode)
  public newGroupName: string;

  public appsList: any = [];
  public groupsList: any = [];
  public rolesList: any = [];
  public privilegesList: any = [];
  public roleListForDropdown: Array<any> = [];

  public selectedAppId: number;
  public selectedGroupId: number;
  public selectedRoleId: number;
  public privilegeRoleId: number;

  public currentGroup: any;
  public currentGroupRoles: any = [];
  public isTemplateLoaded: boolean = false;
  public formattedPrivilegeData: any = [];
  public showPrivilegePreview: boolean = false;
  public enableDeleteGroup: boolean = true;
  public selectedRoleBindingVariableForDropdown: any;
  public groupAddPrevilege: boolean = false;
  public groupEditPrevilege: boolean = false;
  public groupDeletePrevilege: boolean = false;
  // LFWM-1994 To check the selected app id in dropdown
  public appDropdown: number;
  // LFWM-1994 AppId for wms and gvt
  public wmsAppId: number = 1;
  public gvtAppId: number = 2;
  /*** LFWM-2242 LF SCE Dashboard - User Management */
  public dashboardAppId: number = 4;
  /*** LB-11 LF SCE Billing | Group Management */
  public billingAppId: number = 5;
  // POMS-1 SCE - POMS | Group Management
  public pomsAppId: number = 6;
   // for data loader
   public dlAppId: number=3;
     // for notification
   public ntAppId: number=7;
   // for dispatcher
   public dispatchAppId: number=8;

  public privilegeTypeGroupManagement: string = 'B';//setting Button type as privilege for group management

  constructor(public activatedRoute: ActivatedRoute,
    public userManagementService: UserManagementService,
    public alertService: AlertService,
    public navService: NavigationService,
    public confirmDialogService: ConfirmDialogService,
    public resourceService: ResourceService,
    public groupManagementService: GroupManagementService,
    public roleManagementService: RoleManagementService,
    public userService: UserService) { }

  public ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.navService.setPageTitle(this.resourceService.get('mn_manage-group'));
    });
    this.resetGroupManagement(this.wmsAppId);// LFWM-1994 passing the wms appid initially on page load 
    this.roleManagementService.initializePrivilegeTemplate(this.wmsAppId, this.privilegeTypeGroupManagement);//LFWM-1994 passing the wms appid initially on page load 
    this.roleManagementService.onTemplateLoaded
      .subscribe((data: boolean) => {
        this.isTemplateLoaded = data;
        this.formattedPrivilegeData = this.roleManagementService.formattedPrivilegeData;
      });
    this.groupAddPrevilege = this.userService.checkButtonPrivilege('group_add');
    this.groupEditPrevilege = this.userService.checkButtonPrivilege('group_edit');
    this.groupDeletePrevilege = this.userService.checkButtonPrivilege('group_del');

  }

  public getLabel(key: any) {
    return this.resourceService.get(key);
  }

  public resetGroupManagement(appId) { //LFWM-1994 passing the selected app id 
    if (this.currentGroup && this.currentGroup.isEdited) {
      const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
      const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
      const dialogMsg = [];
      dialogMsg.push(dialogMsg1);
      dialogMsg.push(dialogMsg2);
      this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            this.currentGroup.isEdited = false;
            this.showPrivilegePreview = false;
            this.enableDeleteGroup = true;
            this.fetchAppsList(appId); //LFWM-1994 passing the selected app id 
            this.displayMode = 'E'; // Default Display Mode is Edit
            this.newGroupName = '';
          }
        });
    } else {
      this.showPrivilegePreview = false;
      this.fetchAppsList(appId); //LFWM-1994 passing the selected app id 
      this.displayMode = 'E'; // Default Display Mode is Edit
      this.newGroupName = '';
    }
  }
  /*
    LFWM-1830 - Perfomance issues in Groupmanagment screen
    This method was bound directly from template causing numerous array.indexOf checks in each second.
    Casusing the slowness of the page.
    Changing to one time previlege check from constructor.
    public checkButtonPrivilege(privilegeCode: String) {
      return this.userService.checkButtonPrivilege(privilegeCode);
    }
  */
  //[LFWM-2614][User Management - Application access restrictions][Change the api to get the app list based on login user]
  public fetchAppsList(appId) { //LFWM-1994 passing the selected app id 
    this.userManagementService.getAllUserApps()
      .subscribe((data: any) => {
        // LFWM-1994 checking if the logged in user has gvt access,if not
        //  flitering the appslist fetched 
        // if(!this.userService.userInfo.gvtAccess){ 
        // const appList = [];
        // data.forEach(element => {
        //   if (element.appId === this.gvtAppId) {
        //     if (this.userService.userInfo.gvtAccess) {
        //       appList.push(element);
        //     }
        //   } else if (element.appId === this.dashboardAppId) {
        //     /*** LFWM-2242 LF SCE Dashboard - User Management */
        //     if (this.userService.userInfo.dashboardAccess) {
        //       appList.push(element);
        //     }
        //   } else if (element.appId === this.billingAppId) {
        //     /*** LFWM-2242 LF SCE Billing - User Management */
        //     if (this.userService.userInfo.billAccess) {
        //       appList.push(element);
        //     }
        //   } else if (element.appId === this.pomsAppId) {
        //     /*** POMS-1 LF SCE POMS - User Management */
        //     if (this.userService.userInfo.pomsAccess) {
        //       appList.push(element);
        //     }
        //   } else {
        //     appList.push(element);
        //   }
        // });
        // data = appList;
        // }
        this.appsList = data;
        if (this.appDropdown === this.gvtAppId) {
          this.selectedAppId = this.gvtAppId;
        } else if (this.appDropdown === this.dashboardAppId) {
          /*** LFWM-2242 LF SCE Dashboard - User Management */
          this.selectedAppId = this.dashboardAppId;
        } else if (this.appDropdown === this.billingAppId) {
          /*** LB-11 LF SCE Billing | Group Management */
          this.selectedAppId = this.billingAppId;
        } else if(this.appDropdown===this.dlAppId){ 
          this.selectedAppId = this.dlAppId;
        }
        else if(this.appDropdown===this.ntAppId){ 
          this.selectedAppId = this.ntAppId;
        }
        else if(this.appDropdown===this.dispatchAppId){ 
          this.selectedAppId = this.dispatchAppId;
        }
        else if (this.appDropdown === this.pomsAppId) {
          /*** POMS-1 LF SCE POMS | Group Management */
          this.selectedAppId = this.pomsAppId;
        } else {
          this.selectedAppId = this.wmsAppId;
        }
        this.fetchRolesList(appId);
      }, (error: any) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }
  // LFWM-1994 method  to change the app drop down
  public changeSelectedAppId(selectedApp) {
    // LFWM-1994 when the dropdown is changed resettin the display mode to 'E'
    this.displayMode = 'E'
    if (selectedApp == this.gvtAppId) {
      this.appDropdown = this.gvtAppId;
    } else if (selectedApp == this.wmsAppId) {
      this.appDropdown = this.wmsAppId;
    } else if (parseInt(selectedApp, 10) === this.dashboardAppId) {
      /*** LFWM-2242 LF SCE Dashboard - User Management */
      this.appDropdown = this.dashboardAppId;
    } else if (parseInt(selectedApp, 10) === this.billingAppId) {
      /*** LB-11 LF SCE Billing | Group Management */
      this.appDropdown = this.billingAppId;
    } else if (parseInt(selectedApp, 10) === this.pomsAppId) {
      /*** POMS-1 LF SCE POMS | Group Management */
      this.appDropdown = this.pomsAppId;
    } else if(parseInt(selectedApp, 10) === this.dlAppId){// for data loader
      this.appDropdown=this.dlAppId;
    }
    else if(parseInt(selectedApp, 10) === this.ntAppId){// for notifiaction
      this.appDropdown=this.ntAppId;
    }
    else if(parseInt(selectedApp, 10) === this.dispatchAppId){// for notifiaction
      this.appDropdown=this.dispatchAppId;
    }
    this.fetchRolesList(selectedApp); // LFWM-1994  fetching roleelist and temlate based on selected app id
    this.roleManagementService.initializePrivilegeTemplate(selectedApp, this.privilegeTypeGroupManagement);
  }
  public fetchRolesList(appId) {// LFWM-1994 passing appid as a parameter
    this.userManagementService.getAllRoles(appId)// LFWM-1994 passing appid as a parameter
      .subscribe((data: any) => {
        this.rolesList = data;
        this.fetchGroupsList(appId);// LFWM-1994 passing appid as a parameter
        /**
         * Inside groupsList array, the each element of 'roles' array corresponding to each group is
         * having attributes 'role' and 'id'. Whereas, inside rolesList array, each element is having
         * attributes roleId and name. For the ease of comparison, each element inside rolesList array
         * is modified by changing below attribute names ::
         * ['roleId' --> 'id'] and ['name' --> 'role']
         * Note: Since in this page, we modify only Group Definitions, its safe to alter rolesList.
         * !Important: If at any point altered role definitions needs to be saved, above alteration should be reverted
         */
        this.rolesList.map((role: any) => {
          role.isNew = false;
          role.id = role.roleId;
          role.role = role.name;
          delete role.roleId;
          delete role.name;
        });
        this.rolesList.sort((a: any, b: any) => {
          if (a.role < b.role) {
            return -1;
          } else if (a.role > b.role) {
            return 1;
          }
          return 0;
        });
      }, (error: any) => {
        this.rolesList = [];
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }

  public fetchGroupsList(appId) {// LFWM-1994 passing appid as a parameter
    this.userManagementService.getAllGroups(appId)// LFWM-1994 passing appid as a parameter
      .subscribe((data: any) => {
        this.groupsList = data;
        this.groupsList.map((group: any) => {
          group.isEdited = false;
          group.roles.map((role: any) => {
            role.isNew = false;
          });
        });
        this.groupsList.sort((a: any, b: any) => {
          if (a.groupName < b.groupName) {
            return -1;
          } else if (a.groupName > b.groupName) {
            return 1;
          }
          return 0;
        });
        this.selectedGroupId = this.groupsList[0].groupId;
        this.fetchGroupRoles();
      }, (error: any) => {
        this.groupsList = [];
        this.currentGroupRoles = [];
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }

  public fetchPrivilegesList(roleId: number, showPreview: boolean) {
    this.privilegeRoleId = roleId;
    this.roleManagementService.getAccessPrivilegeRole(this.selectedAppId, roleId, this.privilegeTypeGroupManagement)
      .subscribe((resp: any) => {
        this.privilegesList = resp['privileges'];
        this.roleManagementService.refreshPrivilegeData(this.privilegesList);
        this.showPrivilegePreview = showPreview;
      }, (error: any) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }

  public fetchGroupRoles() {
    this.showPrivilegePreview = false;
    /* <select> with ngFor will pass the model as string, even if its datatype is
      declared as number. Server response contains groupId as number. For performance reasons,
      we convert the selectedGroupId (received as string) to number */
    const setSelectedRole = () => {
      this.selectedGroupId = +this.selectedGroupId;
      this.currentGroup = this.groupsList.filter((roleGroup: any) => {
        return roleGroup.groupId === this.selectedGroupId;
      })[0];
      this.currentGroupRoles = Object.assign([], this.currentGroup.roles);
      this.refreshAvailableRolesList();
    };
    if (this.currentGroup && this.currentGroup.isEdited) {
      const dialogMsg1 = `<b>` + this.resourceService.get('mn_data_lost_msg') + `</b>`;
      const dialogMsg2 = this.resourceService.get('mn_nav_confirm_msg');
      const dialogMsg = [];
      dialogMsg.push(dialogMsg1);
      dialogMsg.push(dialogMsg2);
      this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            setSelectedRole();
          }
        });
    } else {
      setSelectedRole();
    }

  }

  public refreshAvailableRolesList() {
    /* Roles once added to a Group and saved cannot be edited, but only deleted.
      On Adding Roles, the dropdown should only list the roles that are not currently
      assigned to the Group, irrespective of whether it is saved or not. For that filtering logic,
      we pass in a filter function, as a paramter which in turn compares the rolesList with roles
      corresponding to currently selected Group.
    */
    this.rolesList.map((role: any) => {
      role.isNew = false;
    });
    function filterRoles(otherArray: any) {
      return function (currentElement: any) {
        return otherArray.filter(function (otherElement: any) {
          return otherElement.id === currentElement.id;
        }).length === 0;
      };
    }
    this.rolesList
      .filter(filterRoles(this.currentGroupRoles))
      .map((role: any) => {
        role.isNew = true;
      });
  }

  public getAvailableRolesListAsKeyValue() {
    // This function is to pass the dropdown options as key-value pairs for the custom dropdown
    this.roleListForDropdown = this.rolesList.filter((role: any) => {
      return role.isNew === true;
    });
    const keyValueArrayForDropdown: Array<any> = [];
    // isNew Key is required for displying as such a check is present in html
    this.roleListForDropdown.map((item: any) => {
      keyValueArrayForDropdown.push(
        {
          key: item.id,
          value: item.role,
          isNew: true
        }
      );
    });
    this.roleListForDropdown = keyValueArrayForDropdown;
    return this.roleListForDropdown;
  }

  public getAvailableRolesList() {
    return this.rolesList.filter((role: any) => {
      return role.isNew === true;
    });

  }

  public deleteGroupRole(index: number) {
    //LFWM-3089 check for active user is linked to particular role or not
    this.roleManagementService.checkActiveUserfromGroup(this.selectedAppId, this.currentGroupRoles[index].id, this.selectedGroupId)
    .subscribe((res: any) => {
      const dialogMsg = this.resourceService.get(res.statusMessage);
      this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            this.showPrivilegePreview = false;
            this.currentGroupRoles.splice(index, 1);
            this.saveLastEntry();
            if (this.currentGroupRoles.length === 0) {
              this.showPrivilegePreview = false;
            }
          }
        });
    }, (error: any) => {
      this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
    });
   
  }

  public addGroupRole() {

    if (this.currentGroupRoles.length !== this.rolesList.length) {
      this.saveLastEntry();
      this.currentGroupRoles.push(this.getAvailableRolesList()[0]);
      // here we have the function call to get the dropdown options in key-value pair
      this.selectedRoleId = this.getAvailableRolesListAsKeyValue()[0].key;
      //  we are passing this variable to the custom dropdown
      this.selectedRoleBindingVariableForDropdown = {
        key: this.selectedRoleId,
        value: this.selectedRoleId,
        cVal: this.selectedRoleId
      };
      this.fetchPrivilegesList(this.selectedRoleId, true);
    } else {
      this.saveLastEntry();
      this.alertService.clearAll().error(this.resourceService.get('msg_no_roles_avail'));
    }
  }

  public saveLastEntry() {
    this.currentGroup.isEdited = true;
    if (this.currentGroupRoles.length > 0) {
      this.currentGroupRoles[this.currentGroupRoles.length - 1].isNew = false;
    }
    this.refreshAvailableRolesList();
  }

  public setSelectedRole() {
    this.showPrivilegePreview = false;
    // we are changing this varibale to false here and then changing it to true after getting response of preview
    this.selectedRoleId = this.selectedRoleBindingVariableForDropdown.cVal;
    this.selectedRoleBindingVariableForDropdown = {
      key: this.selectedRoleId,
      value: this.selectedRoleId,
      cVal: this.selectedRoleId
    };
    const selectedRoleObj = this.getAvailableRolesList()
      .filter((role: any) => {
        return role.id === this.selectedRoleId;
      })[0];
    this.currentGroupRoles.pop();
    this.currentGroupRoles.push(selectedRoleObj);
    this.fetchPrivilegesList(this.selectedRoleId, true);
  }

  public createGroupDefinition() {
    this.enableDeleteGroup = false;
    this.displayMode = 'C';
    if (this.currentGroup.isEdited) {
      const dialogMsg = this.resourceService.get(this.resourceService.get('msg_unsaved_data'));
      this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
        .subscribe((isConfirmed: any) => {
          if (isConfirmed) {
            this.saveGroupDefinition();
            this.currentGroup.isEdited = false;
          } else {
            this.currentGroup = {
              groupId: '',
              groupName: '',
              roles: []
            };
            this.currentGroup.isEdited = true;
            this.currentGroupRoles = [];
            this.selectedGroupId = 0;
            this.newGroupName = '';
            this.roleManagementService.resetPrivilegeData(false);
            this.showPrivilegePreview = false;
          }
        });
    } else {
      this.currentGroup = {
        groupId: '',
        groupName: '',
        roles: []
      };
      this.currentGroup.isEdited = true;
      this.currentGroupRoles = [];
      this.selectedGroupId = 0;
      this.newGroupName = '';
      this.roleManagementService.resetPrivilegeData(false);
      this.showPrivilegePreview = false;
    }
  }

  public saveGroupDefinition() {
    if (this.currentGroup.isEdited) {
      const groupDefinition = {
        groupId: null,
        groupName: '',
        appId: '',
        roles: []
      };
      if (this.displayMode === 'C') {
        groupDefinition.groupName = this.newGroupName = this.newGroupName.trim();
      } else {
        groupDefinition.groupId = this.currentGroup.groupId;
        groupDefinition.groupName = this.currentGroup.groupName;
      }
      groupDefinition.appId = groupDefinition.appId + this.selectedAppId;
      this.currentGroupRoles.map((role: any) => {
        groupDefinition.roles.push(role.id);
      });
      if (groupDefinition.groupName.length === 0) {
        this.alertService.clearAll().error(this.resourceService.get('msg_entr_grp_name'));
      } else {
        this.groupManagementService.saveGroup(groupDefinition)
          .subscribe((response: any) => {
            this.saveLastEntry();
            this.groupsList = response.groups;
            this.selectedGroupId = this.groupsList.filter((group: any) => {
              return (groupDefinition.groupName === group.groupName);
            })[0].groupId;
            this.currentGroup.isEdited = false;
            this.fetchGroupRoles();
            /* Currently no 'statusMessage' attribute is present in response payload.
              Once its added, below line should be removed and commented code should be uncommented */
            this.alertService.success(this.resourceService.translateServerResponse(response['statusMessage']));
            this.displayMode = 'E';
            this.enableDeleteGroup = true;
            this.roleManagementService.resetPrivilegeData(false);
            this.showPrivilegePreview = false;
          }, (error: any) => {
            this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
          });
      }
    } else {
      this.alertService.clearAll().warn(this.resourceService.get('msg_no_mod_sav'));
    }
  }

  public deleteGroupDefinition() {
    // Jira- 1032 change starts [Unsaved data pop up displayed during delete]
    this.currentGroup.isEdited = false;
    // Jira- 1032 change ends
    this.groupManagementService.checkActiveUser(this.selectedAppId, this.selectedGroupId)
      .subscribe((res: any) => {
        const dialogMsg = this.resourceService.get(res.statusMessage);
        this.confirmDialogService.confirmDialog(this.resourceService.get(dialogMsg))
          .subscribe((isConfirmed: any) => {
            if (isConfirmed) {
              this.groupManagementService.deleteGroup(this.selectedAppId, this.selectedGroupId)
                .subscribe((response: any) => {
                  this.alertService.clearAll();
                  this.alertService.success(this.resourceService.translateServerResponse(response['statusMessage']));
                  this.resetGroupManagement(this.selectedAppId);// LFWM-1994 passing appid as a parameter
                  this.roleManagementService.resetPrivilegeData(false);
                  this.showPrivilegePreview = false;
                }, (error: any) => {
                  this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
                });
            }
          });
      }, (error: any) => {
        this.alertService.clearAll().error(this.resourceService.translateServerResponse(error));
      });
  }


}
