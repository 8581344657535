import { Component, OnInit, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import {SelectItem} from 'primeng-lts/api';

@Component({
  selector: 'sce-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.css']
})
export class SCEDropdownComponent implements OnInit, OnChanges {

  public dropdownOptions: SelectItem[];
  public selectedOption: any;

  @Input() public inputValue: any;
  @Input() public inputOptions: Array<any> = [];
  @Input() public isDropup: boolean = false;
  @Input() public scrollHeight: string = '328px';
  @Input() public setFirstValuesAsDefault: boolean = true;
  @Input() public isEditable: boolean = false;

  @Output() public valueChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('primeFocusInput', { static: true }) primeFocusInput: any;

  /* START: Variables for autoselect/search using keypress inside dropdown */
  searchValue: string;
  searchIndex: number;
  searchTimeout: any;
  previousSearchChar: string;
  currentSearchChar: string;
  /* END: Variables for autoselect/search using keypress inside dropdown */

  public ngOnInit() {

    this.dropdownOptions = [];
    if (this.inputOptions.length === 0) {
      this.dropdownOptions.push({ label: 'No Options', value: null });
    } else {
      this.inputOptions.map((option: any, index: number) => {
        if (!this.inputOptions[index]['value']) {
          this.dropdownOptions.push({ label: this.inputOptions[index]['key'], value: this.inputOptions[index] });
        } else {
          this.dropdownOptions.push({ label: this.inputOptions[index]['value'], value: this.inputOptions[index] });
        }
      });
      if (this.inputValue.cVal) {
        const filteredOption: Array<any> = this.inputOptions
          .filter((option: any) => {
            return option.key === this.inputValue.cVal;
          });
        if (filteredOption.length > 0) {
          this.selectedOption = filteredOption[0];
        }
      } else {
        if (this.setFirstValuesAsDefault) {
          // If cVal is undefined, the dropdown should default to first available option
          this.selectedOption = this.inputOptions[0]; // Updating Dropdown component model
          this.inputValue.cVal = this.inputOptions[0]['key']; // Update cVal
        } else {
          this.selectedOption = [];
          this.inputValue.cVal = '';
        }
      }
    }
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes['inputValue'] || changes['inputOptions']) {
      this.dropdownOptions = [];
      if (this.inputOptions.length === 0) {
        this.dropdownOptions.push({ label: 'No Options', value: null });
      } else {
        this.inputOptions.map((option: any, index: number) => {
          if (!this.inputOptions[index]['value']) {
            this.dropdownOptions.push({ label: this.inputOptions[index]['key'], value: this.inputOptions[index] });
          } else {
            this.dropdownOptions.push({ label: this.inputOptions[index]['value'], value: this.inputOptions[index] });
          }
        });
        if (this.inputValue.cVal) {
          const filteredOption: Array<any> = this.inputOptions
            .filter((option: any) => {
              return option.key === this.inputValue.cVal;
            });
          if (filteredOption.length > 0) {
            this.selectedOption = filteredOption[0];
          }
        } else {
          if (this.setFirstValuesAsDefault) {
            // If cVal is undefined, the dropdown should default to first available option
            this.selectedOption = this.inputOptions[0]; // Updating Dropdown component model
            this.inputValue.cVal = this.inputOptions[0]['key']; // Update cVal
          } else {
            this.selectedOption = [];
          //  this.inputValue.cVal = '';
          }

        }
      }
    }
  }
  public setFocus() {
    this.primeFocusInput.focusViewChild.nativeElement.focus();
  }

  public onSelect($event: any) {
    const prevVal = this.inputValue.cVal;
    this.inputValue.cVal = $event.value['key'] ? $event.value['key'] : $event.value;
    this.valueChanged.emit({ type: 'dropdown', prevVal: prevVal, newVal: this.inputValue.cVal });
  }

  public onFocus() {
    const intervalID = setInterval(() => {
      if (this.primeFocusInput.panelVisible) {
        const subIntervalID = setInterval(() => {
          if (this.primeFocusInput.panelViewChild.nativeElement.style.top || this.primeFocusInput.panelViewChild.nativeElement.style.bottom) {
            if (this.isDropup) {
              this.primeFocusInput.panelViewChild.nativeElement.style.bottom = '100%';
              this.primeFocusInput.panelViewChild.nativeElement.style.top = 'unset';
            } else {
              this.primeFocusInput.panelViewChild.nativeElement.style.bottom = 'unset';
              this.primeFocusInput.panelViewChild.nativeElement.style.top = '100%';
            }
            clearInterval(subIntervalID);
          }
        }, 1);
        clearInterval(intervalID);
      }
    }, 1);
  }


  /* START: Code for autoselect/search using keypress inside dropdown */
/*    The keypress select functionality is not available in prime-ng v4.
      Below snippets have been taken from prime-ng v7 implementation and has
      been modified/optimised to work with WMS custom wrapper implementation for prime-ng v4 dropdown,
      so that the functionality is added to prime-ng v4 without breaking the actual v4 implementation.*/
  onKeydown(event: KeyboardEvent) {
    // if (this.readonly || !this.optionsToDisplay || this.optionsToDisplay.length === null) {
    //     return;
    // }
    switch (event.which) {
      // down
      case 40:
      // up
      case 38:
      // space
      case 32:
      // enter
      case 13:
      // escape and tab
      case 27:
      case 9:
        // do nothing.Let prime-ng handle the special cases
        break;

      // search item based on keyboard input
      default:
        this.search(event);
        break;
    }
  }

  search(event) {
    // console.log('**', this.selectedOption)
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }

    const char = String.fromCharCode(event.keyCode);
    this.previousSearchChar = this.currentSearchChar;
    this.currentSearchChar = char;

    /* if (this.previousSearchChar === this.currentSearchChar) {
      this.searchValue = this.currentSearchChar;
    } else {
      this.searchValue = this.searchValue ? this.searchValue + char : char;
    } */
    this.searchValue = this.searchValue ? this.searchValue + char : char;
    let newOption;
    let searchIndex = this.selectedOption ? this.findOptionIndex(this.selectedOption.value, this.dropdownOptions) : -1;
    newOption = this.searchOption(++searchIndex);

    if (newOption) {
      this.selectedOption = newOption.value;
      const prevVal = this.inputValue.cVal;
      this.inputValue.cVal = newOption.value.key;
      this.valueChanged.emit({ type: 'dropdown', prevVal: prevVal, newVal: this.inputValue.cVal });
      // console.log(this.selectedOption)
    }

    this.searchTimeout = setTimeout(() => {
      this.searchValue = null;
    }, 500);
  }

  searchOption(index) {
    let option;

    if (this.searchValue) {
      option = this.searchOptionInRange(index, this.dropdownOptions.length);

      if (!option) {
        option = this.searchOptionInRange(0, index);
      }
    }

    return option;
  }

  searchOptionInRange(start, end) {
    for (let i = start; i < end; i++) {
      const opt = this.dropdownOptions[i];
      if (opt.label.toLowerCase().startsWith(this.searchValue.toLowerCase())) {
        return opt;
      }
    }

    return null;
  }

  findOptionIndex(val: any, opts: any[]): number {
    let index: number = -1;
    if (opts) {
      for (let i = 0; i < opts.length; i++) {
        // if ((val == null && opts[i].value == null) || this.objectUtils.equals(val, opts[i].value, this.dataKey)) {
        if ((val == null && opts[i].value == null) || val === opts[i].value.value) {
          index = i;
          break;
        }
      }
    }

    return index;
  }
  /* END: Code for autoselect/search using keypress inside dropdown */
}
