import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {forkJoin , of} from 'rxjs';
import {catchError,delay, map} from 'rxjs/operators';
import { ResourceService } from '../../resource.service';

@Injectable()
export class PrinterSetupService {
    public header = new HttpHeaders({ 'api': 'admin' });
    constructor(public _http: HttpClient, public resourceService: ResourceService) { }

    /*This method used to fetch default printers from database */
    public fetchDefaultPrinters(): Observable<any> {
        const url = 'api/app/printers';
        return this._http.get(url,{ headers: this.header}).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.length !== 0) {
                    if (error.error.statusCode !== 200) {
                        return Observable.throw(error.error.statusMessage);
                    }
                }
            }));
    }
      /*LFWM-2250 This method used to fetch default printers by printer group from database */
      public fetchDefaultPrintersbyPrinterGroup(selectedPrinterGroup): Observable<any> {
        const url = 'api/app/' + selectedPrinterGroup + '/printers';
        return this._http.get(url,{ headers: this.header}).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.length !== 0) {
                    if (error.error.statusCode !== 200) {
                        return Observable.throw(error.error.statusMessage);
                    }
                }
            }));
    }

    // LFWM-2250
    public fetchPrinterGroup(): Observable<any> {
        const url = 'api/app/scePrinterGroup';
        return this._http.get(url,{ headers: this.header}).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.length !== 0) {
                    if (error.error.statusCode !== 200) {
                        return Observable.throw(error.error.statusMessage);
                    }
                }
            }));
    }
    /*This method is used to save default printer associated with the user*/
    public SaveDefaultPrinters(defaultPrinterRequestObj: any): Observable<any> {
        const url = 'api/core/default/printer';
        return this._http.post(url, defaultPrinterRequestObj,{ headers: this.header}).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.length !== 0) {
                    if (error.error.statusCode !== 200) {
                        return Observable.throw(error.error.statusMessage);
                    }
                }
            }));
    }

    /* Method is to used to fetch default printer associated with the user*/
    public fetchPrinterByUSerName(): Observable<any> {
        const url = 'api/core/default/printer';
        return this._http.get(url,{ headers: this.header}).pipe(
            map((response: any) => {
                return response;
            }),
            catchError((error: any) => {
                if (error.error.length !== 0) {
                    if (error.error.statusCode !== 200) {
                        return Observable.throw(error.error.statusMessage);
                    }
                }
            }));
    }
    // MEthod used to fetch the facility dropdown values
    public getFacilityValues() {
        return this._http.get('api/core/get/facility',{ headers: this.header}).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((error: any) => {
          if (error.error.length !== 0) {
            if (error.error.statusCode !== 200) {
              return Observable.throw(error.error.statusMessage);
            }
          }
        }));
    //  return response$;
      }
      
}
